/**
 * @name Homoclaves.ts
 * @description Registro de Homoclaves de trámites de COFEPRIS
 * @author @tirsomartinezreyes
 * @version 1.0.24
 * @changelog 1.0.24 - 14/feb/25 - @tirsomartinezreyes - Se ajusta tiempo de homoclaves para ESCRITO-LIBRE-EL87
 * @changelog 1.0.23 - 09/jan/25 - @Jorel254 - Se ajusta titulo de homoclave COFEPRIS-04-004-E
 * @changelog 1.0.22 - 16/dec/24 - @tirsomartinezreyes - Se ajustan descripciones de homoclaves COFEPRIS-2022-022-006, COFEPRIS-2022-022-009 y COFEPRIS-2022-022-010
 * @changelog 1.0.21 - 05/nov/24 - Se agregan medicamentos hemoderivados para homoclave 2022-022-013-AB
 * @changelog 1.0.19 - 05/nov/24 - Se ajustan títulos en COFEPRIS-04-004-DB, se agregan hemoderivadoos a COFEPRIS-2022-022-012-A y COFEPRIS-2022-022-013-A, refactorización de getDescripcionArmonizadaHomoclaveModalidad
 * @changelog 1.0.19 - 29/oct/24 - Se ajustan títulos en 40-004
 * @changelog 1.0.18 - 22/oct/24 - Se ajustan las banderaas de prorogas retroactivas para no pedir pago
 * @changelog 1.0.17 - 26/sep/24 - Se agregan los valores para identificación de cadena de pago e5cinco
 * @changelog 1.0.16 - 22/sep/24 - Se agregan porcentajes de pago, grupos de pago y tipo de cálculo de fechas administrativas y se ajustan las unidades administrativas responsables de modalidades. Se agregan grupo de Medicamentos para Producción
 * @changelog 1.0.13 - 09/sep/24 - Se elimina la bandera de modificaciones en la homoclave COFEPRIS-04-004
 * @changelog 1.0.12 - 05/sep/24 - Se agregan IDescripcionArmonizadaHomoclaveModalidad y getDescripcionArmonizadaHomoclaveModalidad para la armonización de descripción de homoclaves con CONAMER y con las modificaciones/modalidades internas
 * @changelog 1.0.11 - 02/sep/24 - Se ajustan las descripciones de homoclaves para armonizar Ensayos clínicos e insumos de salud
 * @changelog 1.0.10 - Se ajustan las descripciones de homoclaves solicitadas por COFEPRIS
 * @changelog 1.0.9 - Se ajustan IHomoclaveRegistryModalidadSinFormatos.modalidadConamer y se renombra COFEPRIS-EL87 por ESCRITO-LIBRE-EL87 por @tirsomartinezreyes
 * @changelog 1.0.8 - Se exporta CRegistroHomoclavesVersion por @tirsomartinezreyes
 * @changelog 1.0.7 - Ajuste de títulos y descripciones de homoclaevs y modalidades por @BrenAzua
 * @changelog 1.0.6 - Se agrega bandera prorrogaRetroactiva a homoclaves de insumos de salud que aplican para su modadlidad
 * @changelog 1.0.5 - Se retira requiereEstructuraMedicamento de la smodalidades y se agregan las configuraciones de insumos de salud
 * @changelog 1.0.4 - Se agregan las modalidades y las homoclaves COFEPRIS-09-012
 * @changelog 1.0.3 - Se agregan las modalidades I,J y K a COFEPRIS-04-004 omitidas por error en 1.0.2
 * @changelog 1.0.2 - Se agregan COFEPRIS-2022-022-006-A, COFEPRIS-2022-022-007-A, COFEPRIS-2022-022-008-A, COFEPRIS-2022-022-009-A, COFEPRIS-2022-022-010-A, y COFEPRIS-04-012-A
 * @changelog 1.0.1 - Se agrega EHomoclavesGrupos, DiccionarioHomoclavesGrupos, CHomoclavesGrupos y CHomoclavesGruposEnvironment
 */
import { CatalogGenericData } from 'cofepris-typesafe/Types/Catalogs'
import { EActor, EOrganizacionUnidadesOrganizativas } from 'cofepris-typesafe/Types/Organizacion'
import {
	HomoclaveRegistry,
	HomoclaveRegistryItem,
	HomoclaveRegistryModalidad,
	HomoclaveRegistryTramite,
	HomoclaveRegistryFormato,
	IHomoclaveRegistryModalidadFormatos,
	EHomoclaveRegistryAntecedenteActivoInformacion,
	EHomoclaveContextResultadoEvaluacion,
	EHomoclaveBanderasModificaciones,
	EHomoclaveRegistryGrupoCoordinacion,
	EHomoclaveRegistryModalidadInsumoSaludAccion
} from 'cofepris-typesafe/Types/Homoclave'

import { EAIInsumoSaludTipo, EMedicamentoTipo, EDispositivoCategoriaLGS262, EDispositivoClaseRIS83, EDispositivoGrupoPago, EMedicamentoGrupoPago } from 'cofepris-typesafe/Modules/InsumoSalud' //Homoclaves de Modificaciones a insumos de salud
import { EModificacionesMedicamentoTipo } from 'cofepris-typesafe/Modules/ModificacionesMedicamentos' //Homoclaves de Modificaciones a insumos de salud
import { EModificacionDispositivosTipo } from './ModificacionesDispositivos'
import { EnvironmentType } from 'cofepris-typesafe/Constants/Config'
import { EFechaAdministrativaOperacion } from 'cofepris-typesafe/Modules/FechasAdministrativas'

export const CRegistroHomoclavesVersion = '1.0.24'

export enum EHomoclaves {
	'COFEPRIS-04-001' = 'COFEPRIS-04-001',
	'COFEPRIS-04-004' = 'COFEPRIS-04-004',
	'COFEPRIS-04-005' = 'COFEPRIS-04-005',
	'COFEPRIS-04-006' = 'COFEPRIS-04-006',
	'COFEPRIS-04-007' = 'COFEPRIS-04-007',
	'COFEPRIS-04-008' = 'COFEPRIS-04-008',
	'COFEPRIS-04-010' = 'COFEPRIS-04-010',
	'COFEPRIS-04-012' = 'COFEPRIS-04-012',
	'COFEPRIS-09-012' = 'COFEPRIS-09-012',
	'COFEPRIS-10-001' = 'COFEPRIS-10-001',
	'COFEPRIS-2022-022-001-A' = 'COFEPRIS-2022-022-001-A',
	'COFEPRIS-2022-022-002-A' = 'COFEPRIS-2022-022-002-A',
	'COFEPRIS-2022-022-003-A' = 'COFEPRIS-2022-022-003-A',
	'COFEPRIS-2022-022-004-A' = 'COFEPRIS-2022-022-004-A',
	'COFEPRIS-2022-022-005-A' = 'COFEPRIS-2022-022-005-A',
	'COFEPRIS-2022-022-006-A' = 'COFEPRIS-2022-022-006-A',
	'COFEPRIS-2022-022-007-A' = 'COFEPRIS-2022-022-007-A',
	'COFEPRIS-2022-022-008-A' = 'COFEPRIS-2022-022-008-A',
	'COFEPRIS-2022-022-009-A' = 'COFEPRIS-2022-022-009-A',
	'COFEPRIS-2022-022-010-A' = 'COFEPRIS-2022-022-010-A',
	'COFEPRIS-2022-022-011-A' = 'COFEPRIS-2022-022-011-A',
	'COFEPRIS-2022-022-012-A' = 'COFEPRIS-2022-022-012-A',
	'COFEPRIS-2022-022-013-A' = 'COFEPRIS-2022-022-013-A',
	'ESCRITO-LIBRE-EL87' = 'ESCRITO-LIBRE-EL87',
	'COFEPRIS-ES-45' = 'COFEPRIS-ES-45',
	'COFEPRIS-ES-47' = 'COFEPRIS-ES-47',
	'IPN-00-A' = 'IPN-00-A',
	'IPN-CTD-M' = 'IPN-CTD-M'
}

export enum EModalidad {
	A = 'A',
	AA = 'AA',
	AB = 'AB',
	AC = 'AC',
	AC1 = 'AC1',
	AC2 = 'AC2',
	AC3 = 'AC3',
	AD = 'AD',
	AE = 'AE',
	AF = 'AF',
	AO = 'AO',
	AR = 'AR',
	B = 'B',
	BA = 'BA',
	BB = 'BB',
	BC = 'BC',
	BC1 = 'BC1',
	BC2 = 'BC2',
	BC3 = 'BC3',
	BO = 'BO',
	BR = 'BR',
	C = 'C',
	CA = 'CA',
	CB = 'CB',
	CC1 = 'CC1',
	CC2 = 'CC2',
	CC3 = 'CC3',
	D = 'D',
	DA = 'DA',
	DB = 'DB',
	DC = 'DC',
	DC1 = 'DC1',
	DC2 = 'DC2',
	DC3 = 'DC3',
	E = 'E',
	EC2 = 'EC2',
	EC3 = 'EC3',
	F = 'F',
	FC1 = 'FC1',
	FC2 = 'FC2',
	FC3 = 'FC3',
	G = 'G',
	GC1 = 'GC1',
	GC2 = 'GC2',
	GC3 = 'GC3',
	H = 'H',
	HC1 = 'HC1',
	HC2 = 'HC2',
	HC3 = 'HC3',
	I = 'I',
	J = 'J',
	K = 'K',
	L = 'L',
	M = 'M',
	R = 'R',
	O = 'O',
	P = 'P',
	X = 'X'
}

export enum EHomoclavesGrupos {
	ENSAYOS_CLINICOS = 'ENSAYOS_CLINICOS',
	DISPOSITIVOS = 'DISPOSITIVOS',
	MEDICAMENTOS = 'MEDICAMENTOS',
	MEDICAMENTOS_PRODUCCION = 'MEDICAMENTOS_PRODUCCION',
	PRUEBAS = 'PRUEBAS'
}

export const DiccionarioHomoclavesGrupos: Record<keyof typeof EHomoclavesGrupos, string> = {
	'ENSAYOS_CLINICOS': 'Ensayos Clínicos',
	'DISPOSITIVOS': 'Dispositivos Médicos',
	'MEDICAMENTOS': 'Medicamentos',
	'MEDICAMENTOS_PRODUCCION': 'Medicamentos',
	'PRUEBAS': 'Pruebas'
}

export const CHomoclavesGrupos: Record<EHomoclavesGrupos, EHomoclaves[]> = {
	ENSAYOS_CLINICOS: [EHomoclaves['COFEPRIS-04-010'], EHomoclaves['COFEPRIS-09-012'], EHomoclaves['COFEPRIS-ES-45'], EHomoclaves['COFEPRIS-ES-47']],
	MEDICAMENTOS: [
		EHomoclaves['COFEPRIS-04-004'],
		EHomoclaves['COFEPRIS-04-005'],
		EHomoclaves['COFEPRIS-04-006'],
		EHomoclaves['COFEPRIS-04-007'],
		EHomoclaves['COFEPRIS-04-008'],
		EHomoclaves['COFEPRIS-04-012'],
		EHomoclaves['COFEPRIS-10-001'],
		EHomoclaves['COFEPRIS-2022-022-006-A'],
		EHomoclaves['COFEPRIS-2022-022-007-A'],
		EHomoclaves['COFEPRIS-2022-022-008-A'],
		EHomoclaves['COFEPRIS-2022-022-009-A'],
		EHomoclaves['COFEPRIS-2022-022-010-A'],
		EHomoclaves['COFEPRIS-2022-022-011-A'],
		EHomoclaves['COFEPRIS-2022-022-012-A'],
		EHomoclaves['COFEPRIS-2022-022-013-A'],
		EHomoclaves['ESCRITO-LIBRE-EL87']
	],
	MEDICAMENTOS_PRODUCCION: [EHomoclaves['COFEPRIS-2022-022-011-A'], EHomoclaves['COFEPRIS-2022-022-012-A'], EHomoclaves['COFEPRIS-2022-022-013-A']],
	DISPOSITIVOS: [
		EHomoclaves['COFEPRIS-04-001'], //
		EHomoclaves['COFEPRIS-04-012'], //
		EHomoclaves['COFEPRIS-2022-022-001-A'],
		EHomoclaves['COFEPRIS-2022-022-002-A'],
		EHomoclaves['COFEPRIS-2022-022-003-A'],
		EHomoclaves['COFEPRIS-2022-022-004-A'],
		EHomoclaves['COFEPRIS-2022-022-005-A'],
		EHomoclaves['COFEPRIS-2022-022-006-A']
	],
	PRUEBAS: [EHomoclaves['IPN-00-A'], EHomoclaves['IPN-CTD-M']]
}

export const CGruposHomoclavesModalidades: { [key in EHomoclavesGrupos]: { [key: string]: EModalidad[] } } = {
	ENSAYOS_CLINICOS: {
		'COFEPRIS-04-010': [EModalidad.A, EModalidad.B, EModalidad.C, EModalidad.D],
		'COFEPRIS-09-012': [EModalidad.A, EModalidad.B, EModalidad.C, EModalidad.D, EModalidad.E, EModalidad.F, EModalidad.G, EModalidad.H, EModalidad.I, EModalidad.J, EModalidad.K, EModalidad.L],
		'COFEPRIS-ES-45': [EModalidad.A, EModalidad.B],
		'COFEPRIS-ES-47': [EModalidad.X]
	},
	DISPOSITIVOS: {
		'COFEPRIS-04-001': [
			EModalidad.AC1,
			EModalidad.AC2,
			EModalidad.AC3,
			EModalidad.BC1,
			EModalidad.BC2,
			EModalidad.BC3,
			EModalidad.CC1,
			EModalidad.CC2,
			EModalidad.CC3,
			EModalidad.D,
			EModalidad.EC2,
			EModalidad.EC3,
			EModalidad.FC1,
			EModalidad.FC2,
			EModalidad.FC3,
			EModalidad.GC1,
			EModalidad.GC2,
			EModalidad.GC3,
			EModalidad.HC1,
			EModalidad.HC2,
			EModalidad.HC3,
			EModalidad.I,
			EModalidad.J,
			EModalidad.K
		],
		'COFEPRIS-04-012': [EModalidad.B],
		'COFEPRIS-2022-022-001-A': [EModalidad.AO, EModalidad.AR],
		'COFEPRIS-2022-022-002-A': [EModalidad.AO, EModalidad.AR],
		'COFEPRIS-2022-022-003-A': [EModalidad.AA, EModalidad.AB, EModalidad.AO],
		'COFEPRIS-2022-022-004-A': [EModalidad.X],
		'COFEPRIS-2022-022-005-A': [EModalidad.X],
		'COFEPRIS-2022-022-006-A': [EModalidad.BO, EModalidad.BR]
	},
	MEDICAMENTOS: {
		'COFEPRIS-04-004': [
			EModalidad.AA,
			EModalidad.AB,
			EModalidad.BA,
			EModalidad.BB,
			EModalidad.BC,
			EModalidad.CA,
			EModalidad.CB,
			EModalidad.DA,
			EModalidad.DB,
			EModalidad.DC,
			EModalidad.E,
			EModalidad.F,
			EModalidad.G,
			EModalidad.H,
			EModalidad.I,
			EModalidad.J,
			EModalidad.K
		],
		'COFEPRIS-04-005': [EModalidad.M, EModalidad.P, EModalidad.R],
		'COFEPRIS-04-006': [EModalidad.A, EModalidad.B],
		'COFEPRIS-04-007': [EModalidad.A, EModalidad.B],
		'COFEPRIS-04-008': [EModalidad.A, EModalidad.B],
		'COFEPRIS-04-012': [EModalidad.A],
		'COFEPRIS-10-001': [EModalidad.AA, EModalidad.AB, EModalidad.AC],
		'COFEPRIS-2022-022-006-A': [EModalidad.AA, EModalidad.AB, EModalidad.AC, EModalidad.AD, EModalidad.AE, EModalidad.AF],
		'COFEPRIS-2022-022-007-A': [EModalidad.AO, EModalidad.AR, EModalidad.BO, EModalidad.BR],
		'COFEPRIS-2022-022-008-A': [EModalidad.AO, EModalidad.AR, EModalidad.BO, EModalidad.BR],
		'COFEPRIS-2022-022-009-A': [EModalidad.AO, EModalidad.AR],
		'COFEPRIS-2022-022-010-A': [EModalidad.AO, EModalidad.AR],
		'COFEPRIS-2022-022-011-A': [EModalidad.AA, EModalidad.AB, EModalidad.AC],
		'COFEPRIS-2022-022-012-A': [EModalidad.AA, EModalidad.AB, EModalidad.AC],
		'COFEPRIS-2022-022-013-A': [EModalidad.AA, EModalidad.AB, EModalidad.AC],
		'ESCRITO-LIBRE-EL87': [EModalidad.M, EModalidad.P, EModalidad.R]
	},
	MEDICAMENTOS_PRODUCCION: {
		'COFEPRIS-2022-022-011-A': [EModalidad.AA, EModalidad.AB, EModalidad.AC],
		'COFEPRIS-2022-022-012-A': [EModalidad.AA, EModalidad.AB, EModalidad.AC],
		'COFEPRIS-2022-022-013-A': [EModalidad.AA, EModalidad.AB, EModalidad.AC]
	},
	PRUEBAS: {
		'IPN-00-A': [EModalidad.A],
		'IPN-CTD-M': [EModalidad.A]
	}
}

export const CHomoclavesGruposEnvironment: Record<EnvironmentType, EHomoclavesGrupos[]> = {
	DRL: [EHomoclavesGrupos.ENSAYOS_CLINICOS, EHomoclavesGrupos.MEDICAMENTOS_PRODUCCION],
	QA: [EHomoclavesGrupos.ENSAYOS_CLINICOS, EHomoclavesGrupos.MEDICAMENTOS, EHomoclavesGrupos.DISPOSITIVOS],
	SEC: [EHomoclavesGrupos.ENSAYOS_CLINICOS, EHomoclavesGrupos.MEDICAMENTOS, EHomoclavesGrupos.DISPOSITIVOS],
	STAGE: [EHomoclavesGrupos.ENSAYOS_CLINICOS, EHomoclavesGrupos.MEDICAMENTOS, EHomoclavesGrupos.DISPOSITIVOS],
	DEV: [EHomoclavesGrupos.ENSAYOS_CLINICOS, EHomoclavesGrupos.MEDICAMENTOS, EHomoclavesGrupos.DISPOSITIVOS, EHomoclavesGrupos.PRUEBAS],
	LUIS: [EHomoclavesGrupos.ENSAYOS_CLINICOS, EHomoclavesGrupos.MEDICAMENTOS, EHomoclavesGrupos.DISPOSITIVOS, EHomoclavesGrupos.PRUEBAS],
	SANTIAGO: [EHomoclavesGrupos.ENSAYOS_CLINICOS, EHomoclavesGrupos.MEDICAMENTOS, EHomoclavesGrupos.DISPOSITIVOS, EHomoclavesGrupos.PRUEBAS]
}

export const CHomoclavesEnsayosClinicos: EHomoclaves[] = [...CHomoclavesGrupos.ENSAYOS_CLINICOS]
export const CHomoclavesInsumosSalud: EHomoclaves[] = [...CHomoclavesGrupos.MEDICAMENTOS, ...CHomoclavesGrupos.DISPOSITIVOS]

//Homoclaves y modalidades disponibles en el sistema
export const Registro: HomoclaveRegistry = [
	{
		homoclave: EHomoclaves['COFEPRIS-04-001'],
		titulo: 'Solicitud de Registro Sanitario de dispositivos médicos.',
		modalidades: [
			{
				modalidad: 'AC1',
				modalidadConamer: 'A',
				subtitulo: 'Clase I.',
				titulo: 'Productos de fabricación nacional.',
				descripcion: 'Permite tramitar el Registro Sanitario de dispositivos médicos de fabricación nacional, de Clase I, para comercializar en territorio nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_I,
				atencion: 30,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 30,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '401AC1',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRCI,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.I],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_SIN_REGISTRO_CLASE_I
			},
			{
				modalidad: 'AC2',
				modalidadConamer: 'A',
				subtitulo: 'Clase II.',
				titulo: 'Productos de fabricación nacional.',
				descripcion: 'Permite tramitar el Registro Sanitario de dispositivos médicos de fabricación nacional, de Clase II, para comercializar en territorio nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_II,
				atencion: 35,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 35,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '401AC2',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRCII,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.II],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_SIN_REGISTRO_CLASE_II
			},
			{
				modalidad: 'AC3',
				modalidadConamer: 'A',
				subtitulo: 'Clase III.',
				titulo: 'Productos de fabricación nacional.',
				descripcion: 'Permite tramitar el Registro Sanitario de dispositivos médicos de fabricación nacional, de Clase III, para comercializar en territorio nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_III,
				atencion: 60,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 60,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '401AC3',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRCIII,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.III],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_SIN_REGISTRO_CLASE_III
			},
			{
				modalidad: 'BC1',
				modalidadConamer: 'B',
				subtitulo: 'Clase I.',
				titulo: 'Productos de Importación (fabricación extranjera).',
				descripcion: 'Permite tramitar el Registro Sanitario de dispositivos médicos de fabricación extranjera, de Clase I, para comercializar en territorio nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_I,
				atencion: 30,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 30,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '401BC1',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRCI,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.I],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_SIN_REGISTRO_CLASE_I
			},
			{
				modalidad: 'BC2',
				modalidadConamer: 'B',
				subtitulo: 'Clase II.',
				titulo: 'Productos de Importación (fabricación extranjera).',
				descripcion: 'Permite tramitar el Registro Sanitario de dispositivos médicos de fabricación extranjera, de Clase II, para comercializar en territorio nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_II,
				atencion: 35,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 35,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '401BC2',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRCII,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.II],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_SIN_REGISTRO_CLASE_II
			},
			{
				modalidad: 'BC3',
				modalidadConamer: 'B',
				subtitulo: 'Clase III.',
				titulo: 'Productos de Importación (fabricación extranjera).',
				descripcion: 'Permite tramitar el Registro Sanitario de dispositivos médicos de fabricación extranjera, de Clase III, para comercializar en territorio nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_III,
				atencion: 60,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 60,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '401BC3',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRCIII,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.III],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_SIN_REGISTRO_CLASE_III
			},
			{
				modalidad: 'CC1',
				modalidadConamer: 'C',
				subtitulo: 'Clase I.',
				titulo: 'Productos de Fabricación Nacional que son Maquilados por otro Establecimiento.',
				descripcion:
					'Permite tramitar el Registro Sanitario de dispositivos médicos de fabricación nacional, de Clase I, manufacturados total o parcialmente por un maquilador, para comercializar en territorio nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_I,
				atencion: 30,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 30,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '401CC1',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRCI,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.I],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_SIN_REGISTRO_CLASE_I
			},
			{
				modalidad: 'CC2',
				modalidadConamer: 'C',
				subtitulo: 'Clase II.',
				titulo: 'Productos de Fabricación Nacional que son Maquilados por otro Establecimiento.',
				descripcion:
					'Permite tramitar el Registro Sanitario de dispositivos médicos de fabricación nacional, de Clase II, manufacturados total o parcialmente por un maquilador, para comercializar en territorio nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_II,
				atencion: 35,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 35,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '401CC2',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRCII,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.II],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_SIN_REGISTRO_CLASE_II
			},
			{
				modalidad: 'CC3',
				modalidadConamer: 'C',
				subtitulo: 'Clase III.',
				titulo: 'Productos de Fabricación Nacional que son Maquilados por otro Establecimiento.',
				descripcion:
					'Permite tramitar el Registro Sanitario de dispositivos médicos de fabricación nacional, de Clase III, manufacturados total o parcialmente por un maquilador, para comercializar en territorio nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_III,
				atencion: 60,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 60,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '401CC3',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRCIII,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.III],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_SIN_REGISTRO_CLASE_III
			},
			{
				modalidad: 'D',
				modalidadConamer: 'D',
				subtitulo: 'Clase I.',
				titulo: 'Productos con Registro Clase I FDA (Acuerdo de Equivalencia E.U.A. y Canadá).',
				descripcion:
					'Permite tramitar el Registro Sanitario de dispositivos médicos con Registro Clase I FDA (Acuerdo de Equivalencia E.U.A. y Canadá), para comercializar en territorio nacional. Clase I, de acuerdo al artículo 83 del Reglamento de Insumos para la Salud.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_I,
				atencion: 30,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 30,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '401DC1',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRE,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.I],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_ACUERDO_EQUIVALENCIA
			},
			{
				modalidad: 'EC2',
				modalidadConamer: 'E',
				subtitulo: 'Clase II.',
				titulo: 'Productos con Registro Clase II y III FDA (Acuerdo de Equivalencia E.U.A. y Canadá).',
				descripcion:
					'Permite tramitar el Registro Sanitario de dispositivos médicos con Registro Clase II y III FDA (Acuerdo de Equivalencia E.U.A. y Canadá), para comercializar en territorio nacional. Clase II, de acuerdo al artículo 83 del Reglamento de Insumos para la Salud.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_II,
				atencion: 30,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 30,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '401EC2',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRE,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.II],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_ACUERDO_EQUIVALENCIA
			},
			{
				modalidad: 'EC3',
				modalidadConamer: 'E',
				subtitulo: 'Clase III.',
				titulo: 'Productos con Registro Clase II y III FDA (Acuerdo de Equivalencia E.U.A. y Canadá).',
				descripcion:
					'Permite tramitar el Registro Sanitario de dispositivos médicos con Registro Clase II y III FDA (Acuerdo de Equivalencia E.U.A. y Canadá), para comercializar en territorio nacional. Clase III, de acuerdo al artículo 83 del Reglamento de Insumos para la Salud.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_III,
				atencion: 30,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 30,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '401EC3',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRE,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.III],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_ACUERDO_EQUIVALENCIA
			},
			{
				modalidad: 'FC1',
				modalidadConamer: 'F',
				subtitulo: 'Clase I.',
				titulo: 'Productos con Registro Clase II, III y IV HEALTH CANADA (Acuerdo de Equivalencia E.U.A. y Canadá).',
				descripcion:
					'Permite tramitar el Registro Sanitario de dispositivos médicos con Registro Clase II, III y IV HEALTH CANADA (Acuerdo de Equivalencia E.U.A. y Canadá), para comercializar en territorio nacional. Clase I, de acuerdo al artículo 83 del Reglamento de Insumos para la Salud.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_I,
				atencion: 30,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 30,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '401FC1',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRE,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.I],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_ACUERDO_EQUIVALENCIA
			},
			{
				modalidad: 'FC2',
				modalidadConamer: 'F',
				subtitulo: 'Clase II.',
				titulo: 'Productos con Registro Clase II, III y IV HEALTH CANADA (Acuerdo de Equivalencia E.U.A. y Canadá).',
				descripcion:
					'Permite tramitar el Registro Sanitario de dispositivos médicos con Registro Clase II, III y IV HEALTH CANADA (Acuerdo de Equivalencia E.U.A. y Canadá), para comercializar en territorio nacional. Clase II, de acuerdo al artículo 83 del Reglamento de Insumos para la Salud.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_II,
				atencion: 30,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 30,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '401FC2',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRE,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.II],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_SIN_REGISTRO_CLASE_II
			},
			{
				modalidad: 'FC3',
				modalidadConamer: 'F',
				subtitulo: 'Clase III.',
				titulo: 'Productos con Registro Clase II, III y IV HEALTH CANADA (Acuerdo de Equivalencia E.U.A. y Canadá).',
				descripcion:
					'Permite tramitar el Registro Sanitario de dispositivos médicos con Registro Clase II, III y IV HEALTH CANADA (Acuerdo de Equivalencia E.U.A. y Canadá), para comercializar en territorio nacional. Clase III, de acuerdo al artículo 83 del Reglamento de Insumos para la Salud.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_III,
				atencion: 30,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 30,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '401FC3',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRE,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.III],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_ACUERDO_EQUIVALENCIA
			},
			{
				modalidad: 'GC1',
				modalidadConamer: 'G',
				subtitulo: 'Clase I.',
				titulo:
					'Dispositivos médicos controlados designados (clase II con criterio de conformidad establecido) con Certificado Emitido por un Organismo de Certificación Registrado ante el MHLW de Japón (Acuerdo de Equivalencia Japón).',
				descripcion:
					'Permite tramitar el Registro Sanitario de dispositivos médicos controlados designados (clase II con criterio de conformidad establecido) con Certificado Emitido por un Organismo de Certificación Registrado ante el MHLW de Japón (Acuerdo de Equivalencia Japón), para comercializar en territorio nacional. Clase I, de acuerdo al artículo 83 del Reglamento de Insumos para la Salud.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_I,
				atencion: 30,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 30,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '401GC1',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRE,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.I],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_ACUERDO_EQUIVALENCIA
			},
			{
				modalidad: 'GC2',
				modalidadConamer: 'G',
				subtitulo: 'Clase II.',
				titulo:
					'Dispositivos médicos controlados designados (clase II con criterio de conformidad establecido) con Certificado Emitido por un Organismo de Certificación Registrado ante el MHLW de Japón (Acuerdo de Equivalencia Japón).',
				descripcion:
					'Permite tramitar el Registro Sanitario de dispositivos médicos controlados designados (clase II con criterio de conformidad establecido) con Certificado Emitido por un Organismo de Certificación Registrado ante el MHLW de Japón (Acuerdo de Equivalencia Japón), para comercializar en territorio nacional. Clase II, de acuerdo al artículo 83 del Reglamento de Insumos para la Salud.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_II,
				atencion: 30,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 30,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '401GC2',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRE,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.III],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_ACUERDO_EQUIVALENCIA
			},
			{
				modalidad: 'GC3',
				modalidadConamer: 'G',
				subtitulo: 'Clase III.',
				titulo:
					'Dispositivos médicos controlados designados (clase II con criterio de conformidad establecido) con Certificado Emitido por un Organismo de Certificación Registrado ante el MHLW de Japón (Acuerdo de Equivalencia Japón).',
				descripcion:
					'Permite tramitar el Registro Sanitario de dispositivos médicos controlados designados (clase II con criterio de conformidad establecido) con Certificado Emitido por un Organismo de Certificación Registrado ante el MHLW de Japón (Acuerdo de Equivalencia Japón), para comercializar en territorio nacional. Clase III, de acuerdo al artículo 83 del Reglamento de Insumos para la Salud.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_III,
				atencion: 30,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 30,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '401GC3',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRE,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.III],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_SIN_REGISTRO_CLASE_III
			},
			{
				modalidad: 'HC1',
				modalidadConamer: 'H',
				subtitulo: 'Clase I.',
				titulo: 'Dispositivos médicos Clase II (clase II sin criterio de conformidad establecido), III y IV con Carta de Aprobación Emitida por el MHLW de Japón (Acuerdo de Equivalencia Japón).',
				descripcion:
					'Permite tramitar el Registro Sanitario de dispositivos médicos Clase II (clase II sin criterio de conformidad establecido), III y IV con Carta de Aprobación Emitida por el MHLW de Japón (Acuerdo de Equivalencia Japón). Clase I, de acuerdo al artículo 83 del Reglamento de Insumos para la Salud.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_I,
				atencion: 30,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 30,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '401HC1',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRE,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.I],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_ACUERDO_EQUIVALENCIA
			},
			{
				modalidad: 'HC2',
				modalidadConamer: 'H',
				subtitulo: 'Clase II.',
				titulo: 'Dispositivos médicos Clase II (clase II sin criterio de conformidad establecido), III y IV con Carta de Aprobación Emitida por el MHLW de Japón (Acuerdo de Equivalencia Japón).',
				descripcion:
					'Permite tramitar el Registro Sanitario de dispositivos médicos Clase II (clase II sin criterio de conformidad establecido), III y IV con Carta de Aprobación Emitida por el MHLW de Japón (Acuerdo de Equivalencia Japón). Clase II, de acuerdo al artículo 83 del Reglamento de Insumos para la Salud.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_II,
				atencion: 30,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 30,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '401HC2',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRE,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.II],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_ACUERDO_EQUIVALENCIA
			},
			{
				modalidad: 'HC3',
				modalidadConamer: 'H',
				subtitulo: 'Clase III.',
				titulo: 'Dispositivos médicos Clase II (clase II sin criterio de conformidad establecido), III y IV con Carta de Aprobación Emitida por el MHLW de Japón (Acuerdo de Equivalencia Japón).',
				descripcion:
					'Permite tramitar el Registro Sanitario de dispositivos médicos Clase II (clase II sin criterio de conformidad establecido), III y IV con Carta de Aprobación Emitida por el MHLW de Japón (Acuerdo de Equivalencia Japón). Clase III, de acuerdo al artículo 83 del Reglamento de Insumos para la Salud.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_III,
				atencion: 30,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 30,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '401HC3',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRE,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.III],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_ACUERDO_EQUIVALENCIA
			},
			{
				modalidad: 'I',
				modalidadConamer: 'I',
				subtitulo: '',
				titulo: 'Productos de fabricación nacional considerados de Bajo Riesgo.',
				descripcion: 'Permite tramitar el Registro Sanitario de dispositivos médicos de fabricación nacional, de Clase I (Bajo Riesgo), para comercializar en territorio nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_I,
				atencion: 30,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 30,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '401IXX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRCI,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.I_BAJO_RIESGO],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_SIN_REGISTRO_CLASE_I
			},
			{
				modalidad: 'J',
				modalidadConamer: 'J',
				subtitulo: '',
				titulo: 'Producto de importación (fabricación extranjera) considerado de Bajo Riesgo.',
				descripcion: 'Permite tramitar el Registro Sanitario de dispositivos médicos de fabricación extranjera, de Clase I (Bajo Riesgo), para comercializar en territorio nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_I,
				atencion: 30,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 30,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '401JXX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRCI,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.I_BAJO_RIESGO],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_SIN_REGISTRO_CLASE_I
			},
			{
				modalidad: 'K',
				modalidadConamer: 'K',
				subtitulo: '',
				titulo: 'Productos de fabricación nacional considerados de Bajo Riesgo que son maquilados por otro establecimiento.',
				descripcion:
					'Permite tramitar el Registro Sanitario de dispositivos médicos de fabricación nacional, de Clase I (Bajo Riesgo), manufacturados total o parcialmente por un maquilador, para comercializar en territorio nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_I,
				atencion: 30,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 30,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '401KXX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_NRCI,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.I_BAJO_RIESGO],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_SIN_REGISTRO_CLASE_I
			}
		]
	},
	{
		homoclave: EHomoclaves['COFEPRIS-04-004'],
		titulo: 'Solicitud de Registro Sanitario de medicamentos Alopáticos, Vacunas y Hemoderivados.',
		modalidades: [
			{
				modalidad: 'AA',
				modalidadConamer: 'A',
				subtitulo: 'Medicamentos farmoquímicos.',
				titulo: 'Fabricación Nacional (Molécula Nueva).',
				descripcion:
					'Permite tramitar la autorización del Registro Sanitario de medicamentos farmoquímicos de fabricación nacional clasificados como molécula nueva, para comercializar en territorio nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.MAXIMO,
				atencion: 240,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 240,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 240,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '404AAX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_NR,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					medicamento: {
						tipoMedicamentos: [EMedicamentoTipo.FARMOQUIMICO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_SIN_REGISTRO
			},
			{
				modalidad: 'AB',
				modalidadConamer: 'A',
				subtitulo: 'Productos biológicos, vacunas y hemoderivados.',
				titulo: 'Fabricación Nacional (Molécula Nueva).',
				descripcion:
					'Permite tramitar la autorización del Registro Sanitario de productos biológicos, vacunas y hemoderivados de fabricación nacional clasificados como molécula nueva, para comercializar en territorio nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.MAXIMO,
				atencion: 240,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 240,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 240,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '404ABX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_BBV,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					medicamento: {
						tipoMedicamentos: [EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.VACUNA, EMedicamentoTipo.HEMODERIVADO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_BBV
			},
			{
				modalidad: 'BA',
				modalidadConamer: 'B',
				subtitulo: 'Medicamentos farmoquímicos.',
				titulo: 'Fabricación Nacional (Genérico).',
				descripcion:
					'Permite tramitar la autorización del Registro Sanitario de medicamentos farmoquímicos de fabricación nacional clasificados como  medicamentos genéricos, para comercializar en territorio nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.GENERICO_BIOCOMPARABLE,
				atencion: 180,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 180,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 180,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '404BAX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_NR,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					medicamento: {
						tipoMedicamentos: [EMedicamentoTipo.FARMOQUIMICO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_SIN_REGISTRO
			},
			{
				modalidad: 'BB',
				modalidadConamer: 'B',
				subtitulo: 'Productos biológicos y hemoderivados.',
				titulo: 'Fabricación Nacional (Genérico).',
				descripcion:
					'Permite tramitar la autorización del Registro Sanitario de productos biológicos y hemoderivados de fabricación nacional, para comercializar en territorio nacional. Se excluyen las vacunas.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.GENERICO_BIOCOMPARABLE,
				atencion: 180,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 180,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 180,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '404BBX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_BBV,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					medicamento: {
						tipoMedicamentos: [EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.VACUNA, EMedicamentoTipo.HEMODERIVADO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_BBV
			},
			{
				modalidad: 'BC',
				modalidadConamer: 'B',
				subtitulo: 'Medicamentos farmoquímicos de fabricación nacional con estudios no clínicos y clínicos.',
				titulo: 'Fabricación Nacional (Genérico).',
				descripcion:
					'Permite tramitar la autorización del Registro Sanitario de medicamentos farmoquímicos de fabricación nacional clasificados como medicamentos genéricos (que sustente seguridad y eficacia con estudios no clínicos y clínicos), para comercializar en territorio nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.GENERICO_BIOCOMPARABLE,
				atencion: 180,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 180,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 180,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '404BCX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_NR,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					medicamento: {
						tipoMedicamentos: [EMedicamentoTipo.FARMOQUIMICO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_SIN_REGISTRO
			},
			{
				modalidad: 'CA',
				modalidadConamer: 'C',
				subtitulo: 'Medicamentos farmoquímicos.',
				titulo: 'Fabricación extranjera (Molécula Nueva).',
				descripcion:
					'Permite tramitar la autorización del Registro Sanitario de medicamentos farmoquímicos de fabricación extranjera clasificados como molécula nueva, para comercializar en territorio nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.MAXIMO,
				atencion: 240,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 240,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 240,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '404CAX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_NR,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_SIN_REGISTRO
			},
			{
				modalidad: 'CB',
				modalidadConamer: 'C',
				subtitulo: 'Productos biológicos, vacunas y hemoderivados.',
				titulo: 'Fabricación extranjera (Molécula Nueva).',
				descripcion:
					'Permite tramitar la autorización del Registro Sanitario de productos biológicos, vacunas y hemoderivados de fabricación extranjera clasificados como molécula nueva, para comercializar en territorio nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.MAXIMO,
				atencion: 240,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 240,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 240,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '404CBX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_BBV,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_BBV
			},
			{
				modalidad: 'DA',
				modalidadConamer: 'D',
				subtitulo: 'Medicamentos farmoquímicos.',
				titulo: 'Fabricación extranjera (Genérico).',
				descripcion:
					'Permite tramitar la autorización del Registro Sanitario de medicamentos farmoquímicos de fabricación extranjera clasificados como medicamentos genéricos, para comercializar en territorio nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.GENERICO_BIOCOMPARABLE,
				atencion: 180,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 180,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 180,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '404DAX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_NR,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_SIN_REGISTRO
			},
			{
				modalidad: 'DB',
				modalidadConamer: 'D',
				subtitulo: 'Productos biológicos y hemoderivados.',
				titulo: 'Fabricación extranjera (Genérico).',
				descripcion:
					'Permite tramitar la autorización del Registro Sanitario de productos biológicos y hemoderivados de fabricación extranjera, para comercializar en territorio nacional. Se excluyen las vacunas.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.GENERICO_BIOCOMPARABLE,
				atencion: 180,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 180,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 180,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '404DBX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_BBV,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_BBV
			},
			{
				modalidad: 'DC',
				modalidadConamer: 'D',
				subtitulo: 'Medicamentos farmoquímicos de fabricación extranjera con estudios no clínicos y clínicos.',
				titulo: 'Fabricación extranjera (Genérico).',
				descripcion:
					'Permite tramitar la autorización del Registro Sanitario de medicamentos farmoquímicos de fabricación extranjera clasificados como medicamentos genéricos (que sustente seguridad y eficacia con estudios no clínicos y clínicos), para comercializar en territorio nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.GENERICO_BIOCOMPARABLE,
				atencion: 180,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 180,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 180,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '404DCX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_NR,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					medicamento: {
						tipoMedicamentos: [EMedicamentoTipo.FARMOQUIMICO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_SIN_REGISTRO
			},
			{
				modalidad: 'E',
				modalidadConamer: 'E',
				subtitulo: '',
				titulo: 'Medicamento biotecnológico innovador de fabricación nacional.',
				descripcion: 'Permite tramitar la autorización del Registro Sanitario de medicamentos biotecnológicos innovadores de fabricación nacional, para comercializar en territorio nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.MAXIMO,
				atencion: 180,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 180,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 150,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '404EBX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_BBV,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_BBV
			},
			{
				modalidad: 'F',
				modalidadConamer: 'F',
				subtitulo: '',
				titulo: 'Medicamento biotecnológico innovador de fabricación extranjera.',
				descripcion: 'Permite tramitar la autorización del Registro Sanitario de medicamentos biotecnológicos innovadores de fabricación extranjera, para comercializar en territorio nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.MAXIMO,
				atencion: 180,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 180,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 150,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '404FBX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_BBV,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_BBV
			},
			{
				modalidad: 'G',
				modalidadConamer: 'G',
				subtitulo: '',
				titulo: 'Medicamento biotecnológico biocomparable de fabricación nacional.',
				descripcion: 'Permite tramitar la autorización del Registro Sanitario de medicamentos biotecnológicos biocomparables de fabricación nacional, para comercializar en territorio nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.GENERICO_BIOCOMPARABLE,
				atencion: 180,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 180,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 150,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '404GBX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_BBV,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_BBV
			},
			{
				modalidad: 'H',
				modalidadConamer: 'H',
				subtitulo: '',
				titulo: 'Medicamento biotecnológico biocomparable de fabricación extranjera.',
				descripcion: 'Permite tramitar la autorización del Registro Sanitario de medicamentos biotecnológicos biocomparables de fabricación extranjera, para comercializar en territorio nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.GENERICO_BIOCOMPARABLE,
				atencion: 180,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 180,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 150,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '404HBX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_BBV,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_BBV
			},
			{
				modalidad: 'I',
				modalidadConamer: 'I',
				subtitulo: '',
				titulo: 'Productos biológicos al amparo de los Acuerdos de Equivalencia (Molécula Nueva).',
				descripcion:
					'Permite tramitar la autorización del Registro Sanitario de productos biológicos con ingredientes activos no registrados en México, pero que se encuentra autorizado para su venta en la Unión Europea, Suiza, Estados Unidos de América, Canadá o Australia.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.MAXIMO,
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 45,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '404IBX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_BBV,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_BBV
			},
			{
				modalidad: 'J',
				modalidadConamer: 'J',
				subtitulo: '',
				titulo: 'Medicamento biotecnológico innovador, al amparo de los Acuerdos de Equivalencia.',
				descripcion:
					'Permite tramitar la autorización del Registro Sanitario de medicamentos biotecnológicos con ingredientes activos no registrados en México, pero que se encuentra autorizado para su venta en la Unión Europea, Suiza, Estados Unidos de América, Canadá o Australia.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.MAXIMO,
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 45,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '404JBX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_BBV,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_BBV
			},
			{
				modalidad: 'K',
				modalidadConamer: 'K',
				subtitulo: '',
				titulo: 'Medicamento alopático al amparo de los Acuerdos de Equivalencia (Molécula Nueva).',
				descripcion:
					'Permite tramitar la autorización del Registro Sanitario de medicamentos alopáticos cuyos ingredientes activos no estén registrados en México, pero se encuentre autorizado para su venta en la Unión Europea, Suiza, Estados Unidos de América, Canadá y Australia.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.MAXIMO,
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 45,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '404KAX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_NR,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_SIN_REGISTRO
			}
		]
	},
	{
		homoclave: EHomoclaves['COFEPRIS-04-005'],
		titulo: 'Solicitud de Registro Sanitario de fórmulas de alimentación enteral especializada de fabricación nacional o extranjera',
		modalidades: [
			{
				modalidad: 'M',
				modalidadConamer: 'X',
				subtitulo: 'Modificaciones a las condiciones de Registro Sanitario de fórmulas de alimentación enteral especializada de fabricación nacional o extranjera.',
				titulo: '',
				descripcion:
					'Permite tramitar la autorización de modificación a las condiciones del Registro Sanitario de Fórmulas de Alimentación Enteral Especializada de Fabricación nacional o extranjera.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.HHV,
				atencion: 180,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 180,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 180,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '405XMX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_FAEE,
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [
						EHomoclaveBanderasModificaciones.SELECCION_AI,
						EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO,
						EHomoclaveBanderasModificaciones.INFO_SOLICITANTE,
						EHomoclaveBanderasModificaciones.PAGO
					],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: [EMedicamentoTipo.FORMULA_ALIMENTACION_ENTERAL_ESPECIALIZADA]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.MODIFICACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_SIN_REGISTRO
			},
			{
				modalidad: 'P',
				modalidadConamer: 'X',
				subtitulo: 'Prórroga del Registro Sanitario de fórmulas de alimentación enteral especializada de fabricación nacional o extranjera.',
				titulo: '',
				descripcion: 'Permite tramitar la autorización de prórroga del Registro Sanitario de Fórmulas de Alimentación Enteral Especializada de Fabricación nacional o extranjera.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.HHV,
				atencion: 180,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 180,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 180,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '405XPX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_FAEE,
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [
						EHomoclaveBanderasModificaciones.SELECCION_AI,
						EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO,
						EHomoclaveBanderasModificaciones.INFO_SOLICITANTE,
						EHomoclaveBanderasModificaciones.PAGO
					],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: [EMedicamentoTipo.FORMULA_ALIMENTACION_ENTERAL_ESPECIALIZADA]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.PRIMERA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_SIN_REGISTRO
			},
			{
				modalidad: 'R',
				modalidadConamer: 'X',
				subtitulo: 'Registro Sanitario de fórmulas de alimentación enteral especializada de fabricación nacional o extranjera.',
				titulo: '',
				descripcion: 'Permite tramitar la autorización del Registro Sanitario de Fórmulas de Alimentación Enteral Especializada de fabricación nacional o extranjera.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.HHV,
				atencion: 180,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 180,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 180,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '405XRX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_FAEE,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_SIN_REGISTRO
			}
		]
	},
	{
		homoclave: EHomoclaves['COFEPRIS-04-006'],
		titulo: 'Solicitud de Registro Sanitario de medicamentos herbolarios',
		modalidades: [
			{
				modalidad: 'A',
				modalidadConamer: 'A',
				subtitulo: '',
				titulo: 'Medicamentos herbolarios de fabricación nacional.',
				descripcion: 'Permite tramitar la autorización del Registro Sanitario de medicamentos herbolarios de fabricación nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.HHV,
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 45,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '406AXX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMHHA,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: []
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_HHV
			},
			{
				modalidad: 'B',
				modalidadConamer: 'B',
				subtitulo: '',
				titulo: 'Medicamentos herbolarios de fabricación extranjera.',
				descripcion: 'Permite tramitar la autorización del Registro Sanitario de medicamentos herbolarios de fabricación extranjera.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.HHV,
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 45,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '406BXX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMHHA,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: []
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_HHV
			}
		]
	},
	{
		homoclave: EHomoclaves['COFEPRIS-04-007'],
		titulo: 'Solicitud de Registro Sanitario de medicamentos homeopáticos',
		modalidades: [
			{
				modalidad: 'A',
				modalidadConamer: 'A',
				subtitulo: '',
				titulo: 'Medicamentos homeopáticos de fabricación nacional.',
				descripcion: 'Permite tramitar la autorización del Registro Sanitario de medicamentos homeopáticos de fabricación nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.HHV,
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 45,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '407AXX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMHHA,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: []
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_HHV
			},
			{
				modalidad: 'B',
				modalidadConamer: 'B',
				subtitulo: '',
				titulo: 'Medicamentos homeopáticos de fabricación extranjera.',
				descripcion: 'Permite tramitar la autorización del Registro Sanitario de  medicamentos homeopáticos de fabricación extranjera.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.HHV,
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 45,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '407BXX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMHHA,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: []
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_HHV
			}
		]
	},
	{
		homoclave: EHomoclaves['COFEPRIS-04-008'],
		titulo: 'Solicitud de Registro Sanitario de medicamentos vitamínicos',
		modalidades: [
			{
				modalidad: 'A',
				modalidadConamer: 'A',
				subtitulo: '',
				titulo: 'Medicamentos vitamínicos de fabricación nacional.',
				descripcion: 'Permite tramitar la autorización del Registro Sanitario de medicamentos vitamínicos de fabricación nacional.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.HHV,
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 45,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '408AXX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMHHA,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: []
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_HHV
			},
			{
				modalidad: 'B',
				modalidadConamer: 'B',
				subtitulo: '',
				titulo: 'Medicamentos vitamínicos de fabricación extranjera.',
				descripcion: 'Permite tramitar la autorización del Registro Sanitario de  medicamentos vitamínicos de fabricación extranjera.',
				version: 1,
				porcentajePago: 1,
				grupoPago: EMedicamentoGrupoPago.HHV,
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 45,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '408BXX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMHHA,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: []
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.AUTORIZACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_HHV
			}
		]
	},
	{
		homoclave: EHomoclaves['COFEPRIS-04-010'],
		titulo: 'Solicitud de Autorización de Protocolo de Investigación en Seres Humanos.',
		modalidades: [
			{
				modalidad: 'A',
				modalidadConamer: 'A',
				subtitulo: '',
				titulo: 'Medicamentos, Biológicos o Biotecnológicos.',
				descripcion: 'Este trámite sirve para obtener la autorización para iniciar un estudio, protocolo o ensayo clínico en seres humanos, para probar un medicamento, biológico o biotecnológico.',
				version: 20231129,
				atencion: 90,
				atencionPrevencion: 45,
				tiempoRespuestaSolicitante: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '410A',
				unidadOrganizativa: 'COFEPRIS_CAS_DEAPE_SEFM_EC',
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				configuracionEvaluacion: {
					permitirOk: true,
					permitirWarning: true,
					permitirError: false,
					permitirObservaciones: true
				},
				evaluaciones: [EHomoclaveContextResultadoEvaluacion.AUTORIZADO, EHomoclaveContextResultadoEvaluacion.PREVENIDO, EHomoclaveContextResultadoEvaluacion.DESECHADO],
				formatos: {
					solicitud: [
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						{
							formato: 'FORMATO_REQUISITOS_PROTOCOLOS_A',
							secciones: ['SOLICITANTE_A'],
							titulo: 'Comprobante de Pago y Carta de Solicitud'
						},
						{
							formato: 'FORMATO_REQUISITOS_PROTOCOLOS_A',
							secciones: ['PATROCINADOR_A'],
							titulo: 'Patrocinador'
						},
						{
							formato: 'FORMATO_REQUISITOS_PROTOCOLOS_A',
							secciones: ['INVESTIGACION_A'],
							titulo: 'Documentos de la Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_PROTOCOLOS_A',
							secciones: ['PRODUCTO_INVESTIGACION_A'],
							titulo: 'Producto de Investigación'
						},
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_A',
						//   secciones: ['CENTRO_INVESTIGACION_A'],
						//   titulo: 'Centro de Investigación'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_A',
						//   secciones: ['CENTRO_URGENCIAS_A'],
						//   titulo: 'Centro de Atención a Urgencias'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_A',
						//   secciones: ['INVESTIGADOR_PRINCIPAL_A'],
						//   titulo: 'Investigador Principal'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_A',
						//   secciones: ['EQUIPO_INVESTIGACION_A'],
						//   titulo: 'Equipo de Investigación'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_A',
						//   secciones: ['COMITE_ETICA_INVESTIGACION_A'],
						//   titulo: 'Comité de Ética en Investigación'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_A',
						//   secciones: ['COMITE_INVESTIGACION_A'],
						//   titulo: 'Comité de Investigación'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_A',
						//   secciones: ['COMITE_BIOSEGURIDAD_A'],
						//   titulo: 'Comité de Bioseguridad'
						// },
						{
							formato: 'FORMATO_MULTICENTRO_INVESTIGACION',
							secciones: ['CENTRO_INVESTIGACION'],
							titulo: 'Centros de Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_PROTOCOLOS_A',
							secciones: ['IMPORTADOR_A'],
							titulo: 'Importador'
						},
						{
							formato: 'FORMATO_INFORMACION_COMPLEMENTARIA',
							secciones: ['RNEC'],
							titulo: 'Información Complementaria'
						},
						{
							formato: 'FORMATO_REQUISITOS_PROTOCOLOS_A',
							secciones: ['OTROS_DOCUMENTOS_A'],
							titulo: 'Otros Documentos'
						}
					],
					evaluacion: [
						//FORMATOS COMPLEMENTARIOS PARA EL DICTAMINADOR EN UN PROTOCOLOR DIGITAL
						{
							formato: 'FORMATO_DICTAMINADOR_PROTOCOLOS_A',
							secciones: ['AUTORIZADO'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_PROTOCOLOS_A',
							secciones: ['PREVENCION'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_PROTOCOLOS_A',
							secciones: ['DESECHO'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						//FORMATOS DE EVALUACIÓN Y RESOLUCIÓN DEL DICTAMINADOR, ESTADÍSTICO Y CMN
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['RESOLUCION'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_PRINCIPAL'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_ESTADISTICO'],
							actores: [EActor.DICTAMINADOR_ESTADISTICO]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_CMN'],
							actores: [EActor.COORDINADOR_COMITE_MOLECULAS_NUEVAS]
						}
					]
				}
			},
			{
				modalidad: 'B',
				modalidadConamer: 'B',
				subtitulo: '',
				titulo: 'Medicamentos (Estudios de Bioequivalencia).',
				descripcion:
					'Este trámite sirve para obtener la autorización para iniciar un estudio, protocolo o ensayo clínicos en seres humanos para administrar medicamentos con la finalidad de convertirse en un medicamento genérico.',
				version: 20231129,
				atencion: 90,
				atencionPrevencion: 45,
				tiempoRespuestaSolicitante: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '410B',
				unidadOrganizativa: 'COFEPRIS_CAS_DEAPE_SEFM_EC',
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				configuracionEvaluacion: {
					permitirOk: true,
					permitirWarning: true,
					permitirError: false,
					permitirObservaciones: true,
					longitudMaximaObservaciones: 100
				},
				evaluaciones: [EHomoclaveContextResultadoEvaluacion.AUTORIZADO, EHomoclaveContextResultadoEvaluacion.PREVENIDO, EHomoclaveContextResultadoEvaluacion.DESECHADO],
				formatos: {
					solicitud: [
						{
							formato: 'FF_COFEPRIS_01_B',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01_B',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						{
							formato: 'FORMATO_REQUISITOS_PROTOCOLOS_B',
							secciones: ['SOLICITANTE_B'],
							titulo: 'Comprobante de Pago y Carta de Solicitud'
						},
						{
							formato: 'FORMATO_REQUISITOS_PROTOCOLOS_B',
							secciones: ['PATROCINADOR_B'],
							titulo: 'Patrocinador'
						},
						{
							formato: 'FORMATO_REQUISITOS_PROTOCOLOS_B',
							secciones: ['INVESTIGACION_B'],
							titulo: 'Documentos de la Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_PROTOCOLOS_B',
							secciones: ['PRODUCTO_INVESTIGACION_B'],
							titulo: 'Producto de Investigación'
						},
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_B',
						//   secciones: ['CENTRO_INVESTIGACION_B'],
						//   titulo: 'Centro de Investigación'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_B',
						//   secciones: ['CENTRO_URGENCIAS_B'],
						//   titulo: 'Centro de Atención a Urgencias'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_B',
						//   secciones: ['INVESTIGADOR_PRINCIPAL_B'],
						//   titulo: 'Investigador Principal'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_B',
						//   secciones: ['EQUIPO_INVESTIGACION_B'],
						//   titulo: 'Equipo de Investigación'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_B',
						//   secciones: ['COMITE_ETICA_INVESTIGACION_B'],
						//   titulo: 'Comité de Ética en Investigación'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_B',
						//   secciones: ['COMITE_INVESTIGACION_B'],
						//   titulo: 'Comité de Investigación'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_B',
						//   secciones: ['COMITE_BIOSEGURIDAD_B'],
						//   titulo: 'Comité de Bioseguridad'
						// },
						{
							formato: 'FORMATO_MULTICENTRO_INVESTIGACION',
							secciones: ['CENTRO_INVESTIGACION'],
							titulo: 'Centros de Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_PROTOCOLOS_B',
							secciones: ['IMPORTADOR_B'],
							titulo: 'Importador'
						},
						{
							formato: 'FORMATO_INFORMACION_COMPLEMENTARIA_B',
							secciones: ['RNEC'],
							titulo: 'Información Complementaria'
						},
						{
							formato: 'FORMATO_REQUISITOS_PROTOCOLOS_B',
							secciones: ['OTROS_DOCUMENTOS_B'],
							titulo: 'Otros Documentos'
						}
					],
					evaluacion: [
						//FORMATOS COMPLEMENTARIOS PARA EL DICTAMINADOR EN UN PROTOCOLOR DIGITAL
						{
							formato: 'FORMATO_DICTAMINADOR_PROTOCOLOS_B',
							secciones: ['AUTORIZADO'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_PROTOCOLOS_B',
							secciones: ['PREVENCION'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_PROTOCOLOS_B',
							secciones: ['DESECHO'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						//FORMATOS DE EVALUACIÓN Y RESOLUCIÓN DEL DICTAMINADOR, ESTADÍSTICO Y CMN
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['RESOLUCION'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_PRINCIPAL'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_ESTADISTICO'],
							actores: [EActor.DICTAMINADOR_ESTADISTICO]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_CMN'],
							actores: [EActor.COORDINADOR_COMITE_MOLECULAS_NUEVAS]
						}
					]
				}
			},
			{
				modalidad: 'C',
				modalidadConamer: 'C',
				subtitulo: '',
				titulo: 'Nuevos Recursos y otros métodos de prevención, diagnóstico, tratamiento y rehabilitación que se realice en seres humanos o en sus productos biológicos, excepto los farmacológicos.',
				descripcion:
					'Este trámite sirve para obtener una autorización para realizar estudios clínicos en seres humanos con nuevos recursos como: injertos, prótesis, trasplantes, tratamiento y rehabilitación de seres humanos y en sus productos biológicos y que no involucre investigación farmacológica.',
				version: 20231129,
				atencion: 90,
				atencionPrevencion: 45,
				tiempoRespuestaSolicitante: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '410C',
				unidadOrganizativa: 'COFEPRIS_CAS_DEAPE_SEFM_EC',
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				evaluaciones: [EHomoclaveContextResultadoEvaluacion.AUTORIZADO, EHomoclaveContextResultadoEvaluacion.PREVENIDO, EHomoclaveContextResultadoEvaluacion.DESECHADO],
				formatos: {
					solicitud: [
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						{
							formato: 'FORMATO_REQUISITOS_PROTOCOLOS_C',
							secciones: ['SOLICITANTE_C'],
							titulo: 'Comprobante de Pago y Carta de Solicitud'
						},
						{
							formato: 'FORMATO_REQUISITOS_PROTOCOLOS_C',
							secciones: ['PATROCINADOR_C'],
							titulo: 'Patrocinador'
						},
						{
							formato: 'FORMATO_REQUISITOS_PROTOCOLOS_C',
							secciones: ['INVESTIGACION_C'],
							titulo: 'Documentos de la Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_PROTOCOLOS_C',
							secciones: ['PRODUCTO_INVESTIGACION_C'],
							titulo: 'Producto de Investigación'
						},
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_C',
						//   secciones: ['CENTRO_INVESTIGACION_C'],
						//   titulo: 'Centro de Investigación'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_C',
						//   secciones: ['CENTRO_URGENCIAS_C'],
						//   titulo: 'Centro de Atención a Urgencias'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_C',
						//   secciones: ['INVESTIGADOR_PRINCIPAL_C'],
						//   titulo: 'Investigador Principal'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_C',
						//   secciones: ['EQUIPO_INVESTIGACION_C'],
						//   titulo: 'Equipo de Investigación'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_C',
						//   secciones: ['COMITE_ETICA_INVESTIGACION_C'],
						//   titulo: 'Comité de Ética en Investigación'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_C',
						//   secciones: ['COMITE_INVESTIGACION_C'],
						//   titulo: 'Comité de Investigación'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_C',
						//   secciones: ['COMITE_BIOSEGURIDAD_C'],
						//   titulo: 'Comité de Bioseguridad'
						// },
						{
							formato: 'FORMATO_MULTICENTRO_INVESTIGACION',
							secciones: ['CENTRO_INVESTIGACION'],
							titulo: 'Centros de Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_PROTOCOLOS_C',
							secciones: ['IMPORTADOR_C'],
							titulo: 'Importador'
						},
						{
							formato: 'FORMATO_INFORMACION_COMPLEMENTARIA',
							secciones: ['RNEC'],
							titulo: 'Información Complementaria'
						},
						{
							formato: 'FORMATO_REQUISITOS_PROTOCOLOS_C',
							secciones: ['OTROS_DOCUMENTOS_C'],
							titulo: 'Otros Documentos'
						}
					],
					evaluacion: [
						//FORMATOS COMPLEMENTARIOS PARA EL DICTAMINADOR EN UN PROTOCOLOR DIGITAL
						{
							formato: 'FORMATO_DICTAMINADOR_PROTOCOLOS_C',
							secciones: ['AUTORIZADO'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_PROTOCOLOS_C',
							secciones: ['PREVENCION'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_PROTOCOLOS_C',
							secciones: ['DESECHO'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						//FORMATOS DE EVALUACIÓN Y RESOLUCIÓN DEL DICTAMINADOR, ESTADÍSTICO Y CMN
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['RESOLUCION'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_PRINCIPAL'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_ESTADISTICO'],
							actores: [EActor.DICTAMINADOR_ESTADISTICO]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_CMN'],
							actores: [EActor.COORDINADOR_COMITE_MOLECULAS_NUEVAS]
						}
					]
				}
			},
			{
				modalidad: 'D',
				modalidadConamer: 'D',
				subtitulo: '',
				titulo: 'Investigación sin Riesgo.',
				descripcion:
					'Este trámite sirve para obtener la autorización para iniciar una investigación sin Riesgo, que involucra la recopilación y análisis de la información o datos de los sujetos que participan en el estudio, en los que no se realiza ninguna intervención o modificación intencionada en las variables fisiológicas, psicológicas y sociales de estos; y en los cuales se pueden emplear técnicas o métodos de investigación documental.',
				version: 20231129,
				atencion: 90,
				atencionPrevencion: 45,
				tiempoRespuestaSolicitante: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '410D',
				unidadOrganizativa: 'COFEPRIS_CAS_DEAPE_SEFM_EC',
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				evaluaciones: [EHomoclaveContextResultadoEvaluacion.AUTORIZADO, EHomoclaveContextResultadoEvaluacion.PREVENIDO, EHomoclaveContextResultadoEvaluacion.DESECHADO],
				formatos: {
					solicitud: [
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						{
							formato: 'FORMATO_REQUISITOS_PROTOCOLOS_D',
							secciones: ['SOLICITANTE_D'],
							titulo: 'Comprobante de Pago y Carta de Solicitud'
						},
						{
							formato: 'FORMATO_REQUISITOS_PROTOCOLOS_D',
							secciones: ['PATROCINADOR_D'],
							titulo: 'Patrocinador'
						},
						{
							formato: 'FORMATO_REQUISITOS_PROTOCOLOS_D',
							secciones: ['INVESTIGACION_D'],
							titulo: 'Documentos de la Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_PROTOCOLOS_D',
							secciones: ['PRODUCTO_INVESTIGACION_D'],
							titulo: 'Producto de Investigación'
						},
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_D',
						//   secciones: ['CENTRO_INVESTIGACION_D'],
						//   titulo: 'Centro de Investigación'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_D',
						//   secciones: ['INVESTIGADOR_PRINCIPAL_D'],
						//   titulo: 'Investigador Principal'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_D',
						//   secciones: ['EQUIPO_INVESTIGACION_D'],
						//   titulo: 'Equipo de Investigación'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_D',
						//   secciones: ['COMITE_ETICA_INVESTIGACION_D'],
						//   titulo: 'Comité de Ética en Investigación'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_D',
						//   secciones: ['COMITE_INVESTIGACION_D'],
						//   titulo: 'Comité de Investigación'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_PROTOCOLOS_D',
						//   secciones: ['COMITE_BIOSEGURIDAD_D'],
						//   titulo: 'Comité de Bioseguridad'
						// },
						{
							formato: 'FORMATO_MULTICENTRO_INVESTIGACION',
							secciones: ['CENTRO_INVESTIGACION'],
							titulo: 'Centros de Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_PROTOCOLOS_D',
							secciones: ['IMPORTADOR_D'],
							titulo: 'Importador'
						},
						{
							formato: 'FORMATO_INFORMACION_COMPLEMENTARIA',
							secciones: ['RNEC'],
							titulo: 'Información Complementaria'
						},
						{
							formato: 'FORMATO_REQUISITOS_PROTOCOLOS_D',
							secciones: ['OTROS_DOCUMENTOS_D'],
							titulo: 'Otros Documentos'
						}
					],
					evaluacion: [
						//FORMATOS COMPLEMENTARIOS PARA EL DICTAMINADOR EN UN PROTOCOLOR DIGITAL
						{
							formato: 'FORMATO_DICTAMINADOR_PROTOCOLOS_D',
							secciones: ['AUTORIZADO'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_PROTOCOLOS_D',
							secciones: ['PREVENCION'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_PROTOCOLOS_D',
							secciones: ['DESECHO'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						//FORMATOS DE EVALUACIÓN Y RESOLUCIÓN DEL DICTAMINADOR, ESTADÍSTICO Y CMN
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['RESOLUCION'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_PRINCIPAL'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_ESTADISTICO'],
							actores: [EActor.DICTAMINADOR_ESTADISTICO]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_CMN'],
							actores: [EActor.COORDINADOR_COMITE_MOLECULAS_NUEVAS]
						}
					]
				}
			}
		]
	},
	{
		homoclave: EHomoclaves['COFEPRIS-04-012'],
		titulo: 'Solicitud de revocación del Registro Sanitario y otras autorizaciones.',
		modalidades: [
			{
				modalidad: 'A',
				modalidadConamer: 'X',
				subtitulo: 'Medicamentos.',
				titulo: '',
				descripcion: 'Permite tramitar la revocación del Registro Sanitario de medicamentos o el Reconocimiento de medicamentos Huérfanos',
				version: 1,
				porcentajePago: 0,
				atencion: 30,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 30,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 90,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '412AXX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_MCR,
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.SELECCION_AI, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: [
							EMedicamentoTipo.BIOLOGICO,
							EMedicamentoTipo.BIOTECNOLOGICO,
							EMedicamentoTipo.FARMOQUIMICO,
							EMedicamentoTipo.FORMULA_ALIMENTACION_ENTERAL_ESPECIALIZADA,
							EMedicamentoTipo.HEMODERIVADO,
							EMedicamentoTipo.HERBOLARIO,
							EMedicamentoTipo.HOMEOPATICO,
							EMedicamentoTipo.HUERFANO,
							EMedicamentoTipo.VACUNA,
							EMedicamentoTipo.VITAMINICO
						]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.REVOCACION
				},
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_CON_REGISTRO
			},
			{
				modalidad: 'B',
				modalidadConamer: 'X',
				subtitulo: 'Dispositivos médicos.',
				titulo: '',
				descripcion: 'Solicitud de revocación del Registro Sanitario de dispositivos médicos.',
				version: 1,
				porcentajePago: 0,
				atencion: 30,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 30,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 90,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '412BXX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_DCR,
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.SELECCION_AI, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE],
					dispositivo: {
						claseDispositivo: [EDispositivoClaseRIS83.I, EDispositivoClaseRIS83.II, EDispositivoClaseRIS83.III, EDispositivoClaseRIS83.I_BAJO_RIESGO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.REVOCACION
				},
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_CON_REGISTRO
			}
		]
	},
	{
		homoclave: EHomoclaves['COFEPRIS-09-012'],
		titulo: 'Solicitud de Modificación o Enmienda a la Autorización de Protocolo de Investigación.',
		modalidades: [
			{
				modalidad: 'A',
				modalidadConamer: 'X',
				subtitulo: 'Documentos Base.',
				titulo: '',
				descripcion:
					'Permite solicitar la autorización de las nuevas versiones generadas de los documentos base previamente autorizados en el oficio de conducción, en los cuales se refleja el resultado de las actualizaciones derivadas de las variaciones, incluyendo de forma enunciativa más no limitativa, la estructura metodológica, sustitución del investigador principal, identificación de riesgos en los sujetos de investigación, entre otras; realizadas respecto a la versión que le antecede.',
				tituloLista: 'Documentos susceptibles a actualizaciones a través de esta modalidad:',
				elementosLista: ['Protocolo de Investigación', 'Manual del investigador', 'Formatos de Consentimientos y Asentimientos Informados'],
				version: 20231129,
				atencion: 90,
				atencionPrevencion: 45,
				tiempoRespuestaSolicitante: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '912XA',
				unidadOrganizativa: 'COFEPRIS_CAS_DEAPE_SEFM_EC',
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				evaluaciones: [EHomoclaveContextResultadoEvaluacion.AUTORIZADO, EHomoclaveContextResultadoEvaluacion.PREVENIDO, EHomoclaveContextResultadoEvaluacion.DESECHADO],
				omitirCamposDigital: [
					{
						formato: 'FORMATO_ANTECEDENTES',
						seccion: 'ANTECEDENTES',
						tagCampo: 'NUMERO_AUTORIZACION_INICIAL',
						etapa: 'solicitud'
					}
				],
				formatosAntecedentes: {
					solicitud: [
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						//FORMATOS SOLICITANTE LEGADO
						{
							formato: 'SOLICITANTE_LEGADO_ENMIENDA_A',
							secciones: ['INFORMACION_COMPLEMENTARIA_ENMIENDA_A'],
							titulo: 'INFORMACIÓN COMPLEMENTARIA'
						},
						{
							formato: 'SOLICITANTE_LEGADO_ENMIENDA_A',
							secciones: ['METODOLOGIA_ENMIENDA_A'],
							titulo: 'METOLOGÍA PREVIAMENTE AUTORIZADA'
						}
					],
					evaluacion: [
						//FORMATOS DICTMANADOR PARA UN ENSAYO CLÍNICO LEGADO
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_A',
							secciones: ['ANTECEDENTE_PROTOCOLOS_LEGADO_A'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_A',
							secciones: ['METODOLOGIA_PREVIAMENTE_AUTORIZADA_A'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_A',
							secciones: ['OTROS_DATO_OFICIO_AUTORIZADO_A'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						}
					]
				},
				formatos: {
					solicitud: [
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						{
							//FORMATOS DE SOLICITANTE PARA UNA ENMIENDA
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_A',
							secciones: ['SOLICITANTE_ENMIENDA_A'],
							titulo: 'Comprobante de Pago y Carta de Solicitud'
						},
						{
							formato: 'FORMATO_ANTECEDENTES',
							secciones: ['ANTECEDENTES'],
							titulo: 'Antecedentes del Protocolo'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_A',
							secciones: ['INVESTIGACION_ENMIENDA_A'],
							titulo: 'Documentos de la Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_A',
							secciones: ['CENTRO_INVESTIGACION_ENMIENDA_A'],
							titulo: 'Centro de Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_A',
							secciones: ['COMITE_ETICA_INVESTIGACION_ENMIENDA_A'],
							titulo: 'Comité de Ética en Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_A',
							secciones: ['COMITE_INVESTIGACION_ENMIENDA_A'],
							titulo: 'Comité de Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_A',
							secciones: ['COMITE_BIOSEGURIDAD_ENMIENDA_A'],
							titulo: 'Comité de Bioseguridad'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_A',
							secciones: ['RESUMEN_ENMIENDA_A'],
							titulo: 'Información Complementaria'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_A',
							secciones: ['OTROS_DOCUMENTOS_ENMIENDA_A'],
							titulo: 'Otros Documentos'
						}
					],
					evaluacion: [
						//FORMATOS COMPLEMENTARIOS PARA EL DICTAMINADOR EN UNA ENMIENDA DIGITAL
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_A',
							secciones: ['AUTORIZADO_A'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_A',
							secciones: ['PREVENCION_A'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_A',
							secciones: ['DESECHO_A'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
							//FORMATOS DE RESOLUCIÓN DEL DICTAMINADOR, ESTADÍSTICO Y CMN
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['RESOLUCION'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_PRINCIPAL'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_ESTADISTICO'],
							actores: [EActor.DICTAMINADOR_ESTADISTICO]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_CMN'],
							actores: [EActor.COORDINADOR_COMITE_MOLECULAS_NUEVAS]
						}
					]
				}
			},
			{
				modalidad: 'B',
				modalidadConamer: 'X',
				subtitulo: 'Inclusión de centro (protocolo subsecuente).',
				titulo: '',
				descripcion:
					'Permite solicitar la adición de un nuevo centro, Institución o establecimiento donde se realizará la investigación para la salud. De un protocolo clínico previamente autorizado en el oficio de conducción bajo la homoclave COFEPRIS-04-010 en la modalidad correspondiente (A, C o D).',
				version: 20231129,
				atencion: 45,
				atencionPrevencion: 45,
				tiempoRespuestaSolicitante: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '912XB',
				unidadOrganizativa: 'COFEPRIS_CAS_DEAPE_SEFM_EC',
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				omitirCamposDigital: [
					{
						formato: 'FORMATO_ANTECEDENTES',
						seccion: 'ANTECEDENTES',
						tagCampo: 'NUMERO_AUTORIZACION_INICIAL',
						etapa: 'solicitud'
					}
				],
				evaluaciones: [EHomoclaveContextResultadoEvaluacion.AUTORIZADO, EHomoclaveContextResultadoEvaluacion.PREVENIDO, EHomoclaveContextResultadoEvaluacion.DESECHADO],
				formatosAntecedentes: {
					solicitud: [
						{
							formato: 'FF_COFEPRIS_01_B',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01_B',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						//FORMATOS SOLICITANTE LEGADO
						{
							formato: 'SOLICITANTE_LEGADO_ENMIENDA_B',
							secciones: ['INFORMACION_COMPLEMENTARIA_ENMIENDA_B'],
							titulo: 'INFORMACIÓN COMPLEMENTARIA'
						},
						{
							formato: 'SOLICITANTE_LEGADO_ENMIENDA_B',
							secciones: ['METODOLOGIA_ENMIENDA_B'],
							titulo: 'METOLOGÍA PREVIAMENTE AUTORIZADA'
						}
					],
					evaluacion: [
						//FORMATOS DICTMANADOR PARA UN ENSAYO CLÍNICO LEGADO
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_B',
							secciones: ['ANTECEDENTE_PROTOCOLOS_LEGADO_B'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_B',
							secciones: ['METODOLOGIA_PREVIAMENTE_AUTORIZADA_B'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_B',
							secciones: ['OTROS_DATO_OFICIO_AUTORIZADO_B'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						}
					]
				},
				formatos: {
					solicitud: [
						{
							formato: 'FF_COFEPRIS_01_B',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01_B',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_B',
							secciones: ['SOLICITANTE_ENMIENDA_B'],
							titulo: 'Comprobante de Pago y Carta de Solicitud'
						},
						{
							formato: 'FORMATO_ANTECEDENTES',
							secciones: ['ANTECEDENTES'],
							titulo: 'Antecedentes del Protocolo'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_B',
							secciones: ['INVESTIGACION_ENMIENDA_B'],
							titulo: 'Documentos de la Investigación'
						},
						// {
						//   formato: 'FORMATO_REQUISITOS_ENMIENDAS_B',
						//   secciones: ['CENTRO_INVESTIGACION_ENMIENDA_B'],
						//   titulo: 'Centro de Investigación'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_ENMIENDAS_B',
						//   secciones: ['CENTRO_URGENCIAS_ENMIENDA_B'],
						//   titulo: 'Centro de Atención a Urgencias'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_ENMIENDAS_B',
						//   secciones: ['INVESTIGADOR_PRINCIPAL_ENMIENDA_B'],
						//   titulo: 'Investigador Principal'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_ENMIENDAS_B',
						//   secciones: ['EQUIPO_INVESTIGACION_ENMIENDA_B'],
						//   titulo: 'Equipo de Investigación'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_ENMIENDAS_B',
						//  secciones: ['COMITE_ETICA_INVESTIGACION_ENMIENDA_B'],
						//   titulo: 'Comité de Ética en Investigación'
						// },
						{
							formato: 'FORMATO_MULTICENTRO_ENMIENDA',
							secciones: ['CENTRO_INVESTIGACION_ENMIENDA'],
							titulo: 'Centros de Investigación'
						},
						// {
						//   formato: 'FORMATO_REQUISITOS_ENMIENDAS_B',
						//   secciones: ['COMITE_INVESTIGACION_ENMIENDA_B'],
						//   titulo: 'Comité de Investigación'
						// },
						// {
						//   formato: 'FORMATO_REQUISITOS_ENMIENDAS_B',
						//   secciones: ['COMITE_BIOSEGURIDAD_ENMIENDA_B'],
						//   titulo: 'Comité de Bioseguridad'
						// },
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_B',
							secciones: ['OTROS_DOCUMENTOS_ENMIENDA_B'],
							titulo: 'Otros Documentos'
						}
					],
					evaluacion: [
						//FORMATOS COMPLEMENTARIOS PARA EL DICTAMINADOR EN UNA ENMIENDA DIGITAL
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_B',
							secciones: ['AUTORIZADO_B'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_B',
							secciones: ['PREVENCION_B'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_B',
							secciones: ['DESECHO_B'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						//FORMATOS DE RESOLUCIÓN DEL DICTAMINADOR, ESTADÍSTICO Y CMN
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['RESOLUCION'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_PRINCIPAL'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_ESTADISTICO'],
							actores: [EActor.DICTAMINADOR_ESTADISTICO]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_CMN'],
							actores: [EActor.COORDINADOR_COMITE_MOLECULAS_NUEVAS]
						}
					]
				}
			},
			{
				modalidad: 'C',
				modalidadConamer: 'X',
				subtitulo: 'Cambios al Centro de Investigación (cambio de domicilio y/o denominación).',
				titulo: '',
				descripcion: 'Permite solicitar la actualización del domicilio del Centro de Investigación y/o la denominación del establecimiento de un Centro de investigación Previamente autorizado.',
				tituloLista: 'Documentos susceptibles a actualizaciones a través de esta modalidad:',
				elementosLista: ['Formatos de Consentimientos y Asentimientos Informados'],
				version: 20231129,
				atencion: 90,
				atencionPrevencion: 45,
				tiempoRespuestaSolicitante: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '912XC',
				unidadOrganizativa: 'COFEPRIS_CAS_DEAPE_SEFM_EC',
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				omitirCamposDigital: [
					{
						formato: 'FORMATO_ANTECEDENTES',
						seccion: 'ANTECEDENTES',
						tagCampo: 'NUMERO_AUTORIZACION_INICIAL',
						etapa: 'solicitud'
					}
				],
				evaluaciones: [EHomoclaveContextResultadoEvaluacion.AUTORIZADO, EHomoclaveContextResultadoEvaluacion.PREVENIDO, EHomoclaveContextResultadoEvaluacion.DESECHADO],
				formatosAntecedentes: {
					solicitud: [
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						//FORMATOS SOLICITANTE LEGADO
						{
							formato: 'SOLICITANTE_LEGADO_ENMIENDA_C',
							secciones: ['INFORMACION_COMPLEMENTARIA_ENMIENDA_C'],
							titulo: 'INFORMACIÓN COMPLEMENTARIA'
						},
						{
							formato: 'SOLICITANTE_LEGADO_ENMIENDA_C',
							secciones: ['METODOLOGIA_ENMIENDA_C'],
							titulo: 'METOLOGÍA PREVIAMENTE AUTORIZADA'
						}
					],
					evaluacion: [
						//FORMATOS DICTMANADOR PARA UN ENSAYO CLÍNICO LEGADO
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_C',
							secciones: ['ANTECEDENTE_PROTOCOLOS_LEGADO_C'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_C',
							secciones: ['METODOLOGIA_PREVIAMENTE_AUTORIZADA_C'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_C',
							secciones: ['OTROS_DATO_OFICIO_AUTORIZADO_C'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						}
					]
				},
				formatos: {
					solicitud: [
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_C',
							secciones: ['SOLICITANTE_ENMIENDA_C'],
							titulo: 'Comprobante de Pago y Carta de Solicitud'
						},
						{
							formato: 'FORMATO_ANTECEDENTES',
							secciones: ['ANTECEDENTES'],
							titulo: 'Antecedentes del Protocolo'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_C',
							secciones: ['INVESTIGACION_ENMIENDA_C'],
							titulo: 'Documentos de la Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_C',
							secciones: ['CENTRO_INVESTIGACION_ENMIENDA_C'],
							titulo: 'Centro de Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_C',
							secciones: ['CENTRO_URGENCIAS_ENMIENDA_C'],
							titulo: 'Centro de Atención a Urgencias'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_C',
							secciones: ['COMITE_ETICA_INVESTIGACION_ENMIENDA_C'],
							titulo: 'Comité de Ética en Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_C',
							secciones: ['COMITE_INVESTIGACION_ENMIENDA_C'],
							titulo: 'Comité de Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_C',
							secciones: ['COMITE_BIOSEGURIDAD_ENMIENDA_C'],
							titulo: 'Comité de Bioseguridad'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_C',
							secciones: ['RESUMEN_ENMIENDA_C'],
							titulo: 'Información Complementaria'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_C',
							secciones: ['OTROS_DOCUMENTOS_ENMIENDA_C'],
							titulo: 'Otros Documentos'
						}
					],
					evaluacion: [
						//FORMATOS COMPLEMENTARIOS PARA EL DICTAMINADOR EN UNA ENMIENDA DIGITAL
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_C',
							secciones: ['AUTORIZADO_C'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_C',
							secciones: ['PREVENCION_C'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_C',
							secciones: ['DESECHO_C'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						//FORMATOS DE RESOLUCIÓN DEL DICTAMINADOR, ESTADÍSTICO Y CMN
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['RESOLUCION'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_PRINCIPAL'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_ESTADISTICO'],
							actores: [EActor.DICTAMINADOR_ESTADISTICO]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_CMN'],
							actores: [EActor.COORDINADOR_COMITE_MOLECULAS_NUEVAS]
						}
					]
				}
			},
			{
				modalidad: 'D',
				modalidadConamer: 'X',
				subtitulo: 'Cambio del Investigador Principal.',
				titulo: '',
				descripcion:
					'Permite solicitar la sustitución del Investigador Principal de un Centro de Investigación previamente autorizado; que será el nuevo responsable de conducir, coordinar y vigilar el desarrollo de la investigación clínica en el Centro de Investigación.',
				tituloLista: 'Documentos susceptibles a actualizaciones a través de esta modalidad:',
				elementosLista: ['Formatos de Consentimientos y Asentimientos Informados'],
				version: 20231129,
				atencion: 90,
				atencionPrevencion: 45,
				tiempoRespuestaSolicitante: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '912XD',
				unidadOrganizativa: 'COFEPRIS_CAS_DEAPE_SEFM_EC',
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				omitirCamposDigital: [
					{
						formato: 'FORMATO_ANTECEDENTES',
						seccion: 'ANTECEDENTES',
						tagCampo: 'NUMERO_AUTORIZACION_INICIAL',
						etapa: 'solicitud'
					}
				],
				evaluaciones: [EHomoclaveContextResultadoEvaluacion.AUTORIZADO, EHomoclaveContextResultadoEvaluacion.PREVENIDO, EHomoclaveContextResultadoEvaluacion.DESECHADO],
				formatosAntecedentes: {
					solicitud: [
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						//FORMATOS SOLICITANTE LEGADO
						{
							formato: 'SOLICITANTE_LEGADO_ENMIENDA_D',
							secciones: ['INFORMACION_COMPLEMENTARIA_ENMIENDA_D'],
							titulo: 'INFORMACIÓN COMPLEMENTARIA'
						},
						{
							formato: 'SOLICITANTE_LEGADO_ENMIENDA_D',
							secciones: ['METODOLOGIA_ENMIENDA_D'],
							titulo: 'METOLOGÍA PREVIAMENTE AUTORIZADA'
						}
					],
					evaluacion: [
						//FORMATOS DICTAMINADOR PARA UN ENSAYO CLÍNICO LEGADO
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_D',
							secciones: ['ANTECEDENTE_PROTOCOLOS_LEGADO_D'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_D',
							secciones: ['METODOLOGIA_PREVIAMENTE_AUTORIZADA_D'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_D',
							secciones: ['OTROS_DATO_OFICIO_AUTORIZADO_D'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						}
					]
				},
				formatos: {
					solicitud: [
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_D',
							secciones: ['SOLICITANTE_ENMIENDA_D'],
							titulo: 'Comprobante de Pago y Carta de Solicitud'
						},
						{
							formato: 'FORMATO_ANTECEDENTES',
							secciones: ['ANTECEDENTES'],
							titulo: 'Antecedentes del Protocolo'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_D',
							secciones: ['INVESTIGACION_ENMIENDA_D'],
							titulo: 'Documentos de la Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_D',
							secciones: ['CENTRO_INVESTIGACION_ENMIENDA_D'],
							titulo: 'Centro de Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_D',
							secciones: ['CENTRO_URGENCIAS_ENMIENDA_D'],
							titulo: 'Centro de Atención a Urgencias'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_D',
							secciones: ['INVESTIGADOR_PRINCIPAL_ENMIENDA_D'],
							titulo: 'Investigador Principal'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_D',
							secciones: ['EQUIPO_INVESTIGACION_ENMIENDA_D'],
							titulo: 'Equipo de Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_D',
							secciones: ['COMITE_ETICA_INVESTIGACION_ENMIENDA_D'],
							titulo: 'Comité de Ética en Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_D',
							secciones: ['COMITE_INVESTIGACION_ENMIENDA_D'],
							titulo: 'Comité de Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_D',
							secciones: ['COMITE_BIOSEGURIDAD_ENMIENDA_D'],
							titulo: 'Comité de Bioseguridad'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_D',
							secciones: ['RESUMEN_ENMIENDA_D'],
							titulo: 'Información Complementaria'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_D',
							secciones: ['OTROS_DOCUMENTOS_ENMIENDA_D'],
							titulo: 'Otros Documentos'
						}
					],
					evaluacion: [
						//FORMATOS COMPLEMENTARIOS PARA EL DICTAMINADOR EN UNA ENMIENDA DIGITAL
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_D',
							secciones: ['AUTORIZADO_D'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_D',
							secciones: ['PREVENCION_D'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_D',
							secciones: ['DESECHO_D'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						//FORMATOS DE RESOLUCIÓN DEL DICTAMINADOR, ESTADÍSTICO Y CMN
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['RESOLUCION'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_PRINCIPAL'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_ESTADISTICO'],
							actores: [EActor.DICTAMINADOR_ESTADISTICO]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_CMN'],
							actores: [EActor.COORDINADOR_COMITE_MOLECULAS_NUEVAS]
						}
					]
				}
			},
			{
				modalidad: 'E',
				modalidadConamer: 'X',
				subtitulo: 'Cambio o integración al Equipo de Investigación.',
				titulo: '',
				descripcion: 'Permite solicitar el cambio y/o actualización de los integrantes de equipo de Investigación de un Centro Específico que ya cuenta con una autorización previa.',
				atencion: 90,
				atencionPrevencion: 45,
				tiempoRespuestaSolicitante: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				version: 20231129,
				infijoIdTramite: '912XE',
				unidadOrganizativa: 'COFEPRIS_CAS_DEAPE_SEFM_EC',
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				omitirCamposDigital: [
					{
						formato: 'FORMATO_ANTECEDENTES',
						seccion: 'ANTECEDENTES',
						tagCampo: 'NUMERO_AUTORIZACION_INICIAL',
						etapa: 'solicitud'
					}
				],
				evaluaciones: [EHomoclaveContextResultadoEvaluacion.AUTORIZADO, EHomoclaveContextResultadoEvaluacion.PREVENIDO, EHomoclaveContextResultadoEvaluacion.DESECHADO],
				formatosAntecedentes: {
					solicitud: [
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						//FORMATOS SOLICITANTE LEGADO
						{
							formato: 'SOLICITANTE_LEGADO_ENMIENDA_E',
							secciones: ['INFORMACION_COMPLEMENTARIA_ENMIENDA_E'],
							titulo: 'INFORMACIÓN COMPLEMENTARIA'
						},
						{
							formato: 'SOLICITANTE_LEGADO_ENMIENDA_E',
							secciones: ['METODOLOGIA_ENMIENDA_E'],
							titulo: 'METOLOGÍA PREVIAMENTE AUTORIZADA'
						}
					],
					evaluacion: [
						//FORMATOS DICTMANADOR PARA UN ENSAYO CLÍNICO LEGADO
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_E',
							secciones: ['ANTECEDENTE_PROTOCOLOS_LEGADO_E'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_E',
							secciones: ['METODOLOGIA_PREVIAMENTE_AUTORIZADA_E'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_E',
							secciones: ['OTROS_DATO_OFICIO_AUTORIZADO_E'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						}
					]
				},
				formatos: {
					solicitud: [
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_E',
							secciones: ['SOLICITANTE_ENMIENDA_E'],
							titulo: 'Comprobante de Pago y Carta de Solicitud'
						},
						{
							formato: 'FORMATO_ANTECEDENTES',
							secciones: ['ANTECEDENTES'],
							titulo: 'Antecedentes del Protocolo'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_E',
							secciones: ['INVESTIGACION_ENMIENDA_E'],
							titulo: 'Documentos de la Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_E',
							secciones: ['CENTRO_INVESTIGACION_ENMIENDA_E'],
							titulo: 'Centro de Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_E',
							secciones: ['EQUIPO_INVESTIGACION_ENMIENDA_E'],
							titulo: 'Equipo de Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_E',
							secciones: ['RESUMEN_ENMIENDA_E'],
							titulo: 'Enmienda o Modificación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_E',
							secciones: ['OTROS_DOCUMENTOS_ENMIENDA_E'],
							titulo: 'Otros Documentos'
						}
					],
					evaluacion: [
						//FORMATOS COMPLEMENTARIOS PARA EL DICTAMINADOR EN UNA ENMIENDA DIGITAL
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_E',
							secciones: ['AUTORIZADO_E'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_E',
							secciones: ['PREVENCION_E'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_E',
							secciones: ['DESECHO_E'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						//FORMATOS DE RESOLUCIÓN DEL DICTAMINADOR, ESTADÍSTICO Y CMN
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['RESOLUCION'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_PRINCIPAL'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_ESTADISTICO'],
							actores: [EActor.DICTAMINADOR_ESTADISTICO]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_CMN'],
							actores: [EActor.COORDINADOR_COMITE_MOLECULAS_NUEVAS]
						}
					]
				}
			},
			{
				modalidad: 'F',
				modalidadConamer: 'X',
				subtitulo: 'Cambios al Centro de Urgencias (domicilio y/o denominación).',
				titulo: '',
				descripcion: 'Permite solicitar la actualización del domicilio y/o la denominación del Centro de Atención de Urgencias Médicas de un Centro de Investigación Previamente autorizado.',
				tituloLista: 'Documentos susceptibles a actualizaciones a través de esta modalidad:',
				elementosLista: ['Formatos de Consentimientos y Asentimientos Informados'],
				atencion: 90,
				atencionPrevencion: 45,
				tiempoRespuestaSolicitante: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				version: 20231129,
				infijoIdTramite: '912XF',
				unidadOrganizativa: 'COFEPRIS_CAS_DEAPE_SEFM_EC',
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				omitirCamposDigital: [
					{
						formato: 'FORMATO_ANTECEDENTES',
						seccion: 'ANTECEDENTES',
						tagCampo: 'NUMERO_AUTORIZACION_INICIAL',
						etapa: 'solicitud'
					}
				],
				evaluaciones: [EHomoclaveContextResultadoEvaluacion.AUTORIZADO, EHomoclaveContextResultadoEvaluacion.PREVENIDO, EHomoclaveContextResultadoEvaluacion.DESECHADO],
				formatosAntecedentes: {
					solicitud: [
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						//FORMATOS SOLICITANTE LEGADO
						{
							formato: 'SOLICITANTE_LEGADO_ENMIENDA_F',
							secciones: ['INFORMACION_COMPLEMENTARIA_ENMIENDA_F'],
							titulo: 'INFORMACIÓN COMPLEMENTARIA'
						},
						{
							formato: 'SOLICITANTE_LEGADO_ENMIENDA_F',
							secciones: ['METODOLOGIA_ENMIENDA_F'],
							titulo: 'METOLOGÍA PREVIAMENTE AUTORIZADA'
						}
					],
					evaluacion: [
						//FORMATOS DICTMANADOR PARA UN ENSAYO CLÍNICO LEGADO
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_F',
							secciones: ['ANTECEDENTE_PROTOCOLOS_LEGADO_F'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_F',
							secciones: ['METODOLOGIA_PREVIAMENTE_AUTORIZADA_F'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_F',
							secciones: ['OTROS_DATO_OFICIO_AUTORIZADO_F'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						}
					]
				},
				formatos: {
					solicitud: [
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_F',
							secciones: ['SOLICITANTE_ENMIENDA_F'],
							titulo: 'Comprobante de Pago y Carta de Solicitud'
						},
						{
							formato: 'FORMATO_ANTECEDENTES',
							secciones: ['ANTECEDENTES'],
							titulo: 'Antecedentes del Protocolo'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_F',
							secciones: ['INVESTIGACION_ENMIENDA_F'],
							titulo: 'Documentos de la Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_F',
							secciones: ['CENTRO_INVESTIGACION_ENMIENDA_F'],
							titulo: 'Centro de Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_F',
							secciones: ['CENTRO_URGENCIAS_ENMIENDA_F'],
							titulo: 'Centro de Atención a Urgencias'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_F',
							secciones: ['COMITE_ETICA_INVESTIGACION_ENMIENDA_F'],
							titulo: 'Comité de Ética en Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_F',
							secciones: ['COMITE_INVESTIGACION_ENMIENDA_F'],
							titulo: 'Comité de Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_F',
							secciones: ['COMITE_BIOSEGURIDAD_ENMIENDA_F'],
							titulo: 'Comité de Bioseguridad'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_F',
							secciones: ['RESUMEN_ENMIENDA_F'],
							titulo: 'Información Complementaria'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_F',
							secciones: ['OTROS_DOCUMENTOS_ENMIENDA_F'],
							titulo: 'Otros Documentos'
						}
					],
					evaluacion: [
						//FORMATOS COMPLEMENTARIOS PARA EL DICTAMINADOR EN UNA ENMIENDA DIGITAL
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_F',
							secciones: ['AUTORIZADO_F'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_F',
							secciones: ['PREVENCION_F'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_F',
							secciones: ['DESECHO_F'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						//FORMATOS DE RESOLUCIÓN DEL DICTAMINADOR, ESTADÍSTICO Y CMN
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['RESOLUCION'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_PRINCIPAL'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_ESTADISTICO'],
							actores: [EActor.DICTAMINADOR_ESTADISTICO]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_CMN'],
							actores: [EActor.COORDINADOR_COMITE_MOLECULAS_NUEVAS]
						}
					]
				}
			},
			{
				modalidad: 'G',
				modalidadConamer: 'X',
				subtitulo: 'Cambios a los Comités Evaluadores.',
				titulo: '',
				descripcion:
					'Permite solicitar la actualización de la información del Comité de Ética en Investigación, Comité de Investigación y/o Comité de Bioseguridad de un Centro de Investigación Previamente autorizado.',
				tituloLista: 'Documentos susceptibles a actualizaciones a través de esta modalidad:',
				elementosLista: ['Formatos de Consentimientos y Asentimientos Informados'],
				atencion: 90,
				atencionPrevencion: 45,
				tiempoRespuestaSolicitante: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				version: 20231129,
				infijoIdTramite: '912XG',
				unidadOrganizativa: 'COFEPRIS_CAS_DEAPE_SEFM_EC',
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				omitirCamposDigital: [
					{
						formato: 'FORMATO_ANTECEDENTES',
						seccion: 'ANTECEDENTES',
						tagCampo: 'NUMERO_AUTORIZACION_INICIAL',
						etapa: 'solicitud'
					}
				],
				evaluaciones: [EHomoclaveContextResultadoEvaluacion.AUTORIZADO, EHomoclaveContextResultadoEvaluacion.PREVENIDO, EHomoclaveContextResultadoEvaluacion.DESECHADO],
				formatosAntecedentes: {
					solicitud: [
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						//FORMATOS SOLICITANTE LEGADO
						{
							formato: 'SOLICITANTE_LEGADO_ENMIENDA_G',
							secciones: ['INFORMACION_COMPLEMENTARIA_ENMIENDA_G'],
							titulo: 'INFORMACIÓN COMPLEMENTARIA'
						},
						{
							formato: 'SOLICITANTE_LEGADO_ENMIENDA_G',
							secciones: ['METODOLOGIA_ENMIENDA_G'],
							titulo: 'METOLOGÍA PREVIAMENTE AUTORIZADA'
						}
					],
					evaluacion: [
						//FORMATOS DICTMANADOR PARA UN ENSAYO CLÍNICO LEGADO
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_G',
							secciones: ['ANTECEDENTE_PROTOCOLOS_LEGADO_G'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_G',
							secciones: ['METODOLOGIA_PREVIAMENTE_AUTORIZADA_G'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_G',
							secciones: ['OTROS_DATO_OFICIO_AUTORIZADO_G'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						}
					]
				},
				formatos: {
					solicitud: [
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_G',
							secciones: ['SOLICITANTE_ENMIENDA_G'],
							titulo: 'Comprobante de Pago y Carta de Solicitud'
						},
						{
							formato: 'FORMATO_ANTECEDENTES',
							secciones: ['ANTECEDENTES'],
							titulo: 'Antecedentes del Protocolo'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_G',
							secciones: ['INVESTIGACION_ENMIENDA_G'],
							titulo: 'Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_G',
							secciones: ['CENTRO_INVESTIGACION_ENMIENDA_G'],
							titulo: 'Centro de Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_G',
							secciones: ['COMITE_ETICA_INVESTIGACION_ENMIENDA_G'],
							titulo: 'Comité de Ética en Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_G',
							secciones: ['COMITE_INVESTIGACION_ENMIENDA_G'],
							titulo: 'Comité de Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_G',
							secciones: ['COMITE_BIOSEGURIDAD_ENMIENDA_G'],
							titulo: 'Comité de Bioseguridad'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_G',
							secciones: ['RESUMEN_ENMIENDA_G'],
							titulo: 'Información Complementaria'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_G',
							secciones: ['OTROS_DOCUMENTOS_ENMIENDA_G'],
							titulo: 'Otros Documentos'
						}
					],
					evaluacion: [
						//FORMATOS COMPLEMENTARIOS PARA EL DICTAMINADOR EN UNA ENMIENDA DIGITAL
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_G',
							secciones: ['AUTORIZADO_G'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_G',
							secciones: ['PREVENCION_G'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_G',
							secciones: ['DESECHO_G'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						//FORMATOS DE RESOLUCIÓN DEL DICTAMINADOR, ESTADÍSTICO Y CMN
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['RESOLUCION'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_PRINCIPAL'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_ESTADISTICO'],
							actores: [EActor.DICTAMINADOR_ESTADISTICO]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_CMN'],
							actores: [EActor.COORDINADOR_COMITE_MOLECULAS_NUEVAS]
						}
					]
				}
			},
			{
				modalidad: 'H',
				modalidadConamer: 'X',
				subtitulo: 'Enmienda de seguridad.',
				titulo: '',
				descripcion:
					'Permite solicitar la autorización de las actualizaciones a los documentos de investigación previamente autorizados cuando se ha identificado algún riesgo que amenaza la vida de los sujetos, esta enmienda podrá aplicarse inmediatamente a juicio del investigador en los casos en que se advierta algún riesgo o daño a la salud e integridad o en condiciones que amenazan la vida del sujeto en investigación. La solicitud de esta enmienda, deberá ser presentada ante COFEPRIS en un plazo no mayor a 15 días hábiles, después de la aplicación de la misma.',
				tituloLista: 'Documentos susceptibles a actualizaciones a través de esta modalidad:',
				elementosLista: ['Protocolo de Investigación', 'Manual del investigador', 'Formatos de Consentimientos y Asentimientos Informados'],
				atencion: 90,
				atencionPrevencion: 45,
				tiempoRespuestaSolicitante: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				version: 20231129,
				infijoIdTramite: '912XH',
				unidadOrganizativa: 'COFEPRIS_CAS_DEAPE_SEFM_EC',
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				omitirCamposDigital: [
					{
						formato: 'FORMATO_ANTECEDENTES',
						seccion: 'ANTECEDENTES',
						tagCampo: 'NUMERO_AUTORIZACION_INICIAL',
						etapa: 'solicitud'
					}
				],
				evaluaciones: [EHomoclaveContextResultadoEvaluacion.AUTORIZADO, EHomoclaveContextResultadoEvaluacion.PREVENIDO, EHomoclaveContextResultadoEvaluacion.DESECHADO],
				formatosAntecedentes: {
					solicitud: [
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						//FORMATOS SOLICITANTE LEGADO
						{
							formato: 'SOLICITANTE_LEGADO_ENMIENDA_H',
							secciones: ['INFORMACION_COMPLEMENTARIA_ENMIENDA_H'],
							titulo: 'INFORMACIÓN COMPLEMENTARIA'
						},
						{
							formato: 'SOLICITANTE_LEGADO_ENMIENDA_H',
							secciones: ['METODOLOGIA_ENMIENDA_H'],
							titulo: 'METOLOGÍA PREVIAMENTE AUTORIZADA'
						}
					],
					evaluacion: [
						//FORMATOS DICTMANADOR PARA UN ENSAYO CLÍNICO LEGADO
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_H',
							secciones: ['ANTECEDENTE_PROTOCOLOS_LEGADO_H'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_H',
							secciones: ['METODOLOGIA_PREVIAMENTE_AUTORIZADA_H'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						//{
						//formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_H',
						//secciones: ['INFORMACION_COMPLEMENTARIA_H'],
						//actores: [EActor.DICTAMINADOR_RESPONSABLE]
						//},
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_H',
							secciones: ['OTROS_DATO_OFICIO_AUTORIZADO_H'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						}
					]
				},
				formatos: {
					solicitud: [
						{
							//Se agrega FF01 para poblar el oficio.
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_H',
							secciones: ['SOLICITANTE_ENMIENDA_H'],
							titulo: 'Comprobante de Pago y Carta de Solicitud'
						},
						{
							formato: 'FORMATO_ANTECEDENTES',
							secciones: ['ANTECEDENTES'],
							titulo: 'Antecedentes del Protocolo'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_H',
							secciones: ['INVESTIGACION_ENMIENDA_H'],
							titulo: 'Documentos de la Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_H',
							secciones: ['COMITE_ETICA_INVESTIGACION_ENMIENDA_H'],
							titulo: 'Comité de Ética en Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_H',
							secciones: ['COMITE_INVESTIGACION_ENMIENDA_H'],
							titulo: 'Comité de Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_H',
							secciones: ['COMITE_BIOSEGURIDAD_ENMIENDA_H'],
							titulo: 'Comité de Bioseguridad'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_H',
							secciones: ['RESUMEN_ENMIENDA_H'],
							titulo: 'Resumen de la Enmienda'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_H',
							secciones: ['OTROS_DOCUMENTOS_ENMIENDA_H'],
							titulo: 'Documentación Adicional a Considerar'
						}
					],
					evaluacion: [
						//FORMATOS COMPLEMENTARIOS PARA EL DICTAMINADOR EN UNA ENMIENDA DIGITAL
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_H',
							secciones: ['AUTORIZADO_H'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_H',
							secciones: ['PREVENCION_H'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_H',
							secciones: ['DESECHO_H'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						//FORMATOS DE RESOLUCIÓN DEL DICTAMINADOR, ESTADÍSTICO Y CMN
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['RESOLUCION'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_PRINCIPAL'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_ESTADISTICO'],
							actores: [EActor.DICTAMINADOR_ESTADISTICO]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_CMN'],
							actores: [EActor.COORDINADOR_COMITE_MOLECULAS_NUEVAS]
						}
					]
				}
			},
			{
				modalidad: 'I',
				modalidadConamer: 'X',
				subtitulo: 'Cambios al Titular (cambio de domicilio y/o denominación).',
				titulo: '',
				descripcion:
					'Permite solicitar la actualización del domicilio y/o denominación del establecimiento del titular de responsable de la Conducción del proyecto de investigación Previamente autorizado.',
				atencion: 90,
				atencionPrevencion: 45,
				tiempoRespuestaSolicitante: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				version: 20231129,
				infijoIdTramite: '912XI',
				unidadOrganizativa: 'COFEPRIS_CAS_DEAPE_SEFM_EC',
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				omitirCamposDigital: [
					{
						formato: 'FORMATO_ANTECEDENTES',
						seccion: 'ANTECEDENTES',
						tagCampo: 'NUMERO_AUTORIZACION_INICIAL',
						etapa: 'solicitud'
					}
				],
				evaluaciones: [EHomoclaveContextResultadoEvaluacion.AUTORIZADO, EHomoclaveContextResultadoEvaluacion.PREVENIDO, EHomoclaveContextResultadoEvaluacion.DESECHADO],
				formatosAntecedentes: {
					solicitud: [
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						//FORMATOS SOLICITANTE LEGADO
						{
							formato: 'SOLICITANTE_LEGADO_ENMIENDA_I',
							secciones: ['INFORMACION_COMPLEMENTARIA_ENMIENDA_I'],
							titulo: 'INFORMACIÓN COMPLEMENTARIA'
						},
						{
							formato: 'SOLICITANTE_LEGADO_ENMIENDA_I',
							secciones: ['METODOLOGIA_ENMIENDA_I'],
							titulo: 'METOLOGÍA PREVIAMENTE AUTORIZADA'
						}
					],
					evaluacion: [
						//FORMATOS DICTMANADOR PARA UN ENSAYO CLÍNICO LEGADO
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_I',
							secciones: ['ANTECEDENTE_PROTOCOLOS_LEGADO_I'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_I',
							secciones: ['METODOLOGIA_PREVIAMENTE_AUTORIZADA_I'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_I',
							secciones: ['OTROS_DATO_OFICIO_AUTORIZADO_I'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						}
					]
				},
				formatos: {
					solicitud: [
						{
							//Se agrega FF01 para poblar el oficio.
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_I',
							secciones: ['SOLICITANTE_ENMIENDA_I'],
							titulo: 'Comprobante de Pago y Carta de Solicitud'
						},
						{
							formato: 'FORMATO_ANTECEDENTES',
							secciones: ['ANTECEDENTES'],
							titulo: 'Antecedentes del Protocolo'
						},
						{
							formato: 'DATOS_NUEVO_TITULAR',
							secciones: ['2'],
							titulo: 'Datos del Nuevo Titular'
						},
						{
							formato: 'DATOS_NUEVO_TITULAR',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Nuevo Titular'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_I',
							secciones: ['CAMBIO_TITULAR_ENMIENDA_I'],
							titulo: 'Cambio de Titular'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_I',
							secciones: ['PATROCINADOR_ENMIENDA_I'],
							titulo: 'Patrocinador'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_I',
							secciones: ['INVESTIGACION_ENMIENDA_I'],
							titulo: 'Documentos de la Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_I',
							secciones: ['PRODUCTO_INVESTIGACION_ENMIENDA_I'],
							titulo: 'Producto de Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_I',
							secciones: ['COMITE_ETICA_INVESTIGACION_ENMIENDA_I'],
							titulo: 'Comité de Ética en Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_I',
							secciones: ['COMITE_INVESTIGACION_ENMIENDA_I'],
							titulo: 'Comité de Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_I',
							secciones: ['COMITE_BIOSEGURIDAD_ENMIENDA_I'],
							titulo: 'Comité de Bioseguridad'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_I',
							secciones: ['RESUMEN_ENMIENDA_I'],
							titulo: 'Información Complementaria'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_I',
							secciones: ['OTROS_DOCUMENTOS_ENMIENDA_I'],
							titulo: 'Otros Documentos'
						}
					],
					evaluacion: [
						//FORMATOS COMPLEMENTARIOS PARA EL DICTAMINADOR EN UNA ENMIENDA DIGITAL
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_I',
							secciones: ['AUTORIZADO_I'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_I',
							secciones: ['PREVENCION_I'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_I',
							secciones: ['DESECHO_I'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						//FORMATOS DE RESOLUCIÓN DEL DICTAMINADOR, ESTADÍSTICO Y CMN
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['RESOLUCION'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_PRINCIPAL'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_ESTADISTICO'],
							actores: [EActor.DICTAMINADOR_ESTADISTICO]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_CMN'],
							actores: [EActor.COORDINADOR_COMITE_MOLECULAS_NUEVAS]
						}
					]
				}
			},
			{
				modalidad: 'J',
				modalidadConamer: 'X',
				subtitulo: 'Cambios de patrocinador (domicilio y/o razón social).',
				titulo: '',
				descripcion:
					'Permite solicitar la actualización del domicilio y/o denominación de patrocinador que asume la responsabilidad de iniciar, gestionar y financiar un protocolo de investigación previamente autorizado.',
				atencion: 90,
				atencionPrevencion: 45,
				tiempoRespuestaSolicitante: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				version: 20231129,
				infijoIdTramite: '912XJ',
				unidadOrganizativa: 'COFEPRIS_CAS_DEAPE_SEFM_EC',
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				omitirCamposDigital: [
					{
						formato: 'FORMATO_ANTECEDENTES',
						seccion: 'ANTECEDENTES',
						tagCampo: 'NUMERO_AUTORIZACION_INICIAL',
						etapa: 'solicitud'
					}
				],
				evaluaciones: [EHomoclaveContextResultadoEvaluacion.AUTORIZADO, EHomoclaveContextResultadoEvaluacion.PREVENIDO, EHomoclaveContextResultadoEvaluacion.DESECHADO],
				formatosAntecedentes: {
					solicitud: [
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						//FORMATOS SOLICITANTE LEGADO
						{
							formato: 'SOLICITANTE_LEGADO_ENMIENDA_J',
							secciones: ['INFORMACION_COMPLEMENTARIA_ENMIENDA_J'],
							titulo: 'INFORMACIÓN COMPLEMENTARIA'
						},
						{
							formato: 'SOLICITANTE_LEGADO_ENMIENDA_J',
							secciones: ['METODOLOGIA_ENMIENDA_J'],
							titulo: 'METOLOGÍA PREVIAMENTE AUTORIZADA'
						}
					],
					evaluacion: [
						//FORMATOS DICTMANADOR PARA UN ENSAYO CLÍNICO LEGADO
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_J',
							secciones: ['ANTECEDENTE_PROTOCOLOS_LEGADO_J'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_J',
							secciones: ['METODOLOGIA_PREVIAMENTE_AUTORIZADA_J'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_J',
							secciones: ['OTROS_DATO_OFICIO_AUTORIZADO_J'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						}
					]
				},
				formatos: {
					solicitud: [
						{
							//Se agrega FF01 para poblar el oficio.
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_J',
							secciones: ['SOLICITANTE_ENMIENDA_J'],
							titulo: 'Comprobante de Pago y Carta de Solicitud'
						},
						{
							formato: 'FORMATO_ANTECEDENTES',
							secciones: ['ANTECEDENTES'],
							titulo: 'Antecedentes del Protocolo'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_J',
							secciones: ['PATROCINADOR_ENMIENDA_J'],
							titulo: 'Patrocinador'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_J',
							secciones: ['INVESTIGACION_ENMIENDA_J'],
							titulo: 'Documentos de la Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_J',
							secciones: ['PRODUCTO_INVESTIGACION_ENMIENDA_J'],
							titulo: 'Producto de Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_J',
							secciones: ['CENTRO_INVESTIGACION_ENMIENDA_J'],
							titulo: 'Centro de Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_J',
							secciones: ['COMITE_ETICA_INVESTIGACION_ENMIENDA_J'],
							titulo: 'Comité de Ética en Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_J',
							secciones: ['COMITE_INVESTIGACION_ENMIENDA_J'],
							titulo: 'Comité de Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_J',
							secciones: ['COMITE_BIOSEGURIDAD_ENMIENDA_J'],
							titulo: 'Comité de Bioseguridad'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_J',
							secciones: ['IMPORTADOR_ENMIENDA_J'],
							titulo: 'Importador'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_J',
							secciones: ['RESUMEN_ENMIENDA_J'],
							titulo: 'Información Complementaria'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_J',
							secciones: ['OTROS_DOCUMENTOS_ENMIENDA_J'],
							titulo: 'Otros Documentos'
						}
					],
					evaluacion: [
						//FORMATOS COMPLEMENTARIOS PARA EL DICTAMINADOR EN UNA ENMIENDA DIGITAL
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_J',
							secciones: ['AUTORIZADO_J'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_J',
							secciones: ['PREVENCION_J'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_J',
							secciones: ['DESECHO_J'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						//FORMATOS DE RESOLUCIÓN DEL DICTAMINADOR, ESTADÍSTICO Y CMN
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['RESOLUCION'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_PRINCIPAL'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_ESTADISTICO'],
							actores: [EActor.DICTAMINADOR_ESTADISTICO]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_CMN'],
							actores: [EActor.COORDINADOR_COMITE_MOLECULAS_NUEVAS]
						}
					]
				}
			},
			{
				modalidad: 'K',
				modalidadConamer: 'X',
				subtitulo: 'Cambio o adición de importador.',
				titulo: '',
				descripcion: 'Permite solicitar el cambio o la adición del importador de un protocolo previamente autorizado.',
				atencion: 90,
				atencionPrevencion: 45,
				tiempoRespuestaSolicitante: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				version: 20231129,
				infijoIdTramite: '912XK',
				unidadOrganizativa: 'COFEPRIS_CAS_DEAPE_SEFM_EC',
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				omitirCamposDigital: [
					{
						formato: 'FORMATO_ANTECEDENTES',
						seccion: 'ANTECEDENTES',
						tagCampo: 'NUMERO_AUTORIZACION_INICIAL',
						etapa: 'solicitud'
					}
				],
				evaluaciones: [EHomoclaveContextResultadoEvaluacion.AUTORIZADO, EHomoclaveContextResultadoEvaluacion.PREVENIDO, EHomoclaveContextResultadoEvaluacion.DESECHADO],
				formatosAntecedentes: {
					solicitud: [
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						//FORMATOS SOLICITANTE LEGADO
						{
							formato: 'SOLICITANTE_LEGADO_ENMIENDA_K',
							secciones: ['INFORMACION_COMPLEMENTARIA_ENMIENDA_K'],
							titulo: 'INFORMACIÓN COMPLEMENTARIA'
						},
						{
							formato: 'SOLICITANTE_LEGADO_ENMIENDA_K',
							secciones: ['METODOLOGIA_ENMIENDA_K'],
							titulo: 'METOLOGÍA PREVIAMENTE AUTORIZADA'
						}
					],
					evaluacion: [
						//FORMATOS DICTMANADOR PARA UN ENSAYO CLÍNICO LEGADO
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_K',
							secciones: ['ANTECEDENTE_PROTOCOLOS_LEGADO_K'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_K',
							secciones: ['METODOLOGIA_PREVIAMENTE_AUTORIZADA_K'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_K',
							secciones: ['OTROS_DATO_OFICIO_AUTORIZADO_K'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						}
					]
				},
				formatos: {
					solicitud: [
						{
							//Se agrega FF01 para poblar el oficio.
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_K',
							secciones: ['SOLICITANTE_ENMIENDA_K'],
							titulo: 'Comprobante de Pago y Carta de Solicitud'
						},
						{
							formato: 'FORMATO_ANTECEDENTES',
							secciones: ['ANTECEDENTES'],
							titulo: 'Antecedentes del Protocolo'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_K',
							secciones: ['PATROCINADOR_ENMIENDA_K'],
							titulo: 'Patrocinador'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_K',
							secciones: ['INVESTIGACION_ENMIENDA_K'],
							titulo: 'Documentos de la Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_K',
							secciones: ['IMPORTADOR_ENMIENDA_K'],
							titulo: 'Importador'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_K',
							secciones: ['RESUMEN_ENMIENDA_K'],
							titulo: 'Información Complementaria'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_K',
							secciones: ['OTROS_DOCUMENTOS_ENMIENDA_K'],
							titulo: 'Otro Documentos'
						}
					],
					evaluacion: [
						//FORMATOS COMPLEMENTARIOS PARA EL DICTAMINADOR EN UNA ENMIENDA DIGITAL
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_K',
							secciones: ['AUTORIZADO_K'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_K',
							secciones: ['PREVENCION_K'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_K',
							secciones: ['DESECHO_K'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						//FORMATOS DE RESOLUCIÓN DEL DICTAMINADOR, ESTADÍSTICO Y CMN
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['RESOLUCION'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_PRINCIPAL'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_ESTADISTICO'],
							actores: [EActor.DICTAMINADOR_ESTADISTICO]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_CMN'],
							actores: [EActor.COORDINADOR_COMITE_MOLECULAS_NUEVAS]
						}
					]
				}
			},
			{
				modalidad: 'L',
				modalidadConamer: 'X',
				subtitulo: 'Otras modificaciones.',
				titulo: '',
				descripcion: '',
				atencion: 90,
				atencionPrevencion: 45,
				tiempoRespuestaSolicitante: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				version: 20231129,
				infijoIdTramite: '912XL',
				unidadOrganizativa: 'COFEPRIS_CAS_DEAPE_SEFM_EC',
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				omitirCamposDigital: [
					{
						formato: 'FORMATO_ANTECEDENTES',
						seccion: 'ANTECEDENTES',
						tagCampo: 'NUMERO_AUTORIZACION_INICIAL',
						etapa: 'solicitud'
					}
				],
				evaluaciones: [EHomoclaveContextResultadoEvaluacion.AUTORIZADO, EHomoclaveContextResultadoEvaluacion.PREVENIDO, EHomoclaveContextResultadoEvaluacion.DESECHADO],
				formatosAntecedentes: {
					solicitud: [
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						//FORMATOS SOLICITANTE LEGADO
						{
							formato: 'SOLICITANTE_LEGADO_ENMIENDA_L',
							secciones: ['INFORMACION_COMPLEMENTARIA_ENMIENDA_L'],
							titulo: 'INFORMACIÓN COMPLEMENTARIA'
						},
						{
							formato: 'SOLICITANTE_LEGADO_ENMIENDA_L',
							secciones: ['METODOLOGIA_ENMIENDA_L'],
							titulo: 'METOLOGÍA PREVIAMENTE AUTORIZADA'
						}
					],
					evaluacion: [
						//FORMATOS DICTMANADOR PARA UN ENSAYO CLÍNICO LEGADO
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_L',
							secciones: ['ANTECEDENTE_PROTOCOLOS_LEGADO_L'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_L',
							secciones: ['METODOLOGIA_PREVIAMENTE_AUTORIZADA_L'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_LEGADO_ENMIENDA_L',
							secciones: ['OTROS_DATO_OFICIO_AUTORIZADO_L'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						}
					]
				},
				formatos: {
					solicitud: [
						{
							//Se agrega FF01 para poblar el oficio.
							formato: 'FF_COFEPRIS_01',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_L',
							secciones: ['SOLICITANTE_ENMIENDA_L'],
							titulo: 'Comprobante de Pago y Carta de Solicitud'
						},
						{
							formato: 'FORMATO_ANTECEDENTES_ENMIENDA_L',
							secciones: ['ANTECEDENTES_ENMIENDA_L'],
							titulo: 'Antecedentes del Protocolo'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_L',
							secciones: ['INVESTIGACION_ENMIENDA_L'],
							titulo: 'Documentos de la Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_L',
							secciones: ['CENTRO_INVESTIGACION_ENMIENDA_L'],
							titulo: 'Centro de Investigación'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_L',
							secciones: ['RESUMEN_ENMIENDA_L'],
							titulo: 'Información Complementaria'
						},
						{
							formato: 'FORMATO_REQUISITOS_ENMIENDAS_L',
							secciones: ['OTROS_DOCUMENTOS_ENMIENDA_L'],
							titulo: 'Otros Documentos'
						}
					],
					evaluacion: [
						//FORMATOS COMPLEMENTARIOS PARA EL DICTAMINADOR EN UNA ENMIENDA DIGITAL
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_L',
							secciones: ['AUTORIZADO_L'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_L',
							secciones: ['PREVENCION_L'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_DICTAMINADOR_ENMIENDA_L',
							secciones: ['DESECHO_L'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						//FORMATOS DE RESOLUCIÓN DEL DICTAMINADOR, ESTADÍSTICO Y CMN
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['RESOLUCION'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_PRINCIPAL'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_ESTADISTICO'],
							actores: [EActor.DICTAMINADOR_ESTADISTICO]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_CMN'],
							actores: [EActor.COORDINADOR_COMITE_MOLECULAS_NUEVAS]
						}
					]
				}
			}
		]
	},
	{
		homoclave: EHomoclaves['COFEPRIS-10-001'],
		titulo: 'Solicitud de cesión de derechos del Registro Sanitario de medicamentos',
		modalidades: [
			{
				modalidad: 'AA',
				modalidadConamer: 'X',
				subtitulo: 'Medicamentos farmoquímicos.',
				titulo: '',
				descripcion: 'Permite tramitar la autorización del cambio de titular del Registro Sanitario de medicamentos farmoquímicos.',
				version: 1,
				porcentajePago: 0.5,
				atencion: 20,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 20,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 30,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '1001AA',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_MCR,
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO, EHomoclaveBanderasModificaciones.SELECCION_AI],
					medicamento: {
						tipoMedicamentos: [EMedicamentoTipo.FARMOQUIMICO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.CESION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_CON_REGISTRO
			},
			{
				modalidad: 'AB',
				modalidadConamer: 'X',
				subtitulo: 'Productos  biológicos, biotecnológicos y vacunas.',
				titulo: '',
				descripcion: 'Permite tramitar la autorización del cambio de titular del Registro Sanitario de medicamentos biológicos, biotecnológicos y vacunas.',
				version: 1,
				porcentajePago: 0.5,
				atencion: 20,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 20,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 30,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '1001AB',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_BBV,
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO, EHomoclaveBanderasModificaciones.SELECCION_AI],
					medicamento: {
						tipoMedicamentos: [EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.HEMODERIVADO, EMedicamentoTipo.VACUNA]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.CESION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_BBV
			},
			{
				modalidad: 'AC',
				modalidadConamer: 'X',
				subtitulo: 'Medicamentos herbolarios, homeopáticos y vitamínicos.',
				titulo: '',
				descripcion: 'Permite tramitar la autorización del cambio de titular del Registro Sanitario  de medicamentos herbolarios, homeopáticos y vitamínicos.',
				version: 1,
				porcentajePago: 0.5,
				atencion: 20,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 20,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 30,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '1001AC',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMHHA,
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO, EHomoclaveBanderasModificaciones.SELECCION_AI],
					medicamento: {
						tipoMedicamentos: [EMedicamentoTipo.HERBOLARIO, EMedicamentoTipo.HOMEOPATICO, EMedicamentoTipo.VITAMINICO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.CESION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_HHV
			}
		]
	},
	{
		homoclave: EHomoclaves['COFEPRIS-2022-022-001-A'],
		titulo: 'Solicitud de primera prórroga del Registro Sanitario de dispositivos médicos. Productos de fabricación nacional.',
		modalidades: [
			{
				modalidad: 'AO',
				modalidadConamer: 'X',
				subtitulo: 'Ordinaria.',
				titulo: '',
				descripcion: 'Permite tramitar la primera prórroga del Registro Sanitario de dispositivos médicos para productos de fabricación nacional.',
				version: 1,
				porcentajePago: 0.75,
				atencion: 120,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 120,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '2201AO',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_DP,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO, EHomoclaveBanderasModificaciones.SELECCION_AI],
					dispositivo: {
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						],
						claseDispositivo: [EDispositivoClaseRIS83.I, EDispositivoClaseRIS83.II, EDispositivoClaseRIS83.III, EDispositivoClaseRIS83.I_BAJO_RIESGO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.PRIMERA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_CON_REGISTRO
			},
			{
				modalidad: 'AR',
				modalidadConamer: 'X',
				subtitulo: 'Retroactiva.',
				titulo: '',
				descripcion: 'Permite tramitar la primera prórroga del Registro Sanitario de dispositivos médicos para productos de fabricación nacional retroactiva.',
				version: 1,
				porcentajePago: 0,
				atencion: 120,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 120,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '2201AR',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_DP,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					prorrogaRetroactiva: true,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.SELECCION_AI],
					dispositivo: {
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						],
						claseDispositivo: [EDispositivoClaseRIS83.I, EDispositivoClaseRIS83.II, EDispositivoClaseRIS83.III, EDispositivoClaseRIS83.I_BAJO_RIESGO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.PRIMERA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_CON_REGISTRO
			}
		]
	},
	{
		homoclave: EHomoclaves['COFEPRIS-2022-022-002-A'],
		titulo: 'Solicitud de primera prórroga del Registro Sanitario de dispositivos médicos. Productos de importación (fabricación extranjera).',
		modalidades: [
			{
				modalidad: 'AO',
				modalidadConamer: 'X',
				subtitulo: 'Ordinaria.',
				titulo: '',
				descripcion: 'Permite tramitar la primera prórroga del Registro Sanitario de dispositivos médicos. Productos de importación (fabricación extranjera).',
				version: 1,
				porcentajePago: 0.75,
				atencion: 120,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 120,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '2202AO',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_DP,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO, EHomoclaveBanderasModificaciones.SELECCION_AI],
					dispositivo: {
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						],
						claseDispositivo: [EDispositivoClaseRIS83.I, EDispositivoClaseRIS83.II, EDispositivoClaseRIS83.III, EDispositivoClaseRIS83.I_BAJO_RIESGO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.PRIMERA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_CON_REGISTRO
			},
			{
				modalidad: 'AR',
				modalidadConamer: 'X',
				subtitulo: 'Retroactiva.',
				titulo: '',
				descripcion: 'Permite tramitar la primera prórroga del Registro Sanitario de dispositivos médicos. Productos de importación (fabricación extranjera) retroactiva.',
				version: 1,
				porcentajePago: 0,
				atencion: 120,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 120,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '2202AR',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_DP,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					prorrogaRetroactiva: true,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.SELECCION_AI],
					dispositivo: {
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						],
						claseDispositivo: [EDispositivoClaseRIS83.I, EDispositivoClaseRIS83.II, EDispositivoClaseRIS83.III, EDispositivoClaseRIS83.I_BAJO_RIESGO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.PRIMERA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_CON_REGISTRO
			}
		]
	},
	{
		homoclave: EHomoclaves['COFEPRIS-2022-022-003-A'],
		titulo: 'Solicitud de modificación a las condiciones del Registro Sanitario de dispositivos médicos. Modificaciones de tipo administrativo.',
		modalidades: [
			{
				modalidad: 'AA',
				modalidadConamer: 'X',
				subtitulo: 'Bajo Riesgo.',
				titulo: '',
				tituloLista: 'Modificaciones de tipo administrativo:',
				elementosLista: [
					'Titular del Registro Sanitario: Cambio de razón social o domicilio (excepto cesión de derechos)',
					'Distribuidor nacional o extranjero: Cambio de razón social o domicilio, inclusión o eliminación',
					'Fabricante: Cambio de razón social o domicilio (solo fabricante real, por expresion), eliminación (solo fabricante real) o inclusión (solo fabricante legal)',
					'Importador nacional o extranjero: Cambio de razón social o domicilio, inclusión o eliminación',
					'Cambio de nombre comercial o número de catálogo del producto, material del envase secundario, códigos o números de referencia del producto, eliminación de presentaciones'
				],
				descripcion: 'Permite obtener la Constancia de modificaciones administrativas a dispositivos medicos de Bajo Riesgo',
				version: 1,
				porcentajePago: 0.75,
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 90,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '2203AA',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_DCR,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [
						EHomoclaveBanderasModificaciones.INFO_SOLICITANTE,
						EHomoclaveBanderasModificaciones.PAGO,
						EHomoclaveBanderasModificaciones.SELECCION_AI,
						EHomoclaveBanderasModificaciones.MODIFICACIONES,
						EHomoclaveBanderasModificaciones.CONSTANCIA
					],
					dispositivo: {
						tipoModificaciones: [EModificacionDispositivosTipo.ADMINISTRATIVAS],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						],
						claseDispositivo: [EDispositivoClaseRIS83.I_BAJO_RIESGO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.MODIFICACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_CON_REGISTRO
			},
			{
				modalidad: 'AB',
				modalidadConamer: 'X',
				subtitulo: 'Clase I, II y III.',
				titulo: '',
				tituloLista: 'Modificaciones de tipo administrativo:',
				elementosLista: [
					'Titular del Registro Sanitario: Cambio de razón social o domicilio (excepto cesión de derechos)',
					'Distribuidor nacional o extranjero: Cambio de razón social o domicilio, inclusión o eliminación',
					'Fabricante: Cambio de razón social o domicilio (solo fabricante real, por expresion), eliminación (solo fabricante real) o inclusión (solo fabricante legal)',
					'Importador nacional o extranjero: Cambio de razón social o domicilio, inclusión o eliminación',
					'Cambio de material del envase secundario'
				],
				descripcion: 'Permite obtener la Constancia de modificaciones administrativas a dispositivos medicos de Clase I, II y III',
				version: 1,
				porcentajePago: 0.75,
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 90,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '2203AB',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_DCR,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [
						EHomoclaveBanderasModificaciones.INFO_SOLICITANTE,
						EHomoclaveBanderasModificaciones.PAGO,
						EHomoclaveBanderasModificaciones.SELECCION_AI,
						EHomoclaveBanderasModificaciones.MODIFICACIONES,
						EHomoclaveBanderasModificaciones.CONSTANCIA
					],
					dispositivo: {
						tipoModificaciones: [EModificacionDispositivosTipo.ADMINISTRATIVAS],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						],
						claseDispositivo: [EDispositivoClaseRIS83.I, EDispositivoClaseRIS83.II, EDispositivoClaseRIS83.III]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.MODIFICACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_CON_REGISTRO
			},
			{
				modalidad: 'AO',
				modalidadConamer: 'X',
				subtitulo: 'Clase I, II y III.',
				titulo: '',
				descripcion: 'Permite tramitar modificaciones administrativas de dispositivos médicos de Clase I, II y III que no son susceptibles de resolución por medio de constancia.',
				version: 1,
				porcentajePago: 0.75,
				atencion: 22,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 22,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '2203AO',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_DCR,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO, EHomoclaveBanderasModificaciones.SELECCION_AI],
					dispositivo: {
						tipoModificaciones: [EModificacionDispositivosTipo.ADMINISTRATIVAS],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						],
						claseDispositivo: [EDispositivoClaseRIS83.I, EDispositivoClaseRIS83.II, EDispositivoClaseRIS83.III, EDispositivoClaseRIS83.I_BAJO_RIESGO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.MODIFICACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_CON_REGISTRO
			}
		]
	},
	{
		homoclave: EHomoclaves['COFEPRIS-2022-022-004-A'],
		titulo: 'Solicitud de modificación a las condiciones del Registro Sanitario de dispositivos médicos. Modificaciones de tipo técnico.',
		modalidades: [
			{
				modalidad: 'X',
				modalidadConamer: 'X',
				subtitulo: '',
				titulo: '',
				descripcion: 'Permite tramitar modificaciones técnicas de dispositivos médicos.',
				version: 1,
				porcentajePago: 0.75,
				atencion: 22,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 22,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '2204AX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_DCR,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.PAGO, EHomoclaveBanderasModificaciones.SELECCION_AI],
					dispositivo: {
						tipoModificaciones: [EModificacionDispositivosTipo.TECNICAS],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						],
						claseDispositivo: [EDispositivoClaseRIS83.I, EDispositivoClaseRIS83.II, EDispositivoClaseRIS83.III, EDispositivoClaseRIS83.I_BAJO_RIESGO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.MODIFICACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_CON_REGISTRO
			}
		]
	},
	{
		homoclave: EHomoclaves['COFEPRIS-2022-022-005-A'],
		titulo: 'Cesión de derechos del Registro Sanitario de dispositivos médicos.',
		modalidades: [
			{
				modalidad: 'X',
				modalidadConamer: 'X',
				subtitulo: '',
				titulo: '',
				descripcion: 'Permite tramitar la autorización del cambio de titular del Registro Sanitario de dispositivos médicos.',
				version: 1,
				porcentajePago: 0.5,
				atencion: 22,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 22,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '2205AX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_DCR,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.SELECCION_AI, EHomoclaveBanderasModificaciones.PAGO],
					dispositivo: {
						tipoModificaciones: [],
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						],
						claseDispositivo: [EDispositivoClaseRIS83.I, EDispositivoClaseRIS83.II, EDispositivoClaseRIS83.III, EDispositivoClaseRIS83.I_BAJO_RIESGO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.CESION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_CON_REGISTRO
			}
		]
	},
	{
		homoclave: EHomoclaves['COFEPRIS-2022-022-006-A'],
		titulo: 'Solicitud de segunda prórroga y subsecuente',
		modalidades: [
			{
				modalidad: 'AA',
				modalidadConamer: 'X',
				subtitulo: 'Medicamentos farmoquímicos.',
				titulo: '',
				descripcion: 'Permite obtener la Constancia de segunda prórroga y subsecuentes de Registro Sanitario de medicamentos farmoquímicos.',
				version: 1,
				porcentajePago: 0.75,
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 90,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '226AAA',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_MCR,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [
						EHomoclaveBanderasModificaciones.INFO_SOLICITANTE,
						EHomoclaveBanderasModificaciones.PAGO,
						EHomoclaveBanderasModificaciones.SELECCION_AI,
						EHomoclaveBanderasModificaciones.CONSTANCIA
					],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: [EMedicamentoTipo.FARMOQUIMICO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.SEGUNDA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_CON_REGISTRO
			},
			{
				modalidad: 'AB',
				modalidadConamer: 'X',
				subtitulo: 'Productos biológicos, biotecnológicos y vacunas.',
				titulo: '',
				descripcion: 'Permite obtener la Constancia de segunda prórroga y subsecuentes de Registro Sanitario de medicamentos biológicos, biotecnológicos y vacunas.',
				version: 1,
				porcentajePago: 0.75,
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 90,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '226AAB',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_BBV,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [
						EHomoclaveBanderasModificaciones.INFO_SOLICITANTE,
						EHomoclaveBanderasModificaciones.PAGO,
						EHomoclaveBanderasModificaciones.SELECCION_AI,
						EHomoclaveBanderasModificaciones.CONSTANCIA
					],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: [EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.HEMODERIVADO, EMedicamentoTipo.VACUNA]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.SEGUNDA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_CON_REGISTRO
			},
			{
				modalidad: 'AC',
				modalidadConamer: 'X',
				subtitulo: 'Medicamentos herbolarios, homeopáticos y vitamínicos.',
				titulo: '',
				descripcion: 'Permite obtener la Constancia de segunda prórroga y subsecuentes de Registro Sanitario de medicamentos herbolarios, homeopáticos y vitamínicos.',
				version: 1,
				porcentajePago: 0.75,
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 90,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '226AAC',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMHHA,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [
						EHomoclaveBanderasModificaciones.INFO_SOLICITANTE,
						EHomoclaveBanderasModificaciones.PAGO,
						EHomoclaveBanderasModificaciones.SELECCION_AI,
						EHomoclaveBanderasModificaciones.CONSTANCIA
					],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: [EMedicamentoTipo.HOMEOPATICO, EMedicamentoTipo.HERBOLARIO, EMedicamentoTipo.VITAMINICO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.SEGUNDA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_CON_REGISTRO
			},
			{
				modalidad: 'AD',
				modalidadConamer: 'X',
				subtitulo: 'Medicamentos farmoquímicos retroactiva.',
				titulo: '',
				descripcion: 'Permite obtener la Constancia de segunda prórroga y subsecuentes de Registro Sanitario de medicamentos farmoquímicos retroactiva.',
				version: 1,
				porcentajePago: 0,
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 90,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '226AAD',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_MCR,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					prorrogaRetroactiva: true,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.SELECCION_AI, EHomoclaveBanderasModificaciones.CONSTANCIA],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: [EMedicamentoTipo.FARMOQUIMICO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.SEGUNDA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_CON_REGISTRO
			},
			{
				modalidad: 'AE',
				modalidadConamer: 'X',
				subtitulo: 'Productos biológicos, biotecnológicos y vacunas retroactiva.',
				titulo: '',
				descripcion: 'Permite obtener la Constancia de segunda prórroga y subsecuentes de Registro Sanitario de medicamentos biológicos, biotecnológicos y vacunas  retroactiva.',
				version: 1,
				porcentajePago: 0,
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 90,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '226AAE',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_BBV,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					prorrogaRetroactiva: true,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.SELECCION_AI, EHomoclaveBanderasModificaciones.CONSTANCIA],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: [EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.HEMODERIVADO, EMedicamentoTipo.VACUNA]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.SEGUNDA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_CON_REGISTRO
			},
			{
				modalidad: 'AF',
				modalidadConamer: 'X',
				subtitulo: 'Medicamentos herbolarios, homeopáticos y vitamínicos retroactiva.',
				titulo: '',
				descripcion: 'Permite obtener la Constancia de segunda prórroga y subsecuentes de Registro Sanitario de medicamentos herbolarios, homeopáticos y vitamínicos  retroactiva.',
				version: 1,
				porcentajePago: 0,
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 90,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '226AAF',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMHHA,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					prorrogaRetroactiva: true,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.SELECCION_AI, EHomoclaveBanderasModificaciones.CONSTANCIA],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: [EMedicamentoTipo.HOMEOPATICO, EMedicamentoTipo.HERBOLARIO, EMedicamentoTipo.VITAMINICO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.SEGUNDA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_CON_REGISTRO
			},
			{
				modalidad: 'BO',
				modalidadConamer: 'X',
				subtitulo: 'Registro Sanitario de dispositivos médicos.',
				titulo: '',
				descripcion: 'Permite obtener la Constancia de segunda prórroga y subsecuentes de Registro Sanitario de dispositivos médicos.',
				version: 1,
				porcentajePago: 0.75,
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 90,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '226ABO',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_DP,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					banderasInsumos: [
						EHomoclaveBanderasModificaciones.INFO_SOLICITANTE,
						EHomoclaveBanderasModificaciones.PAGO,
						EHomoclaveBanderasModificaciones.SELECCION_AI,
						EHomoclaveBanderasModificaciones.CONSTANCIA
					],
					dispositivo: {
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						],
						claseDispositivo: [EDispositivoClaseRIS83.I, EDispositivoClaseRIS83.II, EDispositivoClaseRIS83.III, EDispositivoClaseRIS83.I_BAJO_RIESGO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.SEGUNDA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_CON_REGISTRO
			},
			{
				modalidad: 'BR',
				modalidadConamer: 'X',
				subtitulo: 'Registro Sanitario de dispositivos médicos retroactiva.',
				titulo: '',
				descripcion: 'Permite obtener la Constancia de segunda prórroga y subsecuentes de Registro Sanitario de dispositivos médicos retroactiva.',
				version: 1,
				porcentajePago: 0,
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 90,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '226ABR',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SESSDM_GMCEMPPH_DP,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.DISPOSITIVO,
					prorrogaRetroactiva: true,
					banderasInsumos: [EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.SELECCION_AI, EHomoclaveBanderasModificaciones.CONSTANCIA],
					dispositivo: {
						tipoDispositivo: [
							EDispositivoCategoriaLGS262.I_EQUIPO_MEDICO,
							EDispositivoCategoriaLGS262.II_PROTESIS_ORTESIS_AYUDAS_FUNCIONALES,
							EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
							EDispositivoCategoriaLGS262.IV_INSUMOS_USO_ODONTOLOGICO,
							EDispositivoCategoriaLGS262.V_MATERIALES_QUIRURGICOS_CURACION,
							EDispositivoCategoriaLGS262.VI_PRODUCTOS_HIGIENICOS,
							EDispositivoCategoriaLGS262.VII_DEMAS_INSUMOS_CONSIDERADOS_DISPOSITIVOS
						],
						claseDispositivo: [EDispositivoClaseRIS83.I, EDispositivoClaseRIS83.II, EDispositivoClaseRIS83.III, EDispositivoClaseRIS83.I_BAJO_RIESGO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.SEGUNDA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.DISPOSITIVOS_CON_REGISTRO
			}
		]
	},
	{
		homoclave: EHomoclaves['COFEPRIS-2022-022-007-A'],
		titulo: 'Solicitud de primera prórroga del Registro Sanitario de medicamentos alopáticos, vacunas, hemoderivados y biomedicamentos de fabricación nacional',
		modalidades: [
			{
				modalidad: 'AO',
				modalidadConamer: 'X',
				subtitulo: 'Medicamentos farmoquímicos.',
				titulo: '',
				descripcion: 'Permite tramitar la autorización de la primera prórroga del Registro Sanitario de medicamentos farmoquímicos de fabricación nacional.',
				version: 1,
				porcentajePago: 0.75,
				atencion: 120,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 120,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '227AAO',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_MCR,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [
						EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO,
						EHomoclaveBanderasModificaciones.INFO_SOLICITANTE,
						EHomoclaveBanderasModificaciones.PAGO,
						EHomoclaveBanderasModificaciones.SELECCION_AI
					],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: [EMedicamentoTipo.FARMOQUIMICO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.PRIMERA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_CON_REGISTRO
			},
			{
				modalidad: 'BO',
				modalidadConamer: 'X',
				subtitulo: 'Productos  biológicos, biotecnológicos y vacunas.',
				titulo: '',
				descripcion: 'Permite tramitar la autorización de la primera prórroga del Registro Sanitario de medicamentos biológicos, biotecnológicos y vacunas de fabricación nacional.',
				version: 1,
				porcentajePago: 0.75,
				atencion: 120,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 120,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '227ABO',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_BBV,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [
						EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO,
						EHomoclaveBanderasModificaciones.INFO_SOLICITANTE,
						EHomoclaveBanderasModificaciones.PAGO,
						EHomoclaveBanderasModificaciones.SELECCION_AI
					],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: [EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.HEMODERIVADO, EMedicamentoTipo.VACUNA]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.PRIMERA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_BBV
			},
			{
				modalidad: 'AR',
				modalidadConamer: 'X',
				subtitulo: 'Medicamentos farmoquímicos retroactiva.',
				titulo: '',
				descripcion: 'Permite tramitar la autorización de la primera prórroga del Registro Sanitario de medicamentos farmoquímicos de fabricación nacional retroactiva.',
				version: 1,
				porcentajePago: 0,
				atencion: 120,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 120,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '227AAR',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_MCR,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					prorrogaRetroactiva: true,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.SELECCION_AI],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: [EMedicamentoTipo.FARMOQUIMICO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.PRIMERA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_CON_REGISTRO
			},
			{
				modalidad: 'BR',
				modalidadConamer: 'X',
				subtitulo: 'Productos  biológicos, biotecnológicos y vacunas retroactiva.',
				titulo: '',
				descripcion: 'Permite tramitar la autorización de la primera prórroga del Registro Sanitario de medicamentos biológicos, biotecnológicos y vacunas de fabricación nacional retroactiva.',
				version: 1,
				porcentajePago: 0,
				atencion: 120,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 120,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '227ABR',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_BBV,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					prorrogaRetroactiva: true,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.SELECCION_AI],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: [EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.HEMODERIVADO, EMedicamentoTipo.VACUNA]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.PRIMERA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_BBV
			}
		]
	},
	{
		homoclave: EHomoclaves['COFEPRIS-2022-022-008-A'],
		titulo: 'Solicitud de primera prórroga del Registro Sanitario de medicamentos alopáticos, vacunas, hemoderivados y biomedicamentos de fabricación extranjera',
		modalidades: [
			{
				modalidad: 'AO',
				modalidadConamer: 'X',
				subtitulo: 'Medicamentos farmoquímicos.',
				titulo: '',
				descripcion: 'Permite tramitar la autorización de la primera prórroga del Registro Sanitario de medicamentos farmoquímicos de fabricación extranjera.',
				version: 1,
				porcentajePago: 0.75,
				atencion: 120,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 120,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '228AAO',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_MCR,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [
						EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO,
						EHomoclaveBanderasModificaciones.INFO_SOLICITANTE,
						EHomoclaveBanderasModificaciones.PAGO,
						EHomoclaveBanderasModificaciones.SELECCION_AI
					],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: [EMedicamentoTipo.FARMOQUIMICO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.PRIMERA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_CON_REGISTRO
			},
			{
				modalidad: 'BO',
				modalidadConamer: 'X',
				subtitulo: 'Productos  biológicos, biotecnológicos y vacunas.',
				titulo: '',
				descripcion: 'Permite tramitar la autorización de la primera prórroga del Registro Sanitario de medicamentos biológicos, biotecnológicos y vacunas de fabricación extranjera.',
				version: 1,
				porcentajePago: 0.75,
				atencion: 120,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 120,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '228ABO',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_BBV,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [
						EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO,
						EHomoclaveBanderasModificaciones.INFO_SOLICITANTE,
						EHomoclaveBanderasModificaciones.PAGO,
						EHomoclaveBanderasModificaciones.SELECCION_AI
					],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: [EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.HEMODERIVADO, EMedicamentoTipo.VACUNA]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.PRIMERA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_BBV
			},
			{
				modalidad: 'AR',
				modalidadConamer: 'X',
				subtitulo: 'Medicamentos farmoquímicos retroactiva.',
				titulo: '',
				descripcion: 'Permite tramitar la autorización de la primera prórroga del Registro Sanitario de medicamentos farmoquímicos de fabricación extranjera retroactiva.',
				version: 1,
				porcentajePago: 0,
				atencion: 120,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 120,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '228AAR',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_MCR,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					prorrogaRetroactiva: true,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.SELECCION_AI],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: [EMedicamentoTipo.FARMOQUIMICO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.PRIMERA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_CON_REGISTRO
			},
			{
				modalidad: 'BR',
				modalidadConamer: 'X',
				subtitulo: 'Productos  biológicos, biotecnológicos y vacunas retroactiva.',
				titulo: '',
				descripcion: 'Permite tramitar la autorización de la primera prórroga del Registro Sanitario de medicamentos biológicos, biotecnológicos y vacunas de fabricación extranjera retroactiva.',
				version: 1,
				porcentajePago: 0,
				atencion: 120,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 120,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '228ABR',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_BBV,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					prorrogaRetroactiva: true,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.SELECCION_AI],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: [EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.HEMODERIVADO, EMedicamentoTipo.VACUNA]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.PRIMERA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_BBV
			}
		]
	},
	{
		homoclave: EHomoclaves['COFEPRIS-2022-022-009-A'],
		titulo: 'Solicitud de primera prórroga del Registro Sanitario de medicamentos herbolarios, homeopáticos y vitamínicos de fabricación nacional',
		modalidades: [
			{
				modalidad: 'AO',
				modalidadConamer: 'X',
				subtitulo: 'Medicamentos herbolarios, homeopáticos y vitamínicos.',
				titulo: '',
				descripcion: 'Permite tramitar la autorización de primera prórroga del Registro Sanitario de medicamentos herbolarios, homeopáticos y vitamínicos de fabricación nacional.',
				version: 1,
				porcentajePago: 0.75,
				atencion: 120,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 120,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '229AAO',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMHHA,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [
						EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO,
						EHomoclaveBanderasModificaciones.INFO_SOLICITANTE,
						EHomoclaveBanderasModificaciones.PAGO,
						EHomoclaveBanderasModificaciones.SELECCION_AI
					],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: [EMedicamentoTipo.HOMEOPATICO, EMedicamentoTipo.HERBOLARIO, EMedicamentoTipo.VITAMINICO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.PRIMERA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_HHV
			},
			{
				modalidad: 'AR',
				modalidadConamer: 'X',
				subtitulo: 'Medicamentos herbolarios, homeopáticos y vitamínicos retroactiva.',
				titulo: '',
				descripcion: 'Permite tramitar la autorización de primera prórroga del Registro Sanitario de medicamentos herbolarios, homeopáticos y vitamínicos de fabricación nacional retroactiva.',
				version: 1,
				porcentajePago: 0,
				atencion: 120,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 120,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '229AAR',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMHHA,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					prorrogaRetroactiva: true,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.SELECCION_AI],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: [EMedicamentoTipo.HOMEOPATICO, EMedicamentoTipo.HERBOLARIO, EMedicamentoTipo.VITAMINICO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.PRIMERA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_HHV
			}
		]
	},
	{
		homoclave: EHomoclaves['COFEPRIS-2022-022-010-A'],
		titulo: 'Solicitud de primera prórroga del Registro Sanitario de medicamentos herbolarios, homeopáticos y vitamínicos de fabricación extranjera',
		modalidades: [
			{
				modalidad: 'AO',
				modalidadConamer: 'X',
				subtitulo: 'Medicamentos herbolarios, homeopáticos y vitamínicos.',
				titulo: '',
				descripcion: 'Permite tramitar la autorización de primera prórroga del Registro Sanitario de medicamentos herbolarios, homeopáticos y vitamínicos de fabricación extranjera.',
				version: 1,
				porcentajePago: 0.75,
				atencion: 120,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 120,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '2210AO',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMHHA,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [
						EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO,
						EHomoclaveBanderasModificaciones.INFO_SOLICITANTE,
						EHomoclaveBanderasModificaciones.PAGO,
						EHomoclaveBanderasModificaciones.SELECCION_AI
					],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: [EMedicamentoTipo.HOMEOPATICO, EMedicamentoTipo.HERBOLARIO, EMedicamentoTipo.VITAMINICO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.PRIMERA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_HHV
			},
			{
				modalidad: 'AR',
				modalidadConamer: 'X',
				subtitulo: 'Medicamentos herbolarios, homeopáticos y vitamínicos retroactiva.',
				titulo: '',
				descripcion: 'Permite tramitar la autorización de primera prórroga del Registro Sanitario de medicamentos herbolarios, homeopáticos y vitamínicos de fabricación extranjera retroactiva.',
				version: 1,
				porcentajePago: 0,
				atencion: 120,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 120,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 120,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '2210AR',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMHHA,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					prorrogaRetroactiva: true,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.SELECCION_AI],
					medicamento: {
						tipoModificaciones: [],
						tipoMedicamentos: [EMedicamentoTipo.HOMEOPATICO, EMedicamentoTipo.HERBOLARIO, EMedicamentoTipo.VITAMINICO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.PRIMERA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_HHV
			}
		]
	},
	{
		homoclave: EHomoclaves['COFEPRIS-2022-022-011-A'],
		titulo: 'Solicitud de modificaciones menores a las condiciones de Registro Sanitario de medicamentos',
		modalidades: [
			{
				modalidad: 'AA',
				modalidadConamer: 'X',
				subtitulo: 'Medicamentos farmoquímicos.',
				titulo: '',
				descripcion: 'Permite obtener la Constancia de modificación a las condiciones de Registro Sanitario de medicamentos farmoquímicos.',
				version: 1,
				porcentajePago: 0.75,
				e5cincoReferenciaDPA: '554001071',
				e5PrefijoCadenaDependencia: '0022011',
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 90,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '2211AA',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_MCR,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [
						EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO,
						EHomoclaveBanderasModificaciones.MODIFICACIONES,
						EHomoclaveBanderasModificaciones.INFO_SOLICITANTE,
						EHomoclaveBanderasModificaciones.PAGO,
						EHomoclaveBanderasModificaciones.SELECCION_AI,
						EHomoclaveBanderasModificaciones.CONSTANCIA
					],
					medicamento: {
						tipoModificaciones: [EModificacionesMedicamentoTipo.MENOR],
						tipoMedicamentos: [EMedicamentoTipo.FARMOQUIMICO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.MODIFICACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_CON_REGISTRO
			},
			{
				modalidad: 'AB',
				modalidadConamer: 'X',
				subtitulo: 'Productos  biológicos, biotecnológicos y vacunas.',
				titulo: '',
				descripcion: 'Permite obtener la Constancia de modificación a las condiciones de Registro Sanitario de medicamentos biológicos, biotecnológicos y vacunas.',
				version: 1,
				porcentajePago: 0.75,
				e5cincoReferenciaDPA: '554001071',
				e5PrefijoCadenaDependencia: '0022011',
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 90,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '2211AB',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_BBV,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [
						EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO,
						EHomoclaveBanderasModificaciones.MODIFICACIONES,
						EHomoclaveBanderasModificaciones.INFO_SOLICITANTE,
						EHomoclaveBanderasModificaciones.PAGO,
						EHomoclaveBanderasModificaciones.SELECCION_AI,
						EHomoclaveBanderasModificaciones.CONSTANCIA
					],
					medicamento: {
						tipoModificaciones: [EModificacionesMedicamentoTipo.MENOR],
						tipoMedicamentos: [EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.VACUNA, EMedicamentoTipo.HEMODERIVADO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.MODIFICACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_BBV
			},
			{
				modalidad: 'AC',
				modalidadConamer: 'X',
				subtitulo: 'Medicamentos herbolarios, homeopáticos y vitamínicos.',
				titulo: '',
				descripcion: 'Permite obtener la Constancia de modificación a las condiciones de Registro Sanitario de medicamentos herbolarios, homeopáticos y vitamínicos.',
				version: 1,
				porcentajePago: 0.75,
				e5cincoReferenciaDPA: '554001071',
				e5PrefijoCadenaDependencia: '0022011',
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 90,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '2211AC',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMHHA,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [
						EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO,
						EHomoclaveBanderasModificaciones.MODIFICACIONES,
						EHomoclaveBanderasModificaciones.INFO_SOLICITANTE,
						EHomoclaveBanderasModificaciones.PAGO,
						EHomoclaveBanderasModificaciones.SELECCION_AI,
						EHomoclaveBanderasModificaciones.CONSTANCIA
					],
					medicamento: {
						tipoModificaciones: [EModificacionesMedicamentoTipo.MENOR],
						tipoMedicamentos: [EMedicamentoTipo.HERBOLARIO, EMedicamentoTipo.HOMEOPATICO, EMedicamentoTipo.VITAMINICO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.MODIFICACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_HHV
			}
		]
	},
	{
		homoclave: EHomoclaves['COFEPRIS-2022-022-012-A'],
		titulo: 'Solicitud de modificaciones moderadas a las condiciones de Registro Sanitario de medicamentos',
		modalidades: [
			{
				modalidad: 'AA',
				modalidadConamer: 'X',
				subtitulo: 'Medicamentos farmoquímicos.',
				titulo: '',
				descripcion: 'Permite obtener la Constancia de modificación a las condiciones de Registro Sanitario de medicamentos farmoquímicos.',
				version: 1,
				porcentajePago: 0.75,
				e5cincoReferenciaDPA: '554001071',
				e5PrefijoCadenaDependencia: '0022012',
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 90,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '2212AA',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_MCR,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [
						EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO,
						EHomoclaveBanderasModificaciones.MODIFICACIONES,
						EHomoclaveBanderasModificaciones.INFO_SOLICITANTE,
						EHomoclaveBanderasModificaciones.PAGO,
						EHomoclaveBanderasModificaciones.SELECCION_AI,
						EHomoclaveBanderasModificaciones.CONSTANCIA
					],
					medicamento: {
						tipoModificaciones: [EModificacionesMedicamentoTipo.MENOR, EModificacionesMedicamentoTipo.MODERADA],
						tipoMedicamentos: [EMedicamentoTipo.FARMOQUIMICO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.MODIFICACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_CON_REGISTRO
			},
			{
				modalidad: 'AB',
				modalidadConamer: 'X',
				subtitulo: 'Productos  biológicos, biotecnológicos y vacunas.',
				titulo: '',
				descripcion: 'Permite obtener la Constancia de modificación a las condiciones de Registro Sanitario de medicamentos biológicos, biotecnológicos y vacunas.',
				version: 1,
				porcentajePago: 0.75,
				e5cincoReferenciaDPA: '554001071',
				e5PrefijoCadenaDependencia: '0022012',
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 90,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '2212AB',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_BBV,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [
						EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO,
						EHomoclaveBanderasModificaciones.MODIFICACIONES,
						EHomoclaveBanderasModificaciones.INFO_SOLICITANTE,
						EHomoclaveBanderasModificaciones.PAGO,
						EHomoclaveBanderasModificaciones.SELECCION_AI,
						EHomoclaveBanderasModificaciones.CONSTANCIA
					],
					medicamento: {
						tipoModificaciones: [EModificacionesMedicamentoTipo.MENOR, EModificacionesMedicamentoTipo.MODERADA],
						tipoMedicamentos: [EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.HEMODERIVADO, EMedicamentoTipo.VACUNA]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.MODIFICACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_BBV
			},
			{
				modalidad: 'AC',
				modalidadConamer: 'X',
				subtitulo: 'Medicamentos herbolarios, homeopáticos y vitamínicos.',
				titulo: '',
				descripcion: 'Permite obtener la Constancia de modificación a las condiciones de Registro Sanitario de medicamentos herbolarios, homeopáticos y vitamínicos.',
				version: 1,
				porcentajePago: 0.75,
				e5cincoReferenciaDPA: '554001071',
				e5PrefijoCadenaDependencia: '0022012',
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 90,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '2212AC',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMHHA,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [
						EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO,
						EHomoclaveBanderasModificaciones.MODIFICACIONES,
						EHomoclaveBanderasModificaciones.INFO_SOLICITANTE,
						EHomoclaveBanderasModificaciones.PAGO,
						EHomoclaveBanderasModificaciones.SELECCION_AI,
						EHomoclaveBanderasModificaciones.CONSTANCIA
					],
					medicamento: {
						tipoModificaciones: [EModificacionesMedicamentoTipo.MENOR, EModificacionesMedicamentoTipo.MODERADA],
						tipoMedicamentos: [EMedicamentoTipo.HERBOLARIO, EMedicamentoTipo.HOMEOPATICO, EMedicamentoTipo.VITAMINICO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.MODIFICACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_HHV
			}
		]
	},
	{
		homoclave: EHomoclaves['COFEPRIS-2022-022-013-A'],
		titulo: 'Solicitud de modificaciones mayores a las condiciones de Registro Sanitario de medicamentos',
		modalidades: [
			{
				modalidad: 'AA',
				modalidadConamer: 'X',
				subtitulo: 'Medicamentos farmoquímicos.',
				titulo: '',
				descripcion: 'Permite tramitar la autorización de modificaciones mayores de Registro Sanitario de medicamentos farmoquímicos.',
				version: 1,
				porcentajePago: 0.75,
				e5cincoReferenciaDPA: '554001071',
				e5PrefijoCadenaDependencia: '0022013',
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 45,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '2213AA',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_MCR,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [
						EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO,
						EHomoclaveBanderasModificaciones.INFO_SOLICITANTE,
						EHomoclaveBanderasModificaciones.PAGO,
						EHomoclaveBanderasModificaciones.SELECCION_AI
					],
					medicamento: {
						tipoModificaciones: [EModificacionesMedicamentoTipo.MAYOR],
						tipoMedicamentos: [EMedicamentoTipo.FARMOQUIMICO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.MODIFICACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_CON_REGISTRO
			},
			{
				modalidad: 'AB',
				modalidadConamer: 'X',
				subtitulo: 'Productos  biológicos, biotecnológicos y vacunas.',
				titulo: '',
				descripcion: 'Permite tramitar la autorización de modificaciones mayores de Registro Sanitario de medicamentos biológicos, biotecnológicos y vacunas.',
				version: 1,
				porcentajePago: 0.75,
				e5cincoReferenciaDPA: '554001071',
				e5PrefijoCadenaDependencia: '0022013',
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 45,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '2213AB',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_BBV,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [
						EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO,
						EHomoclaveBanderasModificaciones.INFO_SOLICITANTE,
						EHomoclaveBanderasModificaciones.PAGO,
						EHomoclaveBanderasModificaciones.SELECCION_AI
					],
					medicamento: {
						tipoModificaciones: [EModificacionesMedicamentoTipo.MAYOR],
						tipoMedicamentos: [EMedicamentoTipo.BIOTECNOLOGICO, EMedicamentoTipo.BIOLOGICO, EMedicamentoTipo.HEMODERIVADO, EMedicamentoTipo.VACUNA]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.MODIFICACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_BBV
			},
			{
				modalidad: 'AC',
				modalidadConamer: 'X',
				subtitulo: 'Medicamentos herbolarios, homeopáticos y vitamínicos.',
				titulo: '',
				descripcion: 'Permite tramitar la autorización de modificaciones mayores de Registro Sanitario  de medicamentos herbolarios, homeopáticos y vitamínicos.',
				version: 1,
				porcentajePago: 0.75,
				e5cincoReferenciaDPA: '554001071',
				e5PrefijoCadenaDependencia: '0022013',
				atencion: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				atencionPrevencion: 45,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				tiempoRespuestaSolicitante: 45,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES,
				infijoIdTramite: '2213AC',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMHHA,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [
						EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO,
						EHomoclaveBanderasModificaciones.INFO_SOLICITANTE,
						EHomoclaveBanderasModificaciones.PAGO,
						EHomoclaveBanderasModificaciones.SELECCION_AI
					],
					medicamento: {
						tipoModificaciones: [EModificacionesMedicamentoTipo.MAYOR],
						tipoMedicamentos: [EMedicamentoTipo.HERBOLARIO, EMedicamentoTipo.HOMEOPATICO, EMedicamentoTipo.VITAMINICO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.MODIFICACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_HHV
			}
		]
	},
	{
		homoclave: EHomoclaves['ESCRITO-LIBRE-EL87'],
		titulo: 'Solicitud relacionada a Medicamento Huérfano',
		modalidades: [
			{
				modalidad: 'M',
				modalidadConamer: 'X',
				subtitulo: 'Modificaciones a las condiciones del Reconocimiento de Medicamento Huérfano.',
				titulo: '',
				descripcion: 'Permite tramitar modificaciones a las condiciones del reconocimiento de un medicamento huérfano.',
				version: 1,
				porcentajePago: 0,
				grupoPago: EMedicamentoGrupoPago.HUERFANO,
				atencion: 240,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 240,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 240,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: 'EL87MX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_HUERFANOS,
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.SELECCION_AI],
					medicamento: {
						tipoMedicamentos: [EMedicamentoTipo.HUERFANO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.MODIFICACION
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_SIN_REGISTRO
			},
			{
				modalidad: 'P',
				modalidadConamer: 'X',
				subtitulo: 'Prórroga del Reconocimiento de Medicamento Huérfano.',
				titulo: '',
				descripcion: 'Permite tramitar la prórroga del reconocimiento de un medicamento huérfano.',
				version: 1,
				porcentajePago: 0,
				grupoPago: EMedicamentoGrupoPago.HUERFANO,
				atencion: 240,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 240,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 240,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: 'EL87PX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_HUERFANOS,
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE, EHomoclaveBanderasModificaciones.SELECCION_AI],
					medicamento: {
						tipoMedicamentos: [EMedicamentoTipo.HUERFANO]
					},
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.PRIMERA_PRORROGA
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_SIN_REGISTRO
			},
			{
				modalidad: 'R',
				modalidadConamer: 'X',
				subtitulo: 'Reconocimiento de Medicamento Huérfano.',
				titulo: '',
				descripcion: 'Permite tramitar el Reconocimiento de un medicamento huérfano.',
				version: 1,
				porcentajePago: 0,
				grupoPago: EMedicamentoGrupoPago.HUERFANO,
				atencion: 240,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				atencionPrevencion: 240,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				tiempoRespuestaSolicitante: 240,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: 'EL87RX',
				unidadOrganizativa: EOrganizacionUnidadesOrganizativas.COFEPRIS_CAS_DEAPE_SEFM_GMA_HUERFANOS,
				produceActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD,
				evaluaciones: [],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				insumoSalud: {
					tipoInsumo: EAIInsumoSaludTipo.MEDICAMENTO,
					banderasInsumos: [EHomoclaveBanderasModificaciones.ESTRUCTURA_MEDICAMENTO, EHomoclaveBanderasModificaciones.INFO_SOLICITANTE],
					accion: EHomoclaveRegistryModalidadInsumoSaludAccion.RECONOCIMIENTO_HUERFANO
				},
				grupoCoordinacion: EHomoclaveRegistryGrupoCoordinacion.MEDICAMENTOS_FQ_SIN_REGISTRO
			}
		]
	},
	{
		homoclave: EHomoclaves['COFEPRIS-ES-45'],
		titulo: 'Escrito Libre relacionado a un protocolo de investigación',
		modalidades: [
			{
				modalidad: 'A',
				modalidadConamer: 'X',
				subtitulo: 'Escrito libre referente a un protocolo de investigación.',
				titulo: '',
				descripcion: 'Un escrito libre, es la manera formal que tiene un causante para dirigirse a la institución, ya sea para alguna consulta, aclaración o para manifestar alguna situación.',
				version: 20231129,
				atencion: 90,
				atencionPrevencion: 45,
				tiempoRespuestaSolicitante: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: 'ES45A',
				unidadOrganizativa: 'COFEPRIS_CAS_DEAPE_SEFM_EC',
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				evaluaciones: [EHomoclaveContextResultadoEvaluacion.ATENDIDO],
				formatos: {
					solicitud: [
						{
							//Escrito libre
							formato: 'FF_COFEPRIS_01_45',
							secciones: ['2'],
							titulo: 'Datos del propietario'
						},
						{
							formato: 'FF_COFEPRIS_01_45',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						{
							formato: 'FORMATO_ANTECEDENTES_45',
							secciones: ['ANTECEDENTES_45'],
							titulo: 'Antecedentes del protocolo'
						},
						{
							formato: 'ES_45_OTROS_DOCUMENTOS',
							secciones: ['DOCUMENTOS_INVESTIGACION_ES_45'],
							titulo: 'Documentos de la investigación'
						},
						{
							formato: 'ES_45_OTROS_DOCUMENTOS',
							secciones: ['OTROS_DOCUMENTOS'],
							titulo: 'Otros documentos'
						}
					],
					evaluacion: [
						{
							formato: 'FORMATO_DICTAMINADOR_45',
							secciones: ['AUTORIZADO_45'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						}
					]
				}
			},
			{
				modalidad: 'B',
				modalidadConamer: 'X',
				subtitulo: 'Informe parcial de protocolo de investigación.',
				titulo: '',
				descripcion: 'Informe parcial sobre la investigación de un protocolo autorizado previamente.',
				version: 20231129,
				atencion: 90,
				atencionPrevencion: 45,
				tiempoRespuestaSolicitante: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: 'ES45B',
				unidadOrganizativa: 'COFEPRIS_CAS_DEAPE_SEFM_EC',
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				evaluaciones: [EHomoclaveContextResultadoEvaluacion.ATENDIDO],
				formatos: {
					solicitud: [
						{
							//Escrito libre ES-45 parcial
							formato: 'FF_COFEPRIS_01_45_PARCIAL',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01_45_PARCIAL',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						{
							formato: 'FORMATO_ANTECEDENTES_45_PARCIAL',
							secciones: ['ANTECEDENTES_45_PARCIAL'],
							titulo: 'Antecedentes del protocolo '
						},
						{
							formato: 'ES_45_PARCIAL',
							secciones: ['DOCUMENTOS_45_PARCIAL'],
							titulo: 'Documentos de la Investigación'
						},
						{
							formato: 'ES_45_PARCIAL',
							secciones: ['DOCUMENTOS_INFORME_PARCIAL_45'],
							titulo: 'Documentos de informe parcial del protocolo'
						},
						{
							formato: 'ES_45_PARCIAL',
							secciones: ['OTROS_DOCUMENTOS_45_PARCIAL'],
							titulo: 'Otros documentos'
						}
					],
					evaluacion: [
						{
							formato: 'FORMATO_DICTAMINADOR_45_PARCIAL',
							secciones: ['AUTORIZADO_45_PARCIAL'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						}
					]
				}
			}
		]
	},
	{
		homoclave: EHomoclaves['COFEPRIS-ES-47'],
		titulo: 'Informe final de protocolo de investigación',
		modalidades: [
			{
				modalidad: 'X',
				modalidadConamer: 'X',
				subtitulo: '',
				titulo: '',
				descripcion: 'Informe final sobre la investigación de un protocolo autorizado previamente.',
				version: 20231129,
				atencion: 90,
				atencionPrevencion: 45,
				tiempoRespuestaSolicitante: 45,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: 'ES47X',
				unidadOrganizativa: 'COFEPRIS_CAS_DEAPE_SEFM_EC',
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_ENSAYO_CLINICO,
				evaluaciones: [EHomoclaveContextResultadoEvaluacion.ATENDIDO],
				formatos: {
					solicitud: [
						{
							//Informe final ES-47
							formato: 'FF_COFEPRIS_01_47',
							secciones: ['2'],
							titulo: 'Datos del Propietario'
						},
						{
							formato: 'FF_COFEPRIS_01_47',
							secciones: ['3'],
							titulo: 'Datos del Establecimiento del Titular'
						},
						{
							formato: 'FORMATO_ANTECEDENTES_47',
							secciones: ['ANTECEDENTES_47'],
							titulo: 'Antecedentes del protocolo'
						},
						{
							formato: 'ES_47_FINAL',
							secciones: ['DOCUMENTOS_47'],
							titulo: 'Documentos de la Investigación'
						},
						{
							formato: 'ES_47_FINAL',
							secciones: ['DOCUMENTOS_FINALIZACION_47'],
							titulo: 'Documentos de finalización del  protocolo'
						},
						{
							formato: 'ES_47_FINAL',
							secciones: ['OTROS_DOCUMENTOS_47'],
							titulo: 'Otros documentos'
						}
					],
					evaluacion: [
						{
							formato: 'FORMATO_DICTAMINADOR_47',
							secciones: ['AUTORIZADO_47'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						}
					]
				}
			}
		]
	},
	{
		homoclave: EHomoclaves['IPN-00-A'],
		titulo: 'Solicitud para pruebas de controles',
		modalidades: [
			{
				modalidad: 'A',
				modalidadConamer: 'X',
				subtitulo: '',
				titulo: 'Solicitud para pruebas unitarias de controles utilizados en las solicitudes de trámite.',
				descripcion: 'Este trámite se utiliza para realizar pruebas de los formatos de solicitud de trámites de la COFEPRIS con los controles disponibles en las solicitudes.',
				version: 20231129,
				atencion: 3,
				atencionPrevencion: 2,
				tiempoRespuestaSolicitante: 2,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: '000A',
				unidadOrganizativa: 'COFEPRIS_CAS_DEAPE_SEFM_EC',
				evaluaciones: [EHomoclaveContextResultadoEvaluacion.AUTORIZADO, EHomoclaveContextResultadoEvaluacion.PREVENIDO, EHomoclaveContextResultadoEvaluacion.DESECHADO],
				formatos: {
					solicitud: [
						{
							formato: 'IPN_00_000_A',
							secciones: ['CONTROLES_INDIVIDUALES'],
							titulo: 'Controles Individuales'
						},
						{
							formato: 'IPN_00_000_A',
							secciones: ['CONTROLES_FORMULARIOS'],
							titulo: 'Controles Formularios'
						},
						{
							formato: 'IPN_00_000_A',
							secciones: ['CONTROLES_TABLAS'],
							titulo: 'Controles Tablas'
						},
						{
							formato: 'IPN_00_000_A',
							secciones: ['TRANSPUESTA'],
							titulo: 'Tabla transpuesta'
						},
						{
							formato: 'IPN_00_000_A',
							secciones: ['TEST_DUPLICADO'],
							titulo: 'Seccion duplicada'
						}
					],
					evaluacion: [
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['RESOLUCION'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_PRINCIPAL'],
							actores: [EActor.DICTAMINADOR_RESPONSABLE]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_ESTADISTICO'],
							actores: [EActor.DICTAMINADOR_ESTADISTICO]
						},
						{
							formato: 'FORMATO_EVALUACION_ENSAYO_CLINICO',
							secciones: ['EVALUACION_CMN'],
							actores: [EActor.COORDINADOR_COMITE_MOLECULAS_NUEVAS]
						}
					]
				}
			}
		]
	},
	{
		homoclave: EHomoclaves['IPN-CTD-M'],
		titulo: 'Solicitud para pruebas CTD Medicamentos',
		modalidades: [
			{
				modalidad: 'A',
				modalidadConamer: 'X',
				subtitulo: '',
				titulo: 'Solicitud para pruebas de controles para CTD.',
				descripcion: 'Este trámite se utiliza para realizar pruebas de los formatos de solicitud del CTD',
				version: 1,
				atencion: 3,
				atencionPrevencion: 2,
				tiempoRespuestaSolicitante: 2,
				calculoAtencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoAtencionPrevencion: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				calculoTiempoRespuestaSolicitante: EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES,
				infijoIdTramite: 'X',
				unidadOrganizativa: 'COFEPRIS_CAS_DEAPE_SEFM_EC',
				evaluaciones: [EHomoclaveContextResultadoEvaluacion.AUTORIZADO, EHomoclaveContextResultadoEvaluacion.PREVENIDO, EHomoclaveContextResultadoEvaluacion.DESECHADO],
				formatos: {
					solicitud: [],
					evaluacion: []
				},
				requiereAntecedenteActivoInformacion: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD
			}
		]
	}
]

export const getCedulaFromHomoclaveModalidad = (homoclave: string, modalidad: string): string => {
	let textoCedula = ''
	switch (homoclave + modalidad) {
		case 'COFEPRIS-04-010A':
		case 'COFEPRIS-04-010C':
		case 'COFEPRIS-04-010D':
			textoCedula = 'CÉDULA DE DICTAMEN PARA INVESTIGACIÓN CON MEDICAMENTOS\nCAS-DEAPE-P-16-POI-01-F-06'
			break
		case 'COFEPRIS-04-010B':
			textoCedula = 'CÉDULA DE DICTAMEN INTERCAMBIABILIDAD DE MEDICAMENTOS\nCAS-DEAPE-P-16-POI-02-F-01'
			break
		case 'COFEPRIS-09-012A':
		case 'COFEPRIS-09-012B':
		case 'COFEPRIS-09-012C':
		case 'COFEPRIS-09-012D':
		case 'COFEPRIS-09-012E':
		case 'COFEPRIS-09-012F':
		case 'COFEPRIS-09-012G':
		case 'COFEPRIS-09-012H':
		case 'COFEPRIS-09-012I':
		case 'COFEPRIS-09-012J':
		case 'COFEPRIS-09-012K':
		case 'COFEPRIS-09-012L':
			textoCedula = 'CÉDULA DE DICTAMEN\nCAS-DEAPE-P-16-POI-03-F-04'
			break
	}
	return textoCedula
}
export const getTramiteByIdHomoclaveAndIdModalidad = (idHomoclave: string | EHomoclaves, idModalidad: string | EModalidad): HomoclaveRegistryTramite => {
	const homoclave = {
		...Registro.find(item => item.homoclave === idHomoclave)
	} as HomoclaveRegistryItem
	const modalidad = {
		...homoclave.modalidades?.find(item => item.modalidad === idModalidad)
	} as HomoclaveRegistryModalidad
	delete homoclave.modalidades
	return { homoclave, modalidad } as HomoclaveRegistryTramite
}

export const getFormatosByIDHomoclaveIdModalidadAndEstado = (idHomoclave: string, idModalidad: string, estado: string): HomoclaveRegistryFormato[] => {
	let response: HomoclaveRegistryFormato[] = []
	const tramite = getTramiteByIdHomoclaveAndIdModalidad(idHomoclave, idModalidad)

	if (tramite) {
		if (tramite.modalidad) {
			if (tramite.modalidad.formatos) {
				const formatos = tramite.modalidad.formatos[estado as keyof IHomoclaveRegistryModalidadFormatos]
				if (formatos) {
					response = [...formatos]
				}
			}
		}
	}
	return response
}

export const getDiccionarioCatalogoHomoclavesModalidades = (homoclaveFiltrada: string | undefined = undefined): CatalogGenericData[] => {
	const diccionario: CatalogGenericData[] = []
	console.log(homoclaveFiltrada)
	Registro.forEach(homoclave => {
		if (homoclaveFiltrada == undefined || homoclave.homoclave == homoclaveFiltrada) {
			homoclave.modalidades?.forEach(modalidad => {
				diccionario.push({
					key: `${homoclave.homoclave}-${modalidad.modalidad}`,
					value: {
						etiqueta: `${homoclave.homoclave}-${modalidad.modalidad}`,
						homoclave: homoclave.homoclave,
						modalidad: modalidad.modalidad
					} as any
				})
			})
		}
	})
	return diccionario
}

export const getDiccionarioCatalogoHomoclaves = (): CatalogGenericData[] => {
	const diccionario: CatalogGenericData[] = []
	Registro.forEach(homoclave => {
		diccionario.push({
			key: `${homoclave.homoclave}`,
			value: {
				etiqueta: `${homoclave.homoclave}`,
				homoclave: homoclave.homoclave
			} as any
		})
	})
	return diccionario
}

export interface IDescripcionArmonizadaHomoclaveModalidad {
	homoclave: string
	homoclaveTitulo: string
	modalidad: string
	modalidadInterna: string
	modalidadTitulo: string
	modalidadDescripcion: string
	listaTitulo?: string
	listaElementos?: string[]
	_version?: string
	_modalidadNombre: string
	_modalidadSubtitulo: string
}

/**
 * @name getDescripcionArmonizadaHomoclaveModalidad
 * @description Obtiene la descripción armonizada de una homoclave y modalidad para registros de ensayos clínicos y de insumos para la salud
 * @reference Soluciona el problema de la descripción de las homoclaves y modalidades usadas internamente y que difieren de conamer, entregando una descripción armonizada
 */
export const getDescripcionArmonizadaHomoclaveModalidad = (_homoclave: string, _modalidad: string): IDescripcionArmonizadaHomoclaveModalidad => {
	const homoclaveModalidad = getTramiteByIdHomoclaveAndIdModalidad(_homoclave, _modalidad)

	const homoclave: string = homoclaveModalidad.homoclave.homoclave
	const homoclaveTitulo: string = homoclaveModalidad.homoclave.titulo
	let modalidad: string = '-'
	const modalidadInterna: string = homoclaveModalidad.modalidad.modalidad
	let modalidadTitulo: string = ''
	const modalidadDescripcion: string = homoclaveModalidad.modalidad.descripcion
	const listaTitulo: string | undefined = homoclaveModalidad.modalidad.tituloLista
	const listaElementos: string[] | undefined = homoclaveModalidad.modalidad.elementosLista
	const _version: string = homoclaveModalidad.modalidad.version.toString()
	const _modalidadNombre: string = homoclaveModalidad.modalidad.titulo
	const _modalidadSubtitulo: string = homoclaveModalidad.modalidad.subtitulo

	if (homoclaveModalidad.modalidad.modalidadConamer == 'X') {
		if (homoclaveModalidad.modalidad.modalidad != homoclaveModalidad.modalidad.modalidadConamer) {
			modalidadTitulo = homoclaveModalidad.modalidad.subtitulo
		}
	} else {
		modalidad = homoclaveModalidad.modalidad.modalidadConamer!
		if (homoclaveModalidad.modalidad.modalidad == homoclaveModalidad.modalidad.modalidadConamer) {
			modalidadTitulo = homoclaveModalidad.modalidad.titulo
		} else {
			modalidadTitulo = homoclaveModalidad.modalidad.titulo + (homoclaveModalidad.modalidad.titulo.endsWith('.') ? ' ' : '. ') + homoclaveModalidad.modalidad.subtitulo
		}
	}

	return {
		homoclave,
		homoclaveTitulo,
		modalidad,
		modalidadInterna,
		modalidadTitulo,
		modalidadDescripcion,
		listaTitulo,
		listaElementos,
		_version,
		_modalidadNombre,
		_modalidadSubtitulo
	} as IDescripcionArmonizadaHomoclaveModalidad
}
export const getAccion = (homoclave: string, modalidad: string): EHomoclaveRegistryModalidadInsumoSaludAccion => {
	const homoclaveModalidad = getTramiteByIdHomoclaveAndIdModalidad(homoclave, modalidad)
	console.log('homoclaveModalidad', homoclaveModalidad)
	return homoclaveModalidad.modalidad.insumoSalud?.accion as EHomoclaveRegistryModalidadInsumoSaludAccion
}

/**
 * @name getInformacionReferenciasLlavesPagoHomoclaveModalidad
 * @description Obtiene la información de las referencias de llaves de pago para las homoclaves y modalidades de un entorno
 * @returns string[][] - Arreglo de arreglos con la información de las referencias de llaves de pago
 * campo 1: homoclave
 * campo 2: modalidad
 * campo 3: referencia DPA
 * campo 4: prefijo de la cadena de la dependencia
 */
export const getInformacionReferenciasLlavesPagoHomoclaveModalidad = (environment: EnvironmentType): string[][] => {
	const respuesta: string[][] = []
	CHomoclavesGruposEnvironment[environment].forEach(grupo => {
		if (grupo.length > 0) {
			Registro.forEach(homoclave => {
				if (CHomoclavesGrupos[grupo].includes(homoclave.homoclave as EHomoclaves)) {
					homoclave.modalidades?.forEach(modalidad => {
						if (CGruposHomoclavesModalidades[grupo][homoclave.homoclave].includes(modalidad.modalidad as EModalidad)) {
							if (modalidad.e5cincoReferenciaDPA && modalidad.e5PrefijoCadenaDependencia) {
								const fila = []
								fila.push(homoclave.homoclave)
								fila.push(modalidad.modalidad)
								fila.push(modalidad.e5cincoReferenciaDPA)
								fila.push(modalidad.e5PrefijoCadenaDependencia)
								respuesta.push(fila)
							}
						}
					})
				}
			})
		}
	})
	return respuesta
}
