/**
 * @name ActivoInsumo
 * @description Clase que representa un activo de información de insumo de salud
 *
 * @autor @Jorel254
 * @version 0.0.7
 * @changelog 0.0.7 - 12/nov/24 - Se modifica la eliminación de alertas sanitarias
 * @changelog - 0.0.6 - 28/oct/24/ Se agregaron funciones para eliminar secciones del activo que son opcionales y se ajusta con respecto a la nueva version del IAIInsumoSalud  @Jorel254
 * @changelog - 0.0.5 - 14/oct/24/ Se agregaron la propiedades draft, prorrogado, prorrogaEnEvaluacion y Corrección de la creación de la versión minima  @Jorel254
 * @changelog - 0.0.4 - 12/sep/24/ Se corrigen funciones de la clase  @Jorel254
 * @changelog - 0.0.3 - 29/ago/24/ Se agregan funciones para eliminar formulas de medicamentos  @Jorel254
 */
import {
	EAIInsumoSaludAlertaSanitariaEstado,
	EAIInsumoSaludAlertaSanitariaNivel,
	EAIInsumoSaludEstadoAutorizacion,
	EAIInsumoSaludOrigen,
	EAIInsumoSaludTipo,
	EAIInsumoSaludTipoAutorizacion,
	EDispositivoCategoriaLGS262,
	EDispositivoClaseRIS83,
	EDispositivoGrupoPago,
	EInsumoSaludPaisOrigen,
	EInsumoSaludTipoSujeto,
	EMedicamentoAnexoTipo,
	EMedicamentoClaseLGS226,
	EMedicamentoFormaFarmaceutica,
	EMedicamentoFormulaTipo,
	EMedicamentoTipo,
	IAIInsumoSalud,
	IAIInsumoSaludAlertaSanitaria,
	IAIInsumoSaludAutorizador,
	IAIInsumoSaludDocumento,
	IAIInsumoSaludLog,
	IAIInsumoSaludMetadatos,
	IAIInsumoSaludResumenPublico,
	IAIInsumoSaludTramite,
	IAIInsumoSaludVersion,
	IDispositivo
} from 'cofepris-typesafe/Modules/InsumoSalud'
import { IAIInsumoSaludArchivoLegado } from 'cofepris-typesafe/Modules/InsumoSalud/AIInsumoSalud'
import { EInsumoSaludAcuerdoEquivalencia } from 'cofepris-typesafe/Modules/InsumoSalud/InsumoSalud'
import { EMedicamentoFormulaProporcion, EMedicamentoGrupoCoordinacion, EMedicamentoGrupoPago, EMedicamentoTipoAlopatico } from 'cofepris-typesafe/Modules/InsumoSalud/Medicamento'
import { TIPO_NODO } from 'cofepris-typesafe/src/models/INodoRecurso'
import { EHomoclaveRegistryAntecedenteActivoInformacion } from 'cofepris-typesafe/Types/Homoclave'
const baseSubject = {
	nombre: '',
	direccion: '',
	rfc: '',
	paisOrigen: EInsumoSaludPaisOrigen.MEX,
	notaAutorizacion: ''
}
export class ActivoInsumo implements IAIInsumoSalud {
	public _id!: string
	public path!: string
	public parentName!: string
	public parentPath!: string
	public tipo!: TIPO_NODO
	public creation_date!: number
	public modification_date!: number
	public grupo!: string
	public versionInterfaceAI!: string
	public numeroRegistro!: string
	public versionActual!: number
	public rfcSolicitante!: string
	public tipoAI!: EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD
	public tipoOrigen!: EAIInsumoSaludOrigen
	public tipoInsumoSalud!: EAIInsumoSaludTipo
	public tipoAutorizacion!: EAIInsumoSaludTipoAutorizacion
	public estadoAutorizacion!: EAIInsumoSaludEstadoAutorizacion
	public vigenciaInicioAutorizacion?: number
	public vigenciaFinAutorizacion!: number
	public autorizador?: IAIInsumoSaludAutorizador
	public versiones!: IAIInsumoSaludVersion[]
	public alertasSanitarias?: IAIInsumoSaludAlertaSanitaria[]
	public resumenPublico!: IAIInsumoSaludResumenPublico
	public metadatos!: IAIInsumoSaludMetadatos
	public documentos?: IAIInsumoSaludDocumento[]
	public archivosLegados?: IAIInsumoSaludArchivoLegado[]
	public tramites?: IAIInsumoSaludTramite[]
	public prorrogaRetroactiva!: boolean
	public prorrogado!: boolean
	public prorrogaEnEvaluacion!: boolean
	public log!: IAIInsumoSaludLog[]
	public draft!: string
	public motivoRevocacion?: string
	public autoridadRevocacion?: string
	public fechaRevocacion?: number
	public creacionDigital?: boolean
	public numeroOficioHuerfano?: string
	/**
	 *
	 */

	constructor(activo?: IAIInsumoSalud) {
		if (!activo) {
			this.initDefaults()
		} else {
			this.initFromObject(activo)
		}
	}

	private initDefaults() {
		this._id = ''
		this.path = ''
		this.parentName = ''
		this.parentPath = ''
		this.tipo = TIPO_NODO.AI_ENSAYO_CLINICO
		const now = Date.now()
		this.creation_date = now
		this.modification_date = now
		this.grupo = ''
		this.versionInterfaceAI = ''
		this.numeroRegistro = ''
		this.versionActual = 0
		this.rfcSolicitante = ''
		this.tipoAI = EHomoclaveRegistryAntecedenteActivoInformacion.AI_INSUMO_SALUD
		this.tipoOrigen = EAIInsumoSaludOrigen.DIGITAL
		this.tipoInsumoSalud = EAIInsumoSaludTipo.MEDICAMENTO
		this.tipoAutorizacion = EAIInsumoSaludTipoAutorizacion.REGISTRO_SANITARIO
		this.estadoAutorizacion = EAIInsumoSaludEstadoAutorizacion.AUTORIZADO
		this.vigenciaInicioAutorizacion = undefined
		this.vigenciaFinAutorizacion = now
		this.autorizador = {
			nombre: '',
			certificado: '',
			cadenaOriginal: '',
			firma: '',
			versionAI: 0
		}
		this.versiones = []
		this.alertasSanitarias = undefined
		this.resumenPublico = {
			numeroRegistro: '',
			nombreTitular: '',
			tipoAutorizacion: EAIInsumoSaludTipoAutorizacion.REGISTRO_SANITARIO,
			tipoInsumoSalud: EAIInsumoSaludTipo.MEDICAMENTO,
			estadoAutorizacion: EAIInsumoSaludEstadoAutorizacion.AUTORIZADO,
			fechaInicioVigenciaAutorizacion: now,
			fechaFinVigenciaAutorizacion: now,
			denominacionDistintiva: '',
			denominacionGenerica: '',
			presentaciones: [],
			numeroAlertasSanitariasActivas: 0
		}
		this.metadatos = {
			fechaAutorizacionInicial: 0,
			fechaInicioVigenciaAutorizacion: 0,
			fechaFinVigenciaAutorizacion: 0,
			numeroConstanciasCanceladas: 0,
			numeroConstanciasCanceladasPrevioVerificacionHumana: 0,
			fechaLimiteSolicitudProrroga: 0,
			numeroProrrogas: 0
		}
		this.documentos = []
		this.archivosLegados = []
		this.tramites = []
		this.prorrogaRetroactiva = false
		this.prorrogaEnEvaluacion = false
		this.prorrogado = false
		this.log = []
		this.draft = ''
		this.motivoRevocacion = ''
		this.autoridadRevocacion = undefined
		this.fechaRevocacion = undefined
		this.creacionDigital = false
		this.numeroOficioHuerfano = undefined
	}

	private initFromObject(activo: IAIInsumoSalud) {
		this._id = activo._id
		this.path = activo.path
		this.parentName = activo.parentName
		this.parentPath = activo.parentPath
		this.tipo = activo.tipo
		this.creation_date = activo.creation_date
		this.modification_date = activo.modification_date
		this.grupo = activo.grupo
		this.versionInterfaceAI = activo.versionInterfaceAI
		this.numeroRegistro = activo.numeroRegistro
		this.versionActual = activo.versionActual
		this.rfcSolicitante = activo.rfcSolicitante
		this.tipoAI = activo.tipoAI
		this.tipoOrigen = activo.tipoOrigen
		this.tipoInsumoSalud = activo.tipoInsumoSalud
		this.tipoAutorizacion = activo.tipoAutorizacion
		this.estadoAutorizacion = activo.estadoAutorizacion
		this.vigenciaInicioAutorizacion = activo.vigenciaInicioAutorizacion
		this.vigenciaFinAutorizacion = activo.vigenciaFinAutorizacion
		this.autorizador = activo.autorizador
		this.versiones = activo.versiones || []
		this.alertasSanitarias = activo.alertasSanitarias || undefined
		this.resumenPublico = activo.resumenPublico
		this.metadatos = activo.metadatos
		this.documentos = activo.documentos || undefined
		this.archivosLegados = activo.archivosLegados || undefined
		this.tramites = activo.tramites || undefined
		this.documentos = activo.documentos || undefined
		this.archivosLegados = activo.archivosLegados || undefined
		this.tramites = activo.tramites || undefined
		this.prorrogaRetroactiva = activo.prorrogaRetroactiva
		this.prorrogaEnEvaluacion = activo.prorrogaEnEvaluacion
		this.prorrogado = activo.prorrogado
		this.log = activo.log
		this.draft = activo.draft || ''
		this.motivoRevocacion = activo.motivoRevocacion
		this.autoridadRevocacion = activo.autoridadRevocacion
		this.fechaRevocacion = activo.fechaRevocacion
		this.creacionDigital = activo.creacionDigital
		this.numeroOficioHuerfano = activo.numeroOficioHuerfano
	}

	generateVersion(tipoInsumo?: EAIInsumoSaludTipo) {
		const baseVersion = {
			version: 1,
			fechaVersion: Date.now(),
			insumoSalud: {
				denominacionDistintiva: '',
				denominacionGenerica: '',
				titular: { ...baseSubject, tipo: EInsumoSaludTipoSujeto.TITULAR }
			}
		}
		if (tipoInsumo) {
			this.tipoInsumoSalud = tipoInsumo
		}

		const newVersion: IAIInsumoSaludVersion = {
			...baseVersion,
			insumoSalud: {
				...baseVersion.insumoSalud,
				[tipoInsumo === EAIInsumoSaludTipo.MEDICAMENTO ? 'medicamento' : 'dispositivo']:
					tipoInsumo === EAIInsumoSaludTipo.MEDICAMENTO
						? {
								tipo: EMedicamentoTipo.NO_SELECCIONADO,
								grupoCoordinacion: EMedicamentoGrupoCoordinacion.NO_SELECCIONADO,
								grupoPago: EMedicamentoGrupoPago.NO_SELECCIONADO,
								estupefacientePsicotropicoPrecursor: false,
								deReferencia: false,
								claseMedicamentoLGS226: EMedicamentoClaseLGS226.NO_SELECCIONADO
						  }
						: {
								claseDispositivoRIS83: EDispositivoClaseRIS83.NO_SELECCIONADO,
								categoriaDispositivoLGS262: EDispositivoCategoriaLGS262.NO_SELECCIONADO,
								grupoPago: EDispositivoGrupoPago.NO_SELECCIONADO,
								indicacionesUso: '',
								esDispositivoInvitro: false
						  }
			}
		}

		this.versiones.push(newVersion)
	}

	obtenerUltimaVersion() {
		if (this.versionActual === 0) {
			return this.versiones[0]
		}
		const versionActual = this.versiones.find(v => v.version === this.versionActual)
		return versionActual ? versionActual : this.versiones[0]
	}

	obtenerVersioPorIndice(ai: IAIInsumoSalud | undefined, index: number): IAIInsumoSaludVersion | undefined {
		let version = undefined
		if (ai == undefined) {
			return undefined
		}
		if (index >= 0) {
			version = ai?.versiones[index] || undefined
		} else {
			throw new Error('El activo no cuenta con versiones')
		}

		return version
	}

	agregarRepresentante(version: IAIInsumoSaludVersion) {
		version.insumoSalud.representante = {
			nombre: '',
			tipo: EInsumoSaludTipoSujeto.REPRESENTANTE,
			rfc: '',
			direccion: '',
			paisOrigen: EInsumoSaludPaisOrigen.MEX,
			notaAutorizacion: ''
		}
	}

	agregarMedicamento(version: IAIInsumoSaludVersion) {
		version.insumoSalud.medicamento = {
			tipo: EMedicamentoTipo.FARMOQUIMICO,
			tipoAlopatico: EMedicamentoTipoAlopatico.NO_SELECCIONADO,
			grupoPago: EMedicamentoGrupoPago.GENERICO_BIOCOMPARABLE,
			grupoCoordinacion: EMedicamentoGrupoCoordinacion.FQ,
			estupefacientePsicotropicoPrecursor: false,
			deReferencia: false,
			claseMedicamentoLGS226: EMedicamentoClaseLGS226.I
		}
	}
	agregarDispositivo(version: IAIInsumoSaludVersion) {
		version.insumoSalud.dispositivo = {
			categoriaDispositivoLGS262: EDispositivoCategoriaLGS262.III_AGENTES_DIAGNOSTICO,
			claseDispositivoRIS83: EDispositivoClaseRIS83.I,
			grupoPago: EDispositivoGrupoPago.DISPOSITIVO_PAGO_CLASE_I
		}
	}
	agregarObservaciones = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.observacionesAutorizacion = ['']
	}

	agregarFundamentoLegal = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.fundamentoLegal = ''
	}

	agregarAcuerdoEquivalencia = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.acuerdoEquivalencia = EInsumoSaludAcuerdoEquivalencia.FDA
	}

	/**
	 * @name agregarDescripcion
	 * @description Agrega una descripción al medicamento en caso de que no exista
	 */
	agregarDescripcion = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.descripcion = ''
	}
	eliminarDescripcionMedicamento = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.descripcion = undefined
	}
	/**
	 * @name agregarFormaFarmaceutica
	 * @description Agrega una forma farmaceutica al medicamento en caso de que no exista
	 */
	agregarFormaFarmaceutica = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.formaFarmaceutica = EMedicamentoFormaFarmaceutica.AEROSOL
	}
	eliminarFormaFarmaceutica = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.formaFarmaceutica = undefined
	}
	/**
	 * @name agregarViaAdministracion
	 * @description Agrega una via de administración al medicamento en caso de que no exista
	 */
	agregarViaAdministracion = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.viasAdministracion = []
	}
	/**
	 * @name agregarBiologico
	 * @description Agrega un biologico al medicamento en caso de que no exista
	 */
	agregarBiologico = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.biologico = {
			organismoVivoOrigen: ''
		}
	}
	eliminarBiologico = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.biologico = undefined
	}
	/**
	 * @name agregarBiotecnologico
	 * @description Agrega un biotecnologico al medicamento en caso de que no exista
	 */
	agregarBiotecnologico = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.biotecnologico = {
			origenBiofarmaco: ''
		}
	}
	eliminarBiotecnologico = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.biotecnologico = undefined
	}
	/**
	 * @name agregarConsideraciones
	 * @description Agrega consideraciones de uso al medicamento en caso de que no existan
	 */
	agregarConsideraciones = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.consideracionesUsos = []
	}
	/**
	 * @name eliminarConsideraciones
	 * @description Agrega consideraciones de uso al medicamento en caso de que no existan
	 */
	eliminarConsideraciones = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.consideracionesUsos = undefined
	}
	/**
	 * @name eliminarViaAdministracion
	 * @description Agrega vía de administración al medicamento en caso de que no exista
	 */
	eliminarViaAdministracion = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.viasAdministracion = undefined
	}
	/**
	 * @name agregarIndicaciones
	 * @description Agrega indicaciones terapeuticas al medicamento en caso de que no existan
	 */
	agregarIndicaciones = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.indicacionesTerapeuticas = ['']
	}
	/**
	 * @name eliminarIndicaciones
	 * @description Agrega indicaciones terapeuticas al medicamento en caso de que no existan
	 */
	eliminarIndicaciones = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.indicacionesTerapeuticas = undefined
	}
	/**
	 * @name agregarcontraIndicaciones
	 * @description Agrega contraindicaciones al medicamento en caso de que no existan
	 */
	agregarcontraIndicaciones = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.contraIndicaciones = ['']
	}
	/**
	 * @name eliminarcontraIndicaciones
	 * @description Agrega contraindicaciones al medicamento en caso de que no existan
	 */
	eliminarcontraIndicaciones = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.contraIndicaciones = undefined
	}
	/**
	 * @name agregarUnidades
	 * @description Agrega unidad de farmacovigilancia al medicamento en caso de que no existan
	 */
	agregarUnidades = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.unidadFarmacovigilancia = {
			nombre: '',
			direccion: '',
			rfc: '',
			tipo: EInsumoSaludTipoSujeto.UNIDAD_FARMACOVIGILANCIA,
			paisOrigen: EInsumoSaludPaisOrigen.MEX,
			notaAutorizacion: ''
		}
	}
	/**
	 * @name eliminarUnidades
	 * @description Agrega unidad de farmacovigilancia al medicamento en caso de que no existan
	 */
	eliminarUnidades = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.unidadFarmacovigilancia = undefined
	}
	/**
	 * @name agregarcontraVidaUtil
	 * @description Agrega vida util al medicamento en caso de que no exista
	 */
	agregarVidaUtil = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.vidaUtil = ['']
	}
	/**
	 * @name agregarcontraVidaUtil
	 * @description Agrega vida util al medicamento en caso de que no exista
	 */
	eliminarVidaUtil = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.vidaUtil = undefined
	}
	/**
	 * @name agregarInformeTecnico
	 * @description Agrega informe técnico al medicamento en caso de que no exista
	 */
	agregarInformeTecnico = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.informeTecnico = []
	}
	/**
	 * @name eliminarInformeTecnico
	 * @description Agrega informe técnico al medicamento en caso de que no exista
	 */
	eliminarInformeTecnico = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.informeTecnico = undefined
	}
	/**
	 * @name agregarcontraFarmacos
	 * @description Agrega farmacos al medicamento en caso de que no existan
	 */
	agregarFarmacos = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.farmacos = [
			{
				farmaco: '',
				fabricantes: [
					{
						nombre: '',
						direccion: '',
						rfc: '',
						tipo: EInsumoSaludTipoSujeto.FABRICANTE,
						paisOrigen: EInsumoSaludPaisOrigen.MEX,
						notaAutorizacion: ''
					}
				]
			}
		]
	}
	/**
	 * @name eliminarcontraFarmacos
	 * @description Agrega farmacos al medicamento en caso de que no existan
	 */
	eliminarFarmacos = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.farmacos = undefined
	}
	/**
	 * @name agregarDiluyente
	 * @description Agrega diluyentes al medicamento en caso de que no existan
	 */
	agregarDiluyente = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.diluyentes = [
			{
				diluyente: '',
				fabricantes: [
					{
						nombre: '',
						direccion: '',
						rfc: '',
						tipo: EInsumoSaludTipoSujeto.FABRICANTE,
						paisOrigen: EInsumoSaludPaisOrigen.MEX,
						notaAutorizacion: ''
					}
				]
			}
		]
	}
	/**
	 * @name eliminarDiluyente
	 * @description Agrega diluyentes al medicamento en caso de que no existan
	 */
	eliminarDiluyente = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.diluyentes = undefined
	}
	/**
	 * @name agregarAdyuvantes
	 * @description Agrega adyuvantes al medicamento en caso de que no existan
	 */
	agregarAdyuvantes = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.adyuvantes = [
			{
				adyuvante: '',
				fabricantes: [
					{
						nombre: '',
						direccion: '',
						rfc: '',
						tipo: EInsumoSaludTipoSujeto.FABRICANTE,
						paisOrigen: EInsumoSaludPaisOrigen.MEX,
						notaAutorizacion: ''
					}
				]
			}
		]
	}
	/**
	 * @name eliminarAdyuvantes
	 * @description Agrega adyuvantes al medicamento en caso de que no existan
	 */
	eliminarAdyuvantes = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.adyuvantes = undefined
	}
	/**
	 * @name agregarPresaentaciones
	 * @description Agrega presentaciones al medicamento en caso de que no existan
	 */
	agregarPresentacionesMedicamento = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.presentaciones = [
			{
				resumenPresentacion: '',
				claveCNIS: ''
			}
		]
	}
	eliminarPresentacionesMedicamento = (version: IAIInsumoSaludVersion) => {
		if (version.insumoSalud.medicamento!.presentaciones!.length > 0) {
			version.insumoSalud.medicamento!.presentaciones!.pop()
		}
	}
	eliminarAllPresentacionesMedicamento = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.presentaciones = undefined
	}
	/**
	 * @name agregarcadenaProduccionDistribucion
	 * @description Agrega cadena de producción y distribución al medicamento en caso de que no exista
	 */
	agregarcadenaProduccionDistribucion = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.cadenaProduccionDistribucion = {
			fabricantes: [
				{
					nombre: '',
					direccion: '',
					rfc: '',
					tipo: EInsumoSaludTipoSujeto.FABRICANTE,
					paisOrigen: EInsumoSaludPaisOrigen.MEX,
					notaAutorizacion: ''
				}
			]
		}
	}
	/**
	 * @name eliminarcadenaProduccionDistribucion
	 * @description Agrega cadena de producción y distribución al medicamento en caso de que no exista
	 */
	eliminarcadenaProduccionDistribucion = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.cadenaProduccionDistribucion = undefined
	}
	/**
	 * @name agregarcadenaFormulas
	 * @description Agrega formulas al medicamento en caso de que no existan
	 */
	agregarFormulas = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.formulas = [
			{
				tipo: EMedicamentoFormulaTipo.DILUYENTE,
				etiqueta: '',
				proporcion: EMedicamentoFormulaProporcion.CADA_100_G_CONTIENEN,
				farmacos: [
					{
						elemento: '',
						cantidad: '',
						unidad: ''
					}
				]
			}
		]
	}
	/**
	 * @name eliminarFormulas
	 * @description Agrega formulas al medicamento en caso de que no existan
	 */
	eliminarFormulas = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.formulas = undefined
	}

	agregarAnexos = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.anexos = [
			{
				tipo: EMedicamentoAnexoTipo.ETIQUETAS,
				uuid: '',
				documento: []
			}
		]
	}
	eliminarAnexos = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.anexos = undefined
	}
	/**
	 * @name agregarPatentes
	 * @description Agrega patentes al medicamento en caso de que no existan
	 */
	agregarPatentes = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.patentes = [
			{
				numeroOficioRespuestaIMPI: '',
				documentoConsulta: [],
				documentoRespuesta: []
			}
		]
	}
	/**
	 * @name eliminarPatentes
	 * @description Agrega patentes al medicamento en caso de que no existan
	 */
	eliminarPatentes = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.patentes = undefined
	}
	/**
	 * @name agregarNotas
	 * @description Agrega notas al medicamento en caso de que no existan
	 */
	agregarNotas = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.notas = ['']
	}
	/**
	 * @name eliminarNotas
	 * @description Agrega notas al medicamento en caso de que no existan
	 */
	eliminarNotas = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.notas = undefined
	}
	agregarimportadores = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.dispositivo!.producto!.importadores = [
			{
				...baseSubject,
				tipo: EInsumoSaludTipoSujeto.IMPORTADOR
			}
		]
	}
	agregarDistribuidores = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.dispositivo!.producto!.distribuidores = [
			{
				...baseSubject,
				tipo: EInsumoSaludTipoSujeto.DISTRIBUIDOR
			}
		]
	}

	agregaraMaquiladores = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.dispositivo!.producto!.maquiladores = [
			{
				...baseSubject,
				tipo: EInsumoSaludTipoSujeto.MAQUILADOR
			}
		]
	}
	agregaralmacenes = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.dispositivo!.producto!.almacenes = [
			{
				...baseSubject,
				tipo: EInsumoSaludTipoSujeto.ALMACEN
			}
		]
	}
	agregarDescripcionProducto = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.dispositivo!.producto!.descripcion = ''
	}

	agregarSubject = (tipo: EInsumoSaludTipoSujeto, version: IAIInsumoSaludVersion) => {
		switch (tipo) {
			case EInsumoSaludTipoSujeto.FABRICANTE:
				version.insumoSalud.dispositivo!.producto!.fabricantes.push({
					...baseSubject,
					tipo: EInsumoSaludTipoSujeto.FABRICANTE
				})
				break
			case EInsumoSaludTipoSujeto.IMPORTADOR:
				version.insumoSalud.dispositivo!.producto!.importadores!.push({
					...baseSubject,
					tipo: EInsumoSaludTipoSujeto.IMPORTADOR
				})
				break
			case EInsumoSaludTipoSujeto.DISTRIBUIDOR:
				version.insumoSalud.dispositivo!.producto!.distribuidores!.push({
					...baseSubject,
					tipo: EInsumoSaludTipoSujeto.DISTRIBUIDOR
				})
				break
			case EInsumoSaludTipoSujeto.ALMACEN:
				version.insumoSalud.dispositivo!.producto!.almacenes!.push({
					...baseSubject,
					tipo: EInsumoSaludTipoSujeto.ALMACEN
				})
				break
			case EInsumoSaludTipoSujeto.MAQUILADOR:
				version.insumoSalud.dispositivo!.producto!.maquiladores!.push({
					...baseSubject,
					tipo: EInsumoSaludTipoSujeto.MAQUILADOR
				})
				break
			default:
				break
		}
	}

	eliminarSubject = (tipo: EInsumoSaludTipoSujeto, version: IAIInsumoSaludVersion) => {
		const removerUltimoElemento = (items?: any[]) => {
			if (items && items.length > 0) {
				items.pop()
			}
		}
		switch (tipo) {
			case EInsumoSaludTipoSujeto.FABRICANTE:
				removerUltimoElemento(version.insumoSalud.dispositivo!.producto!.fabricantes)
				break
			case EInsumoSaludTipoSujeto.IMPORTADOR:
				removerUltimoElemento(version.insumoSalud.dispositivo!.producto!.importadores)
				break
			case EInsumoSaludTipoSujeto.DISTRIBUIDOR:
				removerUltimoElemento(version.insumoSalud.dispositivo!.producto!.distribuidores)
				break
			case EInsumoSaludTipoSujeto.ALMACEN:
				removerUltimoElemento(version.insumoSalud.dispositivo!.producto!.almacenes)
				break
			case EInsumoSaludTipoSujeto.MAQUILADOR:
				removerUltimoElemento(version.insumoSalud.dispositivo!.producto!.maquiladores)
				break
			default:
				break
		}
	}
	agregarCaducidad = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.dispositivo!.producto!.caducidad = ''
	}
	agregarPublicidad = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.dispositivo!.producto!.publicidadDirigida = ''
	}
	agregarNotasProducto = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.dispositivo!.producto!.notas = ['']
	}
	eliminarNotasProducto = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.dispositivo!.producto!.notas = undefined
	}

	/**
	 * PRESENTACIONES DISPOSITIVO
	 */
	agregarPresentacionesDispositivos = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.dispositivo!.presentaciones = [
			{
				tipo: '',
				presentacion: [
					{
						identificador: '',
						descripcion: '',
						claveCNIS: ''
					}
				]
			}
		]
	}
	agregarUnaPresentacionDispositivo = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.dispositivo!.presentaciones!.push({
			tipo: '',
			presentacion: [
				{
					identificador: '',
					descripcion: '',
					claveCNIS: ''
				}
			]
		})
	}
	agregarSubPresentacionDispositivo = (version: IAIInsumoSaludVersion, index: number) => {
		version.insumoSalud.dispositivo!.presentaciones![index].presentacion.push({
			identificador: '',
			descripcion: '',
			claveCNIS: ''
		})
	}
	eliminarPresentacionesDispositivos = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.dispositivo!.presentaciones = undefined
	}
	eliminarPresentacionesDispositivosByIndex = (version: IAIInsumoSaludVersion, index: number) => {
		if (version.insumoSalud.dispositivo!.presentaciones!) {
			version.insumoSalud.dispositivo!.presentaciones.splice(index, 1)
		}
	}
	eliminarSubPresentacionesDispositivosByIndex = (version: IAIInsumoSaludVersion, index: number, indexItem: number) => {
		if (version.insumoSalud.dispositivo!.presentaciones![index].presentacion.length > 0) {
			version.insumoSalud.dispositivo!.presentaciones![index].presentacion.splice(indexItem, 1)
		}
	}
	agregarFormulaDispositivo = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.dispositivo!.formulas!.push({
			nombre: '',
			expresionFormula: ''
		})
	}
	eliminarFormulaDispositivo = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.dispositivo!.formulas = undefined
	}
	eliminarFormulaDispositivoByIndex = (version: IAIInsumoSaludVersion, index: number) => {
		if (version.insumoSalud.dispositivo!.formulas!.length > 0) {
			version.insumoSalud.dispositivo!.formulas!.splice(index, 1)
		}
	}
	agregarAlerta = (insumo: ActivoInsumo) => {
		if (insumo.alertasSanitarias) {
			insumo.alertasSanitarias.push({
				idAlertaSanitaria: '',
				nivelAlertaSanitaria: EAIInsumoSaludAlertaSanitariaNivel.ADVERTENCIA,
				estadoAlertaSanitaria: EAIInsumoSaludAlertaSanitariaEstado.ACTIVA,
				urlAlertaSanitaria: '',
				documentoAlertaSanitaria: []
			})
		} else {
			insumo.alertasSanitarias = [
				{
					idAlertaSanitaria: '',
					nivelAlertaSanitaria: EAIInsumoSaludAlertaSanitariaNivel.ADVERTENCIA,
					estadoAlertaSanitaria: EAIInsumoSaludAlertaSanitariaEstado.ACTIVA,
					urlAlertaSanitaria: '',
					documentoAlertaSanitaria: []
				}
			]
		}
	}
	eliminarAlerta = (insumo: ActivoInsumo, index: number) => {
		if (insumo.alertasSanitarias!) {
			if (insumo.alertasSanitarias.length > 0) {
				insumo.alertasSanitarias.splice(index, 1)
			}
		}
	}
	addFormulas = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.dispositivo!.formulas = [{ nombre: '', expresionFormula: '' }]
	}
	addProducto = (dispositivo: IDispositivo) => {
		dispositivo.producto = {
			fabricantes: [
				{
					nombre: '',
					direccion: '',
					rfc: '',
					tipo: EInsumoSaludTipoSujeto.FABRICANTE,
					paisOrigen: EInsumoSaludPaisOrigen.MEX,
					notaAutorizacion: ''
				}
			]
		}
	}
	eliminarProducto = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.dispositivo!.producto = undefined
	}

	agregarFormulaMedicamento = (version: IAIInsumoSaludVersion) => {
		version.insumoSalud.medicamento!.formulas!.push({
			tipo: EMedicamentoFormulaTipo.DILUYENTE,
			farmacos: [
				{
					elemento: '',
					cantidad: '',
					unidad: ''
				}
			],
			aditivos: [
				{
					elemento: '',
					cantidad: '',
					unidad: ''
				}
			]
		})
	}
	eliminarFormulaMedicamento = (version: IAIInsumoSaludVersion, index: number) => {
		if (version.insumoSalud.medicamento!.formulas!.length > 0) {
			version.insumoSalud.medicamento!.formulas!.splice(index, 1)
		}
	}

	exportarComoIAIInsumoSalud = (): IAIInsumoSalud =>
		JSON.parse(
			JSON.stringify({
				_id: this._id,
				path: this.path,
				parentName: this.parentName,
				parentPath: this.parentPath,
				tipo: this.tipo,
				creation_date: this.creation_date,
				modification_date: this.modification_date,
				grupo: this.grupo,
				versionInterfaceAI: this.versionInterfaceAI,
				numeroRegistro: this.numeroRegistro,
				versionActual: this.versionActual,
				rfcSolicitante: this.rfcSolicitante,
				tipoAI: this.tipoAI,
				tipoOrigen: this.tipoOrigen,
				tipoInsumoSalud: this.tipoInsumoSalud,
				tipoAutorizacion: this.tipoAutorizacion,
				estadoAutorizacion: this.estadoAutorizacion,
				vigenciaInicioAutorizacion: this.vigenciaInicioAutorizacion!,
				vigenciaFinAutorizacion: this.vigenciaFinAutorizacion,
				autorizador: this.autorizador,
				versiones: this.versiones,
				alertasSanitarias: this.alertasSanitarias,
				resumenPublico: this.resumenPublico,
				metadatos: this.metadatos,
				documentos: this.documentos,
				tramites: this.tramites,
				log: this.log
			})
		)
}
