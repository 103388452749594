/**
 * @module FechasAdministrativas
 * @author @tirsomartinezreyes
 * @version 1.0.2
 * @description Módulo para el cálculo de fechas administrativas tomando en consideración los días naturales y hábiles
 *
 * @changelog 1.0.2 - 19/feb/24 - @tirsomartinezreyes - Se agrega exportación de diccionario de Operaciones y se agrega el caso de sumar un día hàbil
 * @changelog 1.0.1 - 25/oct/24 - @tirsomartinezreyes - Ajuste por refactorización de getTipoUnidadesPorOperacion
 * @changelog 1.0.0 - 14/oct/24 - @tirsomartinezreyes - Se integran las funciones de cálculo de fechas administrativas validadas
 * @changelog 0.0.1 - 22/09/24 - Primera versión del módulo
 *
 * @businessLogic
 * 1.- Un día habil es un día que no se encuentra en la lista de días no hábiles
 * 2.- Un día natural es un dia normal, sin importar si es hábil o no
 * 3.- El inicio de un día hábil es a las 09:00 horas (Esto no es relevante para el cómputo de los días)
 * 4.- La hora limite para un trámite es las 6:00 pm del día límite (Esto no es relevante para el cómputo de los días)
 * 5.- No importa si el cómputo es con días hábiles o no hábiles, el inicio del cómputo se dá siempre en el siguiente día hábil a la fecha de entrada el trámite
 * 5.- No importa si el cómputo es con días hábiles o no hábiles, el resultado del cómputo se dá siempre en el siguiente día hábil a la fecha aritmética de días habiles
 * 6.- La zona horaria es la de la CDMX con un offset de -6 horas con UTC
 * 7.- Es de interés conocer las fechas no hábiles contempladas en el cómputo desde la fecha de inicio hasta la fecha de resultado
 */

import { getDateByMillis } from 'cofepris-typesafe/Modules/Dates'

const CFechaAdministrativaDiaNatural = 1000 * 60 * 60 * 24
const CFechaAdministrativaInicioHoraHabil = 1000 * 60 * 60 * 9 // 09:00 de la Ciudad de México
const CFechaAdministrativaFinHoraHabil = 1000 * 60 * 60 * 18 // 18:00 de la Ciudad de México
export const CFechaAdministrativaOffsetGMTCiudadDeMéxico = 6 * 60 * 60 * 1000 // 6 horas de diferencia con UTC

export enum EFechaAdministrativaOperacion {
	SUMAR_DIAS_HABILES = 'SUMAR_DIAS_HABILES',
	SUMAR_DIAS_NATURALES = 'SUMAR_DIAS_NATURALES',
	RESTAR_DIAS_NATURALES = 'RESTAR_DIAS_NATURALES',
	SUMAR_ANIOS_CALENDARIO = 'SUMAR_ANIOS_CALENDARIO'
}

export const CFechaAdministrativaOperacionDiccionario: { [key in EFechaAdministrativaOperacion]: string } = {
	SUMAR_DIAS_HABILES: 'SUMAR DíAS HÁBILES',
	SUMAR_DIAS_NATURALES: 'SUMAR DÍAS NATURALES',
	RESTAR_DIAS_NATURALES: 'RESTAR DÍAS NATURALES',
	SUMAR_ANIOS_CALENDARIO: 'SUMAR AÑOS CALENDARIO'
}

interface IFechaAdministrativaRespuestaFechaConDiasNoHabilesContemplados {
	fecha: number
	diasNoHabilesContemplados: number[]
	razonesComputoDias: string[]
}

interface IFechaAdministrativaBase {
	fechaEntrada: number //Fecha proporcionada como el momento de ingreos del trámite para el cálculo de los días
	operacion: EFechaAdministrativaOperacion //Operación a realizar
	cantidad: number //Días o años a sumar, depende del tipo de operación
}

export interface IFechaAdministrativaSolicitud extends IFechaAdministrativaBase {
	diasNoHabiles: string | number[] //Lista de días no hábiles en formato de cadena separada por comas o arreglo de timestamps en UTC
}

export interface IFechaAdministrativaRespuesta extends IFechaAdministrativaBase {
	fechaInicio: number //Fecha de inicio del cálculo, siempre es un día hábil
	fechaResultado: number //Fecha de resultado del cálculo, dependera de la operación
	fechaEntradaTexto: string //Fecha de entrada en formato de cadena
	fechaInicioTexto: string //Fecha de inicio en formato de cadena
	fechaResultadoTexto: string //Fecha de resultado en formato de cadena
	diasNoHabilesContemplados: number[] //Lista de días no hábiles contemplados en el cálculo
	razonesComputoDias?: string[] //Razones por las que se consideraron días no hábiles
}

export const calcularFechaAdministrativa = (solicitud: IFechaAdministrativaSolicitud): IFechaAdministrativaRespuesta => {
	const _fechaEntrada = solicitud.fechaEntrada
	const diasNoHabiles: number[] = typeof solicitud.diasNoHabiles === 'string' ? convertirCadenaFechasEnArray(solicitud.diasNoHabiles) : solicitud.diasNoHabiles
	const _tmpFechaInicio = obtenerFechaInicioComputo(solicitud.operacion, solicitud.fechaEntrada, diasNoHabiles)
	const _fechaInicio = _tmpFechaInicio.fecha
	let _fechaResultado = solicitud.fechaEntrada
	const _diasNoHabilesContemplados: number[] = [..._tmpFechaInicio.diasNoHabilesContemplados]
	const _razonesComputoDias: string[] = [
		`La fecha ingresada es ${getDateByMillis(_fechaEntrada, true, true)}`, //
		..._tmpFechaInicio.razonesComputoDias, //
		`La operación requerida es  ${getTipoAritmeticaPorOperacion(solicitud.operacion)} ${solicitud.cantidad} ${getTipoUnidadesPorOperacion(solicitud.operacion, solicitud.cantidad)}` //
	]

	//SUMAR DIAS NATURALES
	if (solicitud.operacion == EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES) {
		_fechaResultado = sumarDiasNaturales(_fechaInicio, solicitud.cantidad)
	}

	//DIAS HÁBILES
	if (solicitud.operacion == EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES) {
		const diasHabiles: number[] = []
		let counter = 0

		let diaEvaluar = _fechaInicio
		if (solicitud.cantidad == 1) {
			if (esDiaHabil(diaEvaluar, diasNoHabiles)) {
				diasHabiles.push(diaEvaluar)
			} else {
				_diasNoHabilesContemplados.push(diaEvaluar)
				_razonesComputoDias.push(`${getDateByMillis(diaEvaluar, true, true)} es día no hábil`)
			}
		} else {
			while (diasHabiles.length < solicitud.cantidad - 1) {
				diaEvaluar += CFechaAdministrativaDiaNatural

				if (esDiaHabil(diaEvaluar, diasNoHabiles)) {
					diasHabiles.push(diaEvaluar)
				} else {
					_diasNoHabilesContemplados.push(diaEvaluar)
					_razonesComputoDias.push(`${getDateByMillis(diaEvaluar, true, true)} es día no hábil`)
				}
				counter++
				//evitar loop infinito
				if (counter > 10000) {
					throw new Error('Se ha detectado un loop infinito en el cálculo de días hábiles' + JSON.stringify(solicitud))
				}
			}
		}
		_fechaResultado = normalizarFecha(diasHabiles[diasHabiles.length - 1]) + CFechaAdministrativaFinHoraHabil
	}

	//RESTAR DIAS NATURALES
	if (solicitud.operacion == EFechaAdministrativaOperacion.RESTAR_DIAS_NATURALES) {
		_fechaResultado = restarDiasNaturales(_fechaInicio, solicitud.cantidad)
	}

	//SUMAR_ANIOS_CALENDARIO
	if (solicitud.operacion == EFechaAdministrativaOperacion.SUMAR_ANIOS_CALENDARIO) {
		const sumaAniosCalendario: [number, string[]] = sumarAniosCalendario(_fechaInicio, solicitud.cantidad)
		_fechaResultado = sumaAniosCalendario[0]
		_razonesComputoDias.push(...sumaAniosCalendario[1])
	}

	//AJUSTE DE FECHA DE RESULTADO A UN DÍA HáBIL
	//Si la fecha de resultado no es un día hábil, se debe buscar el siguiente día hábil + se contempla la hora final
	if (esDiaHabil(_fechaResultado, diasNoHabiles)) {
		_fechaResultado = normalizarFecha(_fechaResultado) + CFechaAdministrativaFinHoraHabil
		_razonesComputoDias.push(`La fecha resultante de la operación es ${getDateByMillis(_fechaResultado, true, true)}`)
	} else {
		_razonesComputoDias.push(`La fecha resultante de la operación es ${getDateByMillis(_fechaResultado, true, true)}, pero no es un día hábil`)
		const _siguienteDiaHabil = siguienteDiaHabil(_fechaResultado, diasNoHabiles)
		const diasNoHabilesEntreFechas = diasNoHabilesEntreDosFechas(_fechaResultado, _siguienteDiaHabil.fecha, diasNoHabiles)
		_fechaResultado = normalizarFecha(siguienteDiaHabil(_fechaResultado, diasNoHabiles).fecha) + CFechaAdministrativaFinHoraHabil
		_diasNoHabilesContemplados.push(...diasNoHabilesEntreFechas)
		_razonesComputoDias.push(..._siguienteDiaHabil.razonesComputoDias)
	}

	//RESPUESTA
	const respuesta: IFechaAdministrativaRespuesta = {
		fechaEntrada: _fechaEntrada,
		operacion: solicitud.operacion,
		cantidad: solicitud.cantidad,
		fechaInicio: _fechaInicio,
		fechaResultado: _fechaResultado,
		fechaEntradaTexto: getDateByMillis(_fechaEntrada),
		fechaInicioTexto: getDateByMillis(_fechaInicio),
		fechaResultadoTexto: getDateByMillis(_fechaResultado),
		diasNoHabilesContemplados: _diasNoHabilesContemplados,
		razonesComputoDias: _razonesComputoDias
	}

	return respuesta
}

const sumarDiasNaturales = (fechaInicio: number, numeroDias: number): number => {
	let respuesta = 0
	respuesta = fechaInicio + CFechaAdministrativaDiaNatural * (numeroDias - 1)
	respuesta = normalizarFecha(respuesta) + CFechaAdministrativaFinHoraHabil
	return respuesta
}

const restarDiasNaturales = (fechaInicio: number, numeroDias: number): number => {
	let respuesta = 0
	respuesta = fechaInicio - CFechaAdministrativaDiaNatural * (numeroDias - 1)
	respuesta = normalizarFecha(respuesta) + CFechaAdministrativaFinHoraHabil
	return respuesta
}

const sumarAniosCalendario = (fechaInicio: number, numeroAnios: number): [number, string[]] => {
	let fechaBase = new Date(fechaInicio)
	const respuesta: [number, string[]] = [0, []]
	if (fechaBase.getMonth() == 1 && fechaBase.getDate() == 29) {
		//29 de Febrero
		respuesta[1].push(`Al ser el 29 de febrero la fecha de inicio de cómputo, se ajusta la fecha al 1 de Marzo`)
		fechaBase = new Date(fechaInicio + CFechaAdministrativaDiaNatural)
	}
	fechaBase.setFullYear(fechaBase.getFullYear() + numeroAnios)
	respuesta[0] = normalizarFecha(fechaBase.getTime()) + CFechaAdministrativaFinHoraHabil
	return respuesta
}

const obtenerFechaInicioComputo = (operacion: EFechaAdministrativaOperacion, fechaEntrada: number, diasNoHabiles: number[]): IFechaAdministrativaRespuestaFechaConDiasNoHabilesContemplados => {
	if ([EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES, EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES].includes(operacion)) {
		return siguienteDiaHabil(fechaEntrada, diasNoHabiles)
	} else if ([EFechaAdministrativaOperacion.RESTAR_DIAS_NATURALES].includes(operacion)) {
		return diaAnteriorNatural(fechaEntrada)
	} else {
		return {
			fecha: fechaEntrada,
			diasNoHabilesContemplados: [],
			razonesComputoDias: []
		}
	}
}
const anteriorDiaNatural = (fecha: number): number => normalizarFecha(fecha) - CFechaAdministrativaDiaNatural

const convertirCadenaFechasEnArray = (entrada: string | number[]): number[] => {
	if (entrada instanceof Array) {
		return entrada
	}
	const fechas = entrada.split(',').map(f => parseInt(f))
	return fechas
}

export const normalizarFecha = (fecha: number): number => {
	const fechaBase = new Date(fecha)
	fechaBase.setHours(0, 0, 0, 0)
	return fechaBase.getTime()
}

export const esDiaHabil = (fecha: number, diasNoHabiles: number[]): boolean => {
	let respuesta: boolean = true
	const fechaNormalizada = normalizarFecha(fecha)

	if (diasNoHabiles.includes(fechaNormalizada)) {
		respuesta = false
	}

	if (esSabadoDomingo(fechaNormalizada)) {
		respuesta = false
	}

	if (esDiaFestivo(fechaNormalizada)) {
		respuesta = false
	}
	return respuesta
}

const esSabadoDomingo = (fecha: number): boolean => {
	const now = new Date(fecha)
	const diaDeLaSemana = now.getDay()
	return diaDeLaSemana === 0 || diaDeLaSemana === 6
}

/**
 * @name esDiaFestivo
 * @description Función que regresa si una fecha es un día festivo en México basado en la ley federal del trabajo, art. 74 (excepto la fecha correspondiente a la suceción del ejecutivo federal, por inconsistencias en el art. 83 de la constitución 2024)
 * @reference https://www.gob.mx/cms/uploads/attachment/file/156203/1044_Ley_Federal_del_Trabajo.pdf
 */
const esDiaFestivo = (fecha: number): boolean => {
	let respuesta: boolean = false
	const now = new Date(fecha)
	const diaDelMes = now.getDate()
	const diaDeLaSemana = now.getDay()
	const mes = now.getMonth()

	const LUNES = 1

	const ENERO = 0
	const FEBRERO = 1
	const MARZO = 2
	const MAYO = 4
	const SEPTIEMBRE = 8
	const NOVIEMBRE = 10
	const DICIEMBRE = 11

	if (diaDelMes == 1 && mes == ENERO) {
		//Año Nuevo
		respuesta = true
	}

	if (diaDeLaSemana == LUNES && mes == FEBRERO && diaDelMes <= 7) {
		//Festejo del día de la Constitución el primer lunes de febrero
		respuesta = true
	}

	if (diaDeLaSemana == LUNES && mes == MARZO && diaDelMes >= 15 && diaDelMes <= 21) {
		//Natalicio de Benito Juárez,  el tercer lunes de marzo
		respuesta = true
	}

	if (diaDelMes == 1 && mes == MAYO) {
		//Día del Trabajo
		respuesta = true
	}

	if (diaDelMes == 16 && mes == SEPTIEMBRE) {
		//Día de la independencia
		respuesta = true
	}

	if (diaDeLaSemana == LUNES && mes == NOVIEMBRE && diaDelMes >= 15 && diaDelMes <= 21) {
		//Aniversario la revolución mexicana,  el tercer lunes de noviembre
		respuesta = true
	}

	if (diaDelMes == 25 && mes == DICIEMBRE) {
		//Navidad
		respuesta = true
	}
	return respuesta
}

/**
 * @name diaAnteriorNatural
 * @description Función que regresa el día anterior natural a una fecha
 */
const diaAnteriorNatural = (fecha: number): IFechaAdministrativaRespuestaFechaConDiasNoHabilesContemplados => {
	const diaAnteriorNatural = anteriorDiaNatural(fecha)
	const razonesComputoDias: string[] = []
	razonesComputoDias.push(`El día anterior natural a ${getDateByMillis(fecha, true, true)} es ${getDateByMillis(diaAnteriorNatural, true, true)}`)
	return {
		fecha: diaAnteriorNatural + CFechaAdministrativaFinHoraHabil,
		diasNoHabilesContemplados: [],
		razonesComputoDias: razonesComputoDias
	}
}

const siguienteDiaHabil = (fecha: number, diasNoHabiles: number[]): IFechaAdministrativaRespuestaFechaConDiasNoHabilesContemplados => {
	const diasNoHabilesContemplados: number[] = []
	const razonesComputoDias: string[] = []

	const respuesta: IFechaAdministrativaRespuestaFechaConDiasNoHabilesContemplados = {
		fecha: normalizarFecha(fecha + CFechaAdministrativaDiaNatural) + CFechaAdministrativaInicioHoraHabil,
		diasNoHabilesContemplados: diasNoHabilesContemplados,
		razonesComputoDias: razonesComputoDias
	}

	for (let i = 1; i <= 365; i++) {
		const siguienteDia = normalizarFecha(fecha + CFechaAdministrativaDiaNatural * i)
		if (esDiaHabil(siguienteDia, diasNoHabiles)) {
			razonesComputoDias.push(`El siguiente día hábil del ${getDateByMillis(fecha, true, true)} es el ${getDateByMillis(siguienteDia, true, true)}`)
			respuesta.fecha = siguienteDia + CFechaAdministrativaInicioHoraHabil
			respuesta.diasNoHabilesContemplados = diasNoHabilesContemplados
			respuesta.razonesComputoDias = razonesComputoDias
			return respuesta
		} else {
			diasNoHabilesContemplados.push(siguienteDia)
			razonesComputoDias.push(`${getDateByMillis(siguienteDia, true, true)} es día no hábil`)
		}
	}
	return respuesta
}

const diasNoHabilesEntreDosFechas = (fechaInicial: number, fechaFinal: number, diasNoHabiles: number[]): number[] => {
	const respuesta: number[] = []

	const inicio = normalizarFecha(fechaInicial)
	const fin = normalizarFecha(fechaFinal)
	for (let diaVerificar = inicio; diaVerificar <= fin; diaVerificar += CFechaAdministrativaDiaNatural) {
		if (!esDiaHabil(diaVerificar, diasNoHabiles)) {
			respuesta.push(diaVerificar)
		}
	}
	return respuesta
}

export const getTipoUnidadesPorOperacion = (operacion: EFechaAdministrativaOperacion | undefined, unidades: number | undefined): string => {
	let respuesta = ''
	switch (operacion) {
		case EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES:
			respuesta = unidades === 1 ? 'día hábil' : 'días hábiles'
			break
		case EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES:
		case EFechaAdministrativaOperacion.RESTAR_DIAS_NATURALES:
			respuesta = unidades === 1 ? 'día natural' : 'días naturales'
			break
		case EFechaAdministrativaOperacion.SUMAR_ANIOS_CALENDARIO:
			respuesta = unidades === 1 ? 'año calendario' : 'años calendario'
			break
		default:
			respuesta = 'desconocidos'
			break
	}
	return respuesta
}

export const getTipoAritmeticaPorOperacion = (operacion?: EFechaAdministrativaOperacion): string => {
	let respuesta = ''
	switch (operacion) {
		case EFechaAdministrativaOperacion.SUMAR_DIAS_HABILES:
		case EFechaAdministrativaOperacion.SUMAR_DIAS_NATURALES:
		case EFechaAdministrativaOperacion.SUMAR_ANIOS_CALENDARIO:
			respuesta = 'sumar'
			break
		case EFechaAdministrativaOperacion.RESTAR_DIAS_NATURALES:
			respuesta = 'restar'
			break
		default:
			respuesta = 'desconocido'
			break
	}
	return respuesta
}
