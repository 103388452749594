/**
 * @name acl
 * @author @tirsomartinezreyes
 * @version 2.0.4
 * @description Componente que dibuja un nodo del dossier validado
 *
 * @changelog 2.0.4 - 19/feb/25 - @tirsomartinezreyes - Se agrega ruta de calculadora de fechas administrativas
 * @changelog 2.0.3 - 29/ene/25 - @tirsomartinezreyes - Se ignora service worker en RNEC y RNIS para rutas a activos específicos
 * @changelog 2.0.2 - 21/ene/25 - @tirsomartinezreyes - Se integran rutas de RNIS
 * @changelog 2.0.1 - 30/sep/24 -  @tirsomartinezreyes - Se integran listas negras de rutas para ambientes específicos
 */
import { RolType, Roles, RolesTypeArray } from 'cofepris-typesafe/Types/Roles'
import { EnvironmentType } from 'src/bom'

export interface ACLItem {
	requiresRoles: RolType[]
	requiresAccessTokenGroupMatch?: boolean
	group?: string | null
	useRFC?: boolean
	customLabel?: string
	isVisible?: boolean
	cssClass?: string
	iconLeft?: string
	iconRight?: string
	ignoreServiceWorker?: boolean
}
export interface IACL {
	[key: string]: ACLItem
}

export interface IACLBlackList {
	[key: string]: EnvironmentType[]
}

export const ACLBlackList: IACLBlackList = {
	'/portal/GRUPO/:RFC/registros_sanitarios_dispositivos': ['DRL' as EnvironmentType]
}

export const ACL: IACL = Object.freeze({
	//Sección de rutas públicas
	'/': { requiresRoles: [Roles.ROL_ANONIMO.type], isVisible: false },
	'/sitio': { requiresRoles: [Roles.ROL_ANONIMO.type] },
	'/sitio/home': { requiresRoles: [Roles.ROL_ANONIMO.type], customLabel: 'Inicio' },
	'/sitio/login': { requiresRoles: [Roles.ROL_ANONIMO.type] },
	'/sitio/logout': {
		requiresRoles: [
			Roles.ROL_ANONIMO.type,
			Roles.ROL_SOLICITANTE_TITULAR_PERSONA_MORAL.type,
			Roles.ROL_SOLICITANTE_TITULAR_PERSONA_FISICA.type,
			Roles.ROL_SOLICITANTE_AUTORIZADOR.type,
			Roles.ROL_SOLICITANTE_EDITOR.type,
			Roles.ROL_SOLICITANTE_VISOR.type,
			Roles.ROL_AUTORIDAD.type,
			Roles.ROL_COORDINADOR.type,
			Roles.ROL_DICTAMINADOR.type,
			Roles.ROL_ADMINISTRADOR.type,
			Roles.ROL_SUPERADMINISTRADOR.type
		],
		isVisible: false,
		group: null
	},
	'/sitio/generador_credenciales': { requiresRoles: [Roles.ROL_ANONIMO.type], isVisible: false },
	'/sitio/validacion_documentos': { requiresRoles: [Roles.ROL_ANONIMO.type], isVisible: true },
	'/sitio/validacion_documentos/:uuid': { requiresRoles: [Roles.ROL_ANONIMO.type], isVisible: false, ignoreServiceWorker: true },
	'/sitio/rnec': { requiresRoles: [Roles.ROL_ANONIMO.type], isVisible: true },
	'/sitio/rnec/:uuid': { requiresRoles: [Roles.ROL_ANONIMO.type], isVisible: false, ignoreServiceWorker: true },
	'/sitio/rnis': { requiresRoles: [Roles.ROL_ANONIMO.type], isVisible: true },
	'/sitio/rnis/:uuid': { requiresRoles: [Roles.ROL_ANONIMO.type], isVisible: false, ignoreServiceWorker: true },
	//Sección de rutas de administración
	'/administrador': { requiresRoles: [Roles.ROL_ADMINISTRADOR.type], isVisible: false },
	'/administrador/usuarios': { requiresRoles: [Roles.ROL_ADMINISTRADOR.type], group: 'Usuarios' },
	'/administrador/usuarios/:uuid': { requiresRoles: [Roles.ROL_ADMINISTRADOR.type], group: null },
	'/administrador/bitacoras': { requiresRoles: [Roles.ROL_ADMINISTRADOR.type, Roles.ROL_SUPERADMINISTRADOR.type] },
	'/administrador/reportes': { requiresRoles: [Roles.ROL_ADMINISTRADOR.type, Roles.ROL_SUPERADMINISTRADOR.type], group: 'Reportes' },
	'/administrador/modo_operacion': { requiresRoles: [Roles.ROL_SUPERADMINISTRADOR.type, Roles.ROL_ADMINISTRADOR.type], group: 'Configuracion' },
	'/administrador/catalogos/rutas': {
		requiresRoles: [Roles.ROL_SUPERADMINISTRADOR.type, Roles.ROL_ADMINISTRADOR.type],
		group: 'Catálogos'
	},
	'/administrador/catalogos/funciones': {
		requiresRoles: [Roles.ROL_SUPERADMINISTRADOR.type, Roles.ROL_ADMINISTRADOR.type],
		group: 'Catálogos'
	},
	'/administrador/catalogos/homoclaves': {
		requiresRoles: [Roles.ROL_SUPERADMINISTRADOR.type, Roles.ROL_ADMINISTRADOR.type],
		group: 'Catálogos'
	},

	'/administrador/cofepagos/importar': {
		requiresRoles: [Roles.ROL_SUPERADMINISTRADOR.type, Roles.ROL_ADMINISTRADOR.type],
		group: 'Cofepagos'
	},
	'/administrador/cofepagos/llaves': {
		requiresRoles: [Roles.ROL_SUPERADMINISTRADOR.type, Roles.ROL_ADMINISTRADOR.type],
		group: 'Cofepagos'
	},
	'/administrador/cofepagos/desactivar': {
		requiresRoles: [Roles.ROL_SUPERADMINISTRADOR.type, Roles.ROL_ADMINISTRADOR.type],
		group: 'Cofepagos'
	},
	//pruebas para equipo de formify
	'/administrador/formularios/:id': {
		requiresRoles: [Roles.ROL_ADMINISTRADOR.type],
		isVisible: false
	},
	'/administrador/formularios': {
		requiresRoles: [Roles.ROL_ADMINISTRADOR.type],
		group: 'Configuracion'
	},
	'/administrador/plantillas/:id': {
		requiresRoles: [Roles.ROL_ADMINISTRADOR.type],
		isVisible: false
	},
	'/administrador/plantillas': {
		requiresRoles: [Roles.ROL_ADMINISTRADOR.type],
		group: 'Configuracion'
	},
	'/administrador/ai': {
		requiresRoles: [Roles.ROL_ADMINISTRADOR.type],
		group: 'Configuracion'
	},
	'/administrador/vitest': {
		requiresRoles: [Roles.ROL_ADMINISTRADOR.type],
		group: 'Configuracion'
	},
	'/administrador/relacionFFCC': {
		requiresRoles: [Roles.ROL_ADMINISTRADOR.type],
		group: 'Configuracion'
	},
	'/administrador/regex': {
		requiresRoles: [Roles.ROL_ADMINISTRADOR.type],
		group: 'Configuracion'
	},
	'/administrador/pruebaformatos': {
		requiresRoles: [Roles.ROL_ADMINISTRADOR.type],
		group: 'Configuracion'
	},
	'/administrador/tipoDeDatos': {
		requiresRoles: [Roles.ROL_ADMINISTRADOR.type],
		group: 'Configuracion'
	},
	'/administrador/buildertype': {
		requiresRoles: [Roles.ROL_ADMINISTRADOR.type],
		group: 'Configuracion'
	},
	'/administrador/arbolRecursos': {
		requiresRoles: [Roles.ROL_ADMINISTRADOR.type],
		group: 'Configuracion'
	},
	//Editor de activos
	'/editor_activos': {
		requiresRoles: [Roles.ROL_EDITOR_DE_ACTIVOS.type],
		group: 'Activos'
	},
	'/editor_activos/insumos_salud': {
		requiresRoles: [Roles.ROL_EDITOR_DE_ACTIVOS.type],
		group: 'Activos'
	},
	'/editor_activos/ensayos_clinicos': {
		requiresRoles: [Roles.ROL_EDITOR_DE_ACTIVOS.type],
		group: 'Activos'
	},
	'/editor_activos/ensayos_clinicos/:uuid': {
		requiresRoles: [Roles.ROL_EDITOR_DE_ACTIVOS.type],
		group: 'Activos',
		isVisible: false
	},
	//Sección de rutas de superadministrador
	'/superadministrador': { requiresRoles: [Roles.ROL_SUPERADMINISTRADOR.type] },
	'/superadministrador/usuarios': { requiresRoles: [Roles.ROL_SUPERADMINISTRADOR.type], group: 'Usuarios' },
	'/superadministrador/usuarios/nuevo': { requiresRoles: [Roles.ROL_SUPERADMINISTRADOR.type], group: 'Usuarios' },
	'/superadministrador/usuarios/:uuid': { requiresRoles: [Roles.ROL_SUPERADMINISTRADOR.type], isVisible: false },
	'/superadministrador/perfiles': { requiresRoles: [Roles.ROL_SUPERADMINISTRADOR.type] },
	//Sección de rutas de usuario de la organización
	'/sistema': {
		requiresRoles: [Roles.ROL_AUTORIDAD.type, Roles.ROL_DICTAMINADOR.type, Roles.ROL_COORDINADOR.type],
		isVisible: false
	},
	'/sistema/reportes': {
		requiresRoles: [Roles.ROL_AUTORIDAD.type, Roles.ROL_DICTAMINADOR.type, Roles.ROL_COORDINADOR.type],
		group: 'Reportes'
	},
	'/sistema/reportes/tramites_en_evaluacion': {
		requiresRoles: [Roles.ROL_COORDINADOR.type],
		group: 'Reportes'
	},
	'/sistema/reportes/tramites_en_prevencion': {
		requiresRoles: [Roles.ROL_COORDINADOR.type],
		group: 'Reportes'
	},
	'/sistema/reportes/tramites_en_verificacion': {
		requiresRoles: [Roles.ROL_COORDINADOR.type],
		group: 'Reportes'
	},
	'/sistema/reportes/tramites_en_firma': {
		requiresRoles: [Roles.ROL_COORDINADOR.type, Roles.ROL_AUTORIDAD.type],
		group: 'Reportes'
	},
	'/sistema/reportes/tramites_en_resolucion': {
		requiresRoles: [Roles.ROL_COORDINADOR.type],
		group: 'Reportes'
	},
	'/sistema/reportes/tramites_asignados': {
		requiresRoles: [Roles.ROL_DICTAMINADOR.type],
		group: 'Reportes'
	},
	'/sistema/herramientas/fechas_administrativas': {
		requiresRoles: [Roles.ROL_AUTORIDAD.type, Roles.ROL_DICTAMINADOR.type, Roles.ROL_COORDINADOR.type],
		group: 'Herramientas'
	},
	'/sistema/GRUPO/:RFC/HOMOCLAVE/:idHomoclave': {
		requiresRoles: [Roles.ROL_AUTORIDAD.type, Roles.ROL_DICTAMINADOR.type, Roles.ROL_COORDINADOR.type]
	},
	'/sistema/GRUPO/:RFC/HOMOCLAVE/:idHomoclave/MODALIDAD/:idModalidad': {
		requiresRoles: [Roles.ROL_AUTORIDAD.type, Roles.ROL_DICTAMINADOR.type, Roles.ROL_COORDINADOR.type]
	},
	'/sistema/GRUPO/:RFC/HOMOCLAVE/:idHomoclave/MODALIDAD/:idModalidad/SOLICITUD/:idSolicitud': {
		requiresRoles: [Roles.ROL_AUTORIDAD.type, Roles.ROL_DICTAMINADOR.type, Roles.ROL_COORDINADOR.type]
	},

	//Sección de rutas de solicitante
	'/portal': {
		requiresRoles: [
			Roles.ROL_SOLICITANTE_TITULAR_PERSONA_MORAL.type,
			Roles.ROL_SOLICITANTE_TITULAR_PERSONA_FISICA.type,
			Roles.ROL_SOLICITANTE_AUTORIZADOR.type,
			Roles.ROL_SOLICITANTE_EDITOR.type,
			Roles.ROL_SOLICITANTE_VISOR.type
		],
		isVisible: false
	},
	'/portal/GRUPO/:RFC': {
		requiresRoles: [Roles.ROL_SOLICITANTE_TITULAR_PERSONA_FISICA.type, Roles.ROL_SOLICITANTE_AUTORIZADOR.type, Roles.ROL_SOLICITANTE_EDITOR.type, Roles.ROL_SOLICITANTE_VISOR.type],
		requiresAccessTokenGroupMatch: true,
		customLabel: 'Inicio'
	},
	'/portal/GRUPO/:RFC/registros_sanitarios_medicamentos': {
		requiresRoles: [Roles.ROL_SOLICITANTE_TITULAR_PERSONA_FISICA.type, Roles.ROL_SOLICITANTE_AUTORIZADOR.type, Roles.ROL_SOLICITANTE_EDITOR.type, Roles.ROL_SOLICITANTE_VISOR.type],
		requiresAccessTokenGroupMatch: true,
		customLabel: 'Registros Sanitarios Medicamentos',
		group: 'Portal',
		useRFC: true
	},
	'/portal/GRUPO/:RFC/registros_sanitarios_dispositivos': {
		requiresRoles: [Roles.ROL_SOLICITANTE_TITULAR_PERSONA_FISICA.type, Roles.ROL_SOLICITANTE_AUTORIZADOR.type, Roles.ROL_SOLICITANTE_EDITOR.type, Roles.ROL_SOLICITANTE_VISOR.type],
		requiresAccessTokenGroupMatch: true,
		customLabel: 'Registros Sanitarios Dispositivos Médicos',
		group: 'Portal',
		useRFC: true
	},
	'/portal/GRUPO/:RFC/permisos': {
		requiresRoles: [
			Roles.ROL_SOLICITANTE_TITULAR_PERSONA_MORAL.type,
			Roles.ROL_SOLICITANTE_TITULAR_PERSONA_FISICA.type,
			Roles.ROL_SOLICITANTE_AUTORIZADOR.type,
			Roles.ROL_SOLICITANTE_EDITOR.type,
			Roles.ROL_SOLICITANTE_VISOR.type
		],
		requiresAccessTokenGroupMatch: true,
		group: 'Portal',
		useRFC: true
	},
	'/portal/GRUPO/:RFC/agregarPermiso': {
		requiresRoles: [Roles.ROL_SOLICITANTE_TITULAR_PERSONA_MORAL.type, Roles.ROL_SOLICITANTE_TITULAR_PERSONA_FISICA.type],
		requiresAccessTokenGroupMatch: true,
		group: 'Portal',
		useRFC: true,
		customLabel: 'Agregar Permiso'
	},
	//Sección de notificaciones sobre acciones del grupo solicitante
	'/portal/GRUPO/:RFC/NOTIFICACION': {
		requiresRoles: [Roles.ROL_SOLICITANTE_TITULAR_PERSONA_FISICA.type, Roles.ROL_SOLICITANTE_AUTORIZADOR.type, Roles.ROL_SOLICITANTE_EDITOR.type, Roles.ROL_SOLICITANTE_VISOR.type],
		requiresAccessTokenGroupMatch: true,
		group: 'Portal',
		useRFC: true
	},
	'/portal/GRUPO/:RFC/NOTIFICACION/:idNotificacion': {
		requiresRoles: [Roles.ROL_SOLICITANTE_TITULAR_PERSONA_FISICA.type, Roles.ROL_SOLICITANTE_AUTORIZADOR.type, Roles.ROL_SOLICITANTE_EDITOR.type, Roles.ROL_SOLICITANTE_VISOR.type],
		requiresAccessTokenGroupMatch: true,
		group: 'Portal',
		useRFC: true
	},
	//Sección de información y archivos de uso común del grupo solicitante para uso en las solicitudes
	/*
	'/portal/GRUPO/:RFC/informacion': {
		requiresRoles: [Roles.ROL_SOLICITANTE_TITULAR_PERSONA_FISICA.type, Roles.ROL_SOLICITANTE_AUTORIZADOR.type, Roles.ROL_SOLICITANTE_EDITOR.type, Roles.ROL_SOLICITANTE_VISOR.type],
		requiresAccessTokenGroupMatch: true,
		group: 'Portal',
		useRFC: true
	},
	*/
	'/portal/GRUPO/:RFC/HOMOCLAVE': {
		requiresRoles: [
			Roles.ROL_SOLICITANTE_TITULAR_PERSONA_FISICA.type,
			Roles.ROL_SOLICITANTE_AUTORIZADOR.type,
			Roles.ROL_SOLICITANTE_EDITOR.type,
			///TEMPORAL ELIMINAR EN PRODUCCION
			Roles.ROL_SOLICITANTE_VISOR.type
		],
		requiresAccessTokenGroupMatch: true,
		group: 'Portal',
		useRFC: true,
		customLabel: 'Nueva Solicitud',
		cssClass: 'btn btn-primary active pt5 pb5',
		iconRight: 'glyphicon glyphicon-circle-arrow-right ml5'
	},
	'/portal/GRUPO/:RFC/HOMOCLAVE/:idHomoclave': {
		requiresRoles: [Roles.ROL_SOLICITANTE_TITULAR_PERSONA_FISICA.type, Roles.ROL_SOLICITANTE_AUTORIZADOR.type, Roles.ROL_SOLICITANTE_EDITOR.type, Roles.ROL_SOLICITANTE_VISOR.type]
	},
	'/portal/GRUPO/:RFC/HOMOCLAVE/:idHomoclave/MODALIDAD/:idModalidad': {
		requiresRoles: [Roles.ROL_SOLICITANTE_TITULAR_PERSONA_FISICA.type, Roles.ROL_SOLICITANTE_AUTORIZADOR.type, Roles.ROL_SOLICITANTE_EDITOR.type, Roles.ROL_SOLICITANTE_VISOR.type],
		requiresAccessTokenGroupMatch: true
	},
	'/portal/GRUPO/:RFC/HOMOCLAVE/:idHomoclave/MODALIDAD/:idModalidad/SOLICITUD/:idSolicitud': {
		requiresRoles: [Roles.ROL_SOLICITANTE_TITULAR_PERSONA_FISICA.type, Roles.ROL_SOLICITANTE_AUTORIZADOR.type, Roles.ROL_SOLICITANTE_EDITOR.type, Roles.ROL_SOLICITANTE_VISOR.type],
		requiresAccessTokenGroupMatch: true
	},

	'/portal/GRUPO/:RFC/nuevaSolicitud': {
		requiresRoles: [Roles.ROL_SOLICITANTE_TITULAR_PERSONA_FISICA.type, Roles.ROL_SOLICITANTE_AUTORIZADOR.type, Roles.ROL_SOLICITANTE_EDITOR.type, Roles.ROL_SOLICITANTE_VISOR.type],
		requiresAccessTokenGroupMatch: true
	},
	// Sección de perfil
	'/perfil': {
		requiresRoles: [
			Roles.ROL_SOLICITANTE_TITULAR_PERSONA_MORAL.type,
			Roles.ROL_SOLICITANTE_TITULAR_PERSONA_FISICA.type,
			Roles.ROL_SOLICITANTE_AUTORIZADOR.type,
			Roles.ROL_SOLICITANTE_EDITOR.type,
			Roles.ROL_SOLICITANTE_VISOR.type,
			Roles.ROL_AUTORIDAD.type,
			Roles.ROL_COORDINADOR.type,
			Roles.ROL_DICTAMINADOR.type,
			Roles.ROL_ADMINISTRADOR.type,
			Roles.ROL_SUPERADMINISTRADOR.type
		],
		isVisible: false
	},
	'/perfil/cuenta': {
		requiresRoles: [
			Roles.ROL_SOLICITANTE_TITULAR_PERSONA_MORAL.type,
			Roles.ROL_SOLICITANTE_TITULAR_PERSONA_FISICA.type,
			Roles.ROL_SOLICITANTE_AUTORIZADOR.type,
			Roles.ROL_SOLICITANTE_EDITOR.type,
			Roles.ROL_SOLICITANTE_VISOR.type,
			Roles.ROL_AUTORIDAD.type,
			Roles.ROL_COORDINADOR.type,
			Roles.ROL_DICTAMINADOR.type,
			Roles.ROL_ADMINISTRADOR.type,
			Roles.ROL_SUPERADMINISTRADOR.type,
			Roles.ROL_EDITOR_DE_ACTIVOS.type
		],
		isVisible: false
	},
	'/perfil/sesion': {
		requiresRoles: [
			Roles.ROL_SOLICITANTE_TITULAR_PERSONA_MORAL.type,
			Roles.ROL_SOLICITANTE_TITULAR_PERSONA_FISICA.type,
			Roles.ROL_SOLICITANTE_AUTORIZADOR.type,
			Roles.ROL_SOLICITANTE_EDITOR.type,
			Roles.ROL_SOLICITANTE_VISOR.type,
			Roles.ROL_AUTORIDAD.type,
			Roles.ROL_COORDINADOR.type,
			Roles.ROL_DICTAMINADOR.type,
			Roles.ROL_ADMINISTRADOR.type,
			Roles.ROL_SUPERADMINISTRADOR.type,
			Roles.ROL_EDITOR_DE_ACTIVOS.type
		],
		isVisible: false
	},

	'/:pathMatch(.*)*': { requiresRoles: [...RolesTypeArray], isVisible: false }
})
